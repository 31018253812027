import mqtt from "mqtt"
import MQTTPattern from "mqtt-pattern"
import { SubscriptionVault } from "mqtt-subscription-vault"

import { MQTT_HOST, MQTT_PORT, MQTT_PROTOCOL, MQTT_REJECT_UNAUTHORIZED } from "constants/config"
import { patch } from "utils/api"
import { getSpaceId } from "utils/auth"
import { abToStr, isASCII, jsonToArrayBuffer } from "utils/misc"
import {
  addMessage,
  GLOBAL_NOTIFICATIONS,
  MESSAGE_TYPE_ERROR
} from "utils/notifications"


const MOCKED = process.env.REACT_APP_ENVIRONMENT === "development"
const SUBSCRIBE = "subscribe"
const UNSUBSCRIBE = "unsubscribe"

//Export custom event names
export const THING_STATUS_MQTT_MESSAGE = "THING_STATUS_MQTT_MESSAGE" //This value needs to be updated
export const DATA_MQTT_MESSAGE = "DATA_MQTT_MESSAGE"
export const FUNCTION_STATUS_MQTT_MESSAGE = "FUNCTION_STATUS_MQTT_MESSAGE"

export const mqttTopicRegEx = /^(([a-zA-Z0-9]+|\+)\/)*([a-zA-Z0-9]+|\+|#)$/
export const mqttTopicSymbolsNotAllowed = /[^a-zA-Z0-9#+/]/

class MqttClient {

  constructor({ host="", port="", protocol="wss"}) {
    this.host = host
    this.port = port
    this.protocol = protocol
    this.client = ""
    this.connecting = false
    this.topic = ""
    this.allSubscriptions = new Set() //includes all subscription topics, even overlapping ones
    // this.currentSubscriptions = [] //topics actually subscribed to
    this.subscriptionQueue = [] //queue of topics to subscribe to or unsubscribe from synchronously
    this.queueLib = {} // library of the requests in the queue, value equal to the method used

    this.resolve = [] // fill w/ resolve funcs for using syncSubscribe

    //NOTE: this should be cleared any time mqtt client reconnects
    this.subscriptionVault = new SubscriptionVault({
      onTopicAdded: (topic) => {
        this.subscribe(topic)
      },
      onTopicRemoved: (topic) => {
        this.unsubscribe(topic)
      }
    })
  }

  connect = async (callback=()=>{}) => {
    const { host, port, protocol, client, connecting } = this
    const username = getMqttUsername()
    const password = await getMqttPassword()
    if (!MOCKED && !client && !connecting && host && port && password) {
      this.connecting = true
      const url = `${protocol}://${host}:${port}/mqtt`
      const mqttClient = mqtt.connect(url, {username, password, rejectUnauthorized: MQTT_REJECT_UNAUTHORIZED})

      console.log("attempting to connect mqtt client")

      mqttClient.on("connect", () => {
        this.client = mqttClient
        this.connecting = false
        console.log("mqtt client connected")
        callback()
        this.processSubscriptionQueue()
      })

      mqttClient.on("message", messageHandler)

      mqttClient.on("error", error => {
        console.error("MQTT Client error: ", error)

        //Note: bad username/password error reoccurs every retry
        this.restart()
      })
    }
    else {
      let warningText = "Connection with MQTT broker was not established. Reason:"
      if(client) console.warn(warningText + " Already connected.")
      if(connecting) console.warn(warningText + " Pending connection.")
      if(!host) console.warn(warningText + " Missing host.")
      if(!port) console.warn(warningText + " Missing port.")
      if(!password) console.warn(warningText + " Missing password.")
    }
  }

  subscribe = (topic) => {
    if(!MOCKED) {
      if (Array.isArray(topic)) {
        topic.forEach((tp) => this.allSubscriptions.add(tp))
      } else {
        this.allSubscriptions.add(topic)
      }

      this.updateSubscriptions()
    }
  }

  publish = (topic, message, options, callback) => {
    if(!MOCKED && this.client) {
      //NOTE: this is not working yet...
      const arrayBuffer = jsonToArrayBuffer(message)
      this.client.publish(topic, arrayBuffer, options, callback)
    }
  }

  unsubscribe = topic => {
    if(!MOCKED) {
      // Remove topic from list of subscriptions and update subscriptions
      if (Array.isArray(topic)) {
        topic.forEach(this.allSubscriptions.delete, this.allSubscriptions)
      } else {
        this.allSubscriptions.delete(topic)
      }

      this.updateSubscriptions({ removed: topic })
    }
  }

  // Handles subscriptions to overlapping topics
  updateSubscriptions = ({ removed="" }={}) => {
    const allSubs = [...this.allSubscriptions]
    // const currentTopics = [...this.currentSubscriptions]
    const currentTopicsLib = this.client?._resubscribeTopics || {}
    const currentTopics = Object.keys(currentTopicsLib || {})

    // Determine subscriptions to subscribe to (permissive vs specific)
    const toSubscribe = allSubs.filter(sub => {
      // Remove this sub if it matches any other in this.allSubscriptions
      const hasMatchingTopic = allSubs.some((topic) => {
        if (topic === sub) return
        return MQTTPattern.matches(topic, sub)
      })
      return !hasMatchingTopic
    })

    // Remove topics that client is already subscribed to
    const toAdd = toSubscribe.filter(sub => !currentTopicsLib[sub] && this.queueLib[sub]?.method !== SUBSCRIBE)

    // Get list of topics to unsubscribe to (permissive sub vs specific sub)
    // add it to the list if
    //   it's in the broker
    //   it's not in toSubscribe
    //   it's not in the queue as an unsubscribe
    let toUnsubscribe = currentTopics.filter(sub => !toSubscribe.includes(sub) && this.queueLib[sub]?.method !== UNSUBSCRIBE)

    // check if the specificly unsubscribed topic is being subscribed to
    // add it to the list if
    //   it's in the queue as a SUB
    //   it's not in the broker
    //   it's not in toSubscribe
    if (
      !!removed && // specific unsub request made
      this.queueLib[removed]?.method === SUBSCRIBE && // item is in the queue to sub
      !currentTopicsLib[removed] && // client isn't currently subscribed
      !this.allSubscriptions.has(removed) // isn't in the list of things to be subscribed to
    ) {
      toUnsubscribe.push(removed)
    }

    // For debugging
    // console.log("updateSubscriptions ", {allSubs, currentTopics, toSubscribe, toAdd, toUnsubscribe})

    // loops to double check that only one topic is handled per request
    // double check that a duplicate isn't being added since we are already looping
    toUnsubscribe.forEach(topic => {
      if (this.queueLib[topic]?.method !== UNSUBSCRIBE) this.addToSubscriptionQueue({method: UNSUBSCRIBE, topic })
    })
    toAdd.forEach(topic => {
      if (this.queueLib[topic]?.method !== SUBSCRIBE) this.addToSubscriptionQueue({method: SUBSCRIBE, topic })
    })
    // catch for if a sync call was made, but nothing was added due to more permissive topics
    if (this.subscriptionQueue.length === 0) {
      this.clearResolves()
    }
  }

  addToSubscriptionQueue = (subscription) => {
    const { method, topic } = subscription
    const queueLength = this.subscriptionQueue.length
    let position = 0
    if (queueLength > 0) position = this.subscriptionQueue[queueLength - 1].position + 1
    this.subscriptionQueue.push({ ...subscription, position })

    // add to a lib for quicker lookup, which includes the last method used
    this.queueLib[topic] = { method, position }

    this.processSubscriptionQueue()
  }

  processSubscriptionQueue = () => {
    if (this.queueInProgress || !this.client) return

    const nextSub = this.subscriptionQueue.shift()

    if (nextSub) {
      this.queueInProgress = true
      const {method="subscribe", topic="", position} = nextSub

      // if matches the last request for that topic, remove from the lib
      const lastAction = this.queueLib[topic]
      if (lastAction && position === lastAction.position) delete this.queueLib[topic]

      const callback = err => {
        if (err) {
          console.error("MQTT Could not process subscription in queue: ", {method, topic, err})
        }
        this.queueInProgress = false
        this.processSubscriptionQueue()
      }

      if (method === SUBSCRIBE) this.client.subscribe([topic], {qos: 1}, callback)
      else if (method === UNSUBSCRIBE) this.client.unsubscribe([topic], callback)

    } else {
      // Force clear queueLib when queue is emptied
      this.queueLib = {}
      this.clearResolves()
      //For testing only, calls when queue ends
      //console.log("QUEUE END\n    this.client._resubscribeTopics: ", unique(this.client._resubscribeTopics))
    }
  }

  clearResolves = () => {
    // Call each resolve requested
    if (this.resolve.length > 0) {
      this.resolve.forEach(func => func())
      this.resolve = []
    }
  }

  onMessages = (callback=()=>{}) => {
    if(!MOCKED && this.client) {
      this.client.on("message", callback)
    }
  }

  disconnect = (callback=()=>{}) => {
    console.log("MQTT Client disconnect called ", {client: this.client})
    if(!MOCKED && this.client) {
      const currentSubscriptions = Object.keys(this.client?._resubscribeTopics || {})
      const client = this.client
      //nullify this.client before disconnect is complete to prevent subscription queue from processing while disconnecting
      this.client = ""
      client.end(() => {

        //Add all currently subscribed to topics back to the subscription queue
        //When client is reconnected, will subscribe back to these channels first
        this.subscriptionQueue = [
          ...currentSubscriptions.map(topic => ({method: SUBSCRIBE, topic})),
          ...this.subscriptionQueue
        ]
        callback()
      })
    }
  }

  restart = () => {
    if(!MOCKED) {
      console.log("MQTT Restart called ", {client: this.client})
      this.client? this.disconnect(connectMqttClient) : connectMqttClient()
    }
  }
}

export function getMqttUsername() {
  return `studio@${getSpaceId()}`
}

export async function getMqttPassword() {
  try {
    const response = await patch(`/mqtt-credentials/mqtt-username/${getMqttUsername()}`, { password: "" })
    const {
      status,
      data: {
        password
      }={}
    } = response
    if (status === 200 && password) {
      return password
    } else console.error("Could not retrieve MQTT password")
  } catch (error) {
    console.error(`${error.name}: ${error.message}`)
    addMessage({
      target: GLOBAL_NOTIFICATIONS,
      text: "Error connecting to MQTT",
      subtext: "Connection could not be established",
      type: MESSAGE_TYPE_ERROR
    })
  }
}

const mqttClient = new MqttClient({
  host: MQTT_HOST,
  port: MQTT_PORT,
  protocol: MQTT_PROTOCOL
})

export default mqttClient

//For testing only
window.mqttClient = mqttClient

export const getTopicParams = (pattern, topic) => {
  //If no match, return empty object to prevent destructuring from failing.
  return MQTTPattern.exec(pattern, topic) || {}
}

export const getMessageContent = (message) => {
  const messageStr = abToStr(message)

  try {
    return JSON.parse(messageStr)
  } catch(err) {
    //If message is not valid json, check if is valid ascii, otherwise return truncated string
    return isASCII(messageStr)
      ? messageStr
      : `${messageStr.slice(0, 10)}...`
  }
}

export const messageHandler = (topic, message, meta) => {
  const matchingSubscriptions = mqttClient.subscriptionVault.findMatches(topic)
  matchingSubscriptions.forEach(subscription => subscription(topic, message, meta))
}

export const connectMqttClient = () => {
  if(!mqttClient.client && !mqttClient.connecting) mqttClient.connect()
}

export const restartMqtt = () => {
  mqttClient.restart()
}

export const subscribe = (topic, subscription) => {
  if (Array.isArray(topic)) {
    topic.forEach(subTopic => mqttClient.subscriptionVault.add(subTopic, subscription))
  } else {
    mqttClient.subscriptionVault.add(topic, subscription)
  }
}

export const syncSubscribe = async (topic, subscription) => {
  return new Promise (resolve => {
    mqttClient.resolve.push(resolve)
    subscribe(topic, subscription)
  })
}

export const unsubscribe = (topic, subscription) => {
  if (Array.isArray(topic)) {
    topic.forEach(subTopic => mqttClient.subscriptionVault.remove(subTopic, subscription))
  } else {
    mqttClient.subscriptionVault.remove(topic, subscription)
  }
}

export const syncUnsubscribe = async (topic, subscription) => {
  return new Promise (resolve => {
    mqttClient.resolve.push(resolve)
    unsubscribe(topic, subscription)
  })
}

export const publish = (topic, message, options) => {
  mqttClient.publish(topic, message, options, (err) => {
    if (err) {
      console.error("Error publishing message: ", err)
    }
  })
}

//Methods to match topic
export const isThingStatusTopic = (topic) =>
  MQTTPattern.matches("spaces/+/things/+/+/#", topic) ||
  MQTTPattern.matches("spaces/+/categories/+/things/+/+/#", topic)

export const isThingDataTopic = (topic) =>
  MQTTPattern.matches("spaces/+/things/+/data", topic) ||
  MQTTPattern.matches("spaces/+/categories/+/things/+/data", topic)

export const isFunctionStatusTopic = (topic) =>
  MQTTPattern.matches("spaces/+/functions/+/status", topic)

//Returns the following topic parameters:
// spaceId
// collectionId
// thingUlid
// sectionId (actions, events or properties)
export const getStatusTopicParams = (topic) => {
  let topics = getTopicParams("spaces/+spaceId/things/+thingUlid/+sectionId/#propertyId", topic)
  if (Object.keys(topics).length === 0) {
    topics = getTopicParams("spaces/+spaceId/categories/+collectionId/things/+thingUlid/+sectionId/#propertyId", topic)
  }
  return topics
}

//Returns the following topic parameters:
// spaceId
// collectionId
// thingUlid
export const getDataTopicParams = (topic) => {
  let topics = getTopicParams("spaces/+spaceId/things/+thingUlid/data", topic)
  if (Object.keys(topics).length === 0) {
    topics = getTopicParams("spaces/+spaceId/categories/+collectionId/things/+thingUlid/data", topic)
  }
  return topics
}

//Returns the following topic parameters for Beta clusters:
// spaceId
// collectionId
// thingUlid
export const getDataTopicParamsBeta = (topic) => {
  let topics = getTopicParams("spaces/+spaceId/things/+thingUlid/events/metrics", topic)
  if (Object.keys(topics).length === 0) {
    topics = getTopicParams("spaces/+spaceId/categories/+collectionId/things/+thingUlid/events/metrics", topic)
  }
  return topics
}

//Returns the following topic parameters:
// spaceId
// functionId
export const getFunctionTopicParams = (topic) => getTopicParams("spaces/+spaceId/functions/+functionId/status", topic)

export const getTriggerTopicParams = (topic) => getTopicParams("spaces/+spaceId/triggers/+triggerId/status", topic)
