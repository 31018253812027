import { UnityButton } from "@bit/smartworks.unity-react.unity-core-react"
import React from "react"
import { Field } from "react-final-form"

import TextInput from "components/common/Inputs/TextInput"


const SubscriptionFieldArray = ({fields}) => {
  return <div id="mqtt-inspector-topics-section-wrapper" style={styles.section}>
    <UnityButton
      id="add-mqtt-topic-button"
      type="borderless"
      style={styles.actionBtn}
      onClick={() => fields.push("")}
      label="Add Topic"
      leftIcon="unity:add"
    />
    <div id="mqtt-inspector-topics-list-wrapper" style={styles.innerSection}>
      {fields.map((field, index) => {
        return <div id={`mqtt-topic-${index}-wrapper`} key={field} style={styles.topicContainer}>
          <Field
            name={field}
            id={`mqtt-topic-${index}`}
            component={TextInput}
            placeholder='New topic'
            style={styles.topicInput}
            trackDirty
            hideErrors={true}
          />
          <UnityButton
            id={`delete-mqtt-inspector-topic-button-${index}`}
            centerIcon={"unity:trash_can"}
            type="borderless"
            onClick={() => fields.remove(index)}
          />
        </div>
      }
      )}
    </div>

  </div>
}

export default SubscriptionFieldArray

const styles = {
  section: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    // marginRight: 12
    width: 400,
    marginLeft: 20
  },
  innerSection: {
    "--horz-pos": "stretch",
    flex: 1
  },
  actionBtn: {
    marginTop: 6,
    alignSelf: "flex-start"
  },
  topicContainer: {
    marginTop: 6,
    display: "flex",
    alignItems: "center"
  }
}