import React from "react"
import "@polymer/paper-spinner/paper-spinner-lite.js"

export default function Spinner({
  size="small",
  style: spinnerStyles={}
}) {
  const spinnerWidthHeight = ({
    small: 56,
    mediumn: 82,
    large: 100
  })[size] || 56
  return (<paper-spinner-lite
    active
    style={{
      width: spinnerWidthHeight,
      height: spinnerWidthHeight,
      position: "absolute",
      top: "40%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      "--paper-spinner-color": "var(--primary-color, var(--default-primary-color))",
      ...spinnerStyles
    }}
  ></paper-spinner-lite>)
}