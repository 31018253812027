import { UnitySpinner } from "@bit/smartworks.unity-react.unity-core-react"
import { Typography } from "antd"
import React from "react"
import {connect} from "react-redux"

import {GearIcon} from "assets/icons"
import { colors } from "assets/stylesheets/common"
import UnityIcon from "components/unity/UnityIcon"
import UnityLink from "components/unity/UnityLink"
import {IDP_URL} from "constants/config"


const UserPopoverContentSettingsAccount = ({isLoading = false}) => {

  return (
    <div style={styles.wrapper}>
      <Typography.Title level={2}>
        Settings
      </Typography.Title>
      {isLoading ?
        <div style={styles.spinnerWrapper}>
          <UnitySpinner active style={styles.spinner}/>
        </div> :
        <div style={styles.settingsCard}>
          <UnityIcon Component={GearIcon}/>
          <UnityLink id="idp-link" href={`${IDP_URL}/settings`} target="_blank" rel="noopener noreferrer">Account Settings</UnityLink>
        </div>}
    </div>
  )
}

const styles = {
  spinnerWrapper: {
    position: "relative",
    width: "100%",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  spinner: {
    "--default-spinner-color": "var(--default-primary-brand-color)",
    "--default-spinner-size": "25px",
    "--default-spinner-stroke-width": "2px",
  },
  wrapper:{
    border: `1px solid ${colors.lightGray1}`,
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    gap: 4,
    alignSelf: "stretch",
    position: "relative",
  },
  settingsCard: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  }
}
export default connect()(UserPopoverContentSettingsAccount)