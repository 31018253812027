import {
  clusterInstallationLabel,
  clusterDetailsConfigurationLabel as configurationLabel,
  clusterDetailsLabel as detailsLabel,
  clusterDetailsOverviewLabel as overviewLabel
} from "assets/texts/clusterTexts"

export const PENDING_CONNECTION = "Pending connection"
export const OFFLINE = "ClientOffline"
export const CONNECTED = "Connected"
export const CLIENT_WAKE_UP = "ClientWakeUp"
export const CLIENT_GONE = "ClientGone"
export const REGISTERED = "Registered"
export const SUBSCRIBED = "Subscribed"

export const CLUSTER_API_CLIENT = "apiClient"
export const CLUSTER_SYNCED_THINGS = "syncedThings"
export const CLUSTER_SYNCED_THINGS_SCHEMAS = "syncedThingsSchemas"

export const OVERRIDE_DESC = "Parameter"

export const LABEL_OVERVIEW = { label: overviewLabel, key: "overview", id: "cluster-details-overview-tab" }
export const LABEL_DETAILS = { label: detailsLabel, key: "details", id: "cluster-details-tab"}
export const LABEL_CONFIG = { label: configurationLabel, key: "configuration", id: "cluster-details-config-tab"}

export const EVERY_FLEET_OPTION = "every-asset"
export const EVERY_FLEET_LABEL = "Every Asset"
export const LABEL_INSTALLATION = { label: clusterInstallationLabel, key: "installation", id: "cluster-details-installation-tab"}
