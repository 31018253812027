/* === IMPORTS === */
import { createReducer } from "@reduxjs/toolkit"
import { setWith } from "lodash"

import { CLEAN_SPACE } from "actions/auth"
import {
  clusterLabel,
  clusterLabelPlural,
  clusterSoftwareLabel
} from "assets/texts/clusterTexts"
import { fetchingError } from "assets/texts/requestTexts"
import { CLUSTER_SYNCED_THINGS, CLUSTER_SYNCED_THINGS_SCHEMAS } from "constants/clusters"
import {
  CLUSTER_MANAGEMENT_OLD,
  CLUSTER_SOFTWARE,
  CONFIG_VERSION,
  EDGE_APPS,
  PACKAGES,
  PARAMS,
  RESOURCES,
  VERSION_DEPLOYMENT
} from "constants/routes"
import { addLabelToStore } from "reduxModules/labels"
import { paceRequests } from "utils/api"
import { deleteItem, getItem, getList, patchItem, postItem, putItem } from "utils/apiActions"
import { makeLib, zipLists } from "utils/misc"
import {
  addMessage,
  GLOBAL_NOTIFICATIONS,
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS
} from "utils/notifications"

import { makeActions } from "./utiliducks"


const STATUS_DATA = "statusData"
const DEPLOYMENTS = "deployments"
export const ANYTHING_SYNCED_THING = "anything-thing"

let edgeOps = {
  [CLUSTER_MANAGEMENT_OLD]: {
    list: "clusters",
    lib: "clustersLib",
    label: clusterLabel,
    labelPlural: clusterLabelPlural
  },
  [CLUSTER_SOFTWARE]: {
    list: "currentClusterSoftware",
    lib: "clusterSoftwareLib",
    label: clusterSoftwareLabel,
    labelPlural: clusterSoftwareLabel
  },
  [RESOURCES]: {
    list: "resources",
    lib: "resourcesLib",
    label: "Resource",
    labelPlural: "Resources"
  },
  [PARAMS]: {
    list: "resources",
    lib: "resourcesLib",
    label: "Resource Parameter",
    labelPlural: "Resource Parameters"
  },
  [EDGE_APPS]: {
    list: "edgeApps",
    lib: "edgeAppsLib",
    label: "Edge Application",
    labelPlural: "Edge Applications"
  },
  [STATUS_DATA]: {
    list: "statusData",
    lib: "statusDataLib",
    label: "Cluster Status Data",
    labelPlural: "Cluster Status Data"
  },
  [DEPLOYMENTS]: {
    list: "deployments",
    lib: "deploymentsLib",
    label: "Configuration",
    labelPlural: "Configurations"
  },
  [PACKAGES]: {
    list: "packages",
    lib: "packagesLib",
    label: "Package",
    labelPlural: "Packages"
  },
  [CONFIG_VERSION]: {
    list: "edgeAppVersions",
    lib: "edgeAppVersionsLib",
    label: "Edge Application Version",
    labelPlural: "Edge Application Versions"
  },
  [VERSION_DEPLOYMENT]: {
    list: "versionDeployments",
    lib: "versionDeploymentsLib",
    label: "Edge Application Configuration",
    labelPlural: "Edge Application Configurations"
  },
  [CLUSTER_SYNCED_THINGS]: {
    list: "syncedThings",
    lib: "syncedThingsLib",
    label: "Synced Thing",
    labelPlural: "Synced Things"
  },
  [CLUSTER_SYNCED_THINGS_SCHEMAS]: {
    list: "syncedThingsSchemas",
    lib: "syncedThingsSchemasLib",
    label: "Synced Thing Schema",
    labelPlural: "Synced Thing Schemas"
  },
}

/* == ACTIONS === */
const actionList = [
  "setClustersAction",
  "addClusterAction",
  "updateClusterAction",
  "updatePropertyAction",
  "deleteClustersAction",
  "updateKubeLogsAction",
  "updateConfigStatusAction",
  "updateStatusDataAction",
  "updateDeploymentsAction",
  "updateStatefulsetsAction",
  "updateDaemonsetsAction",

  "setListAction",
  "setNestedListAction",
  "addItemAction",
  "addNestedItemAction",
  "updateItemAction",
  "updateNestedItemAction",
  "removeItemAction",
  "removeNestedItemAction",

  "setPagingAction",
  "updateResourceSearchAction",
  "updateEdgeAppSearchAction",

  "addSyncedThingsAction",
  "removeSyncedThingsAction",
  "setSyncedThingsAction",
  "setSyncedThingsSchemasAction",
  "updateSyncedThingsAction",
  "updateSelectedSyncedThingsAction",

  "setClusterAppsAction",
  "clearClusterAppsAction",

  "fetchClusterSoftwareStatusAction"
]

const {
  setClustersAction,
  addClusterAction,
  updateClusterAction,
  updatePropertyAction,
  deleteClustersAction,
  updateKubeLogsAction,
  updateConfigStatusAction,
  updateStatusDataAction,
  updateDeploymentsAction,
  updateStatefulsetsAction,
  updateDaemonsetsAction,

  setListAction,
  setNestedListAction,
  addItemAction,
  addNestedItemAction,
  updateItemAction,
  updateNestedItemAction,
  removeItemAction,
  removeNestedItemAction,

  setPagingAction,
  updateResourceSearchAction,
  updateEdgeAppSearchAction,

  addSyncedThingsAction,
  removeSyncedThingsAction,
  setSyncedThingsAction,
  setSyncedThingsSchemasAction,
  updateSyncedThingsAction,
  updateSelectedSyncedThingsAction,

  setClusterAppsAction,
  clearClusterAppsAction,

  fetchClusterSoftwareStatusAction
} = makeActions("edgeOrchestration", actionList)

/* === INITIAL STATE === */
const initialState = {
  [edgeOps[CLUSTER_MANAGEMENT_OLD].list]: [],
  [edgeOps[CLUSTER_MANAGEMENT_OLD].lib]: {},
  [edgeOps[CLUSTER_SOFTWARE].list]: [],
  [edgeOps[CLUSTER_SOFTWARE].lib]: {},
  [edgeOps[RESOURCES].list]: [],
  [edgeOps[RESOURCES].lib]: {},
  [edgeOps[EDGE_APPS].list]: [],
  [edgeOps[EDGE_APPS].lib]: {},
  [edgeOps[CONFIG_VERSION].list]: [],
  [edgeOps[CONFIG_VERSION].lib]: {},
  [edgeOps[VERSION_DEPLOYMENT].list]: [],
  [edgeOps[VERSION_DEPLOYMENT].lib]: {},
  [edgeOps[STATUS_DATA].lib]: {},
  [edgeOps[DEPLOYMENTS].lib]: {},
  [edgeOps[PACKAGES].list]: [],
  [edgeOps[PACKAGES].lib]: {},
  [edgeOps[CLUSTER_SYNCED_THINGS].list]: [],
  [edgeOps[CLUSTER_SYNCED_THINGS].lib]: {},
  [edgeOps[CLUSTER_SYNCED_THINGS_SCHEMAS].list]: [],
  [edgeOps[CLUSTER_SYNCED_THINGS_SCHEMAS].lib]: {},

  clusterApps: [],
  clusterAppsLib: {},

  paging: { previous_cursor: "", next_cursor: "" },
  fetchingData: false,
  resourceSearchText: "",
  resourceSearchTags: "",
  edgeAppSearchText: "",
  edgeAppSearchTags: "",
  trackingBuilds: [],
  selectedSyncedThings: []
}

/* === Reducer === */
export default createReducer(initialState, {
  [setClustersAction]: (state, { payload: { clusters=[] }}={}) => ({
    ...state,
    clusters,
    clustersLib: makeLib({ data: clusters, key: "uid" }),

  }),
  [addClusterAction]: (state, { payload: {cluster}}={}) => ({
    ...state,
    clusters: [...state.clusters, cluster],
    clustersLib: {
      ...state.clustersLib,
      [cluster.uid]: cluster
    }
  }),
  [updateClusterAction]: (state, { payload: { cluster, uid }}={}) => {
    return ({
      ...state,
      clusters: state.clusters.map(c =>
        c.uid === uid
          ? {...c, ...cluster}
          : c
      ),
      clustersLib: {
        ...state.clustersLib,
        [uid]: {...state.clustersLib[uid], ...cluster}
      }
    })
  },
  [updatePropertyAction]: (state, { payload: { uid, propertyId, value }}={}) => (
    {
      ...state,
      clusters: state.clusters.map(c => c.uid === uid?
        {
          ...c, properties: {...c.properties, [propertyId]: value }, [propertyId]: value
        }
        : c
      ),
      clustersLib: {
        ...state.clustersLib,
        [uid]: {
          ...state.clustersLib[uid],
          properties: {
            ...state.clustersLib[uid]?.properties,
            [propertyId]: value
          }
        }
      }
    }),
  [deleteClustersAction]: (state, { payload: {clusterIds}={}}={}) => {
    let nextClustersLib = {...state.clustersLib}
    //Remove deleted clusters from state.clusters and state.clustersLib
    clusterIds.forEach(clusterId => {
      delete nextClustersLib[clusterId]
    })
    return {
      ...state,
      clusters: state.clusters.filter(cluster => !clusterIds.includes(cluster.uid)),
      clustersLib: nextClustersLib
    }
  },
  [updateKubeLogsAction]: (state, { payload: {clusterId, kubeLogs}={}}={}) => {
    return {
      ...state,
      clustersLib: {
        ...state.clustersLib,
        [clusterId]: {
          ...state.clustersLib[clusterId],
          kubeLogs: kubeLogs
        }
      }
    }
  },
  [updateConfigStatusAction]: (state, { payload: {clusterId, configStatus}={}}={}) => {
    return {
      ...state,
      clustersLib: {
        ...state.clustersLib,
        [clusterId]: {
          ...state.clustersLib[clusterId],
          customEdgeApplication: {
            ...state.clustersLib[clusterId].customEdgeApplication,
            status: configStatus
          }
        }
      }
    }
  },

  //NOTE: statusData is is a hash with timestamp as the key:
  // {[timestamp]: {stats, capacities, conditions}}
  [updateStatusDataAction]: (state, { payload: {clusterId, statusData}={}}={}) => {
    //NOTE: storing lastTimestamp for fast access when displaying status in table
    const lastTimestamp = Object.keys(statusData)
      .sort((a, b) => a - b)
      .pop()
    const {
      [edgeOps[STATUS_DATA].lib]: allClusterStatus,
      [edgeOps[STATUS_DATA].lib]: {
        [clusterId]: clusterStats,
        [clusterId]: {
          data={},
          lastTimestamp: previousTimestamp
        }={}
      }={}
    } = state
    return {
      ...state,
      [edgeOps[STATUS_DATA].lib]: {
        ...allClusterStatus,
        [clusterId]: {
          ...clusterStats,
          data: {
            ...data,
            ...statusData,
          },
          lastTimestamp: lastTimestamp || previousTimestamp
        }
      }
    }
  },

  //NOTE: Only storing most recent set of deployments stats fo a given cluster
  [updateDeploymentsAction]: (state, { payload: {clusterId, deployments}={}}={}) => {
    setWith(state, [ edgeOps[DEPLOYMENTS].lib , clusterId, "deployments" ], deployments)
  },

  [updateStatefulsetsAction]: (state, { payload: {clusterId, statefulsets}={}}={}) => {
    setWith(state, [ edgeOps[DEPLOYMENTS].lib , clusterId, "statefulsets" ], statefulsets)
  },

  [updateDaemonsetsAction]: (state, { payload: {clusterId, daemonsets}={}}={}) => {
    setWith(state, [ edgeOps[DEPLOYMENTS].lib , clusterId, "daemonsets" ], daemonsets)
  },

  [setListAction]: (state, { payload: {type, data=[], key }}={}) => {
    const { list, lib } = edgeOps[type]
    return {
      ...state,
      [list]: data,
      [lib]: makeLib({ data, key })
    }
  },
  [setNestedListAction]: (state, { payload: { type, data, path, key }}={}) => {
    return nestedProcessor({
      state,
      type,
      path,
      listHandler: data,
      libHandler:  makeLib({ data, key })
    })
  },
  [addItemAction]: (state, { payload: {type, item, key="uid"}}={}) => {
    const { list, lib } = edgeOps[type]
    let newState = {...state}
    if (list) newState[list] = [...(state[list] || []), item]
    if (lib) newState[lib] = {...(state[lib] || {}), [item[key]]: item}
    return newState
  },
  [addNestedItemAction]: (state, { payload: { type, item, path, key }}={}) => {
    return nestedProcessor({
      state,
      type,
      path,
      listHandler: list => ([...(list || []), item]),
      libHandler:  lib => ({...(lib || {}), [item[key]]: item})
    })
  },
  [updateItemAction]: (state, { payload: { type, item, id, uid }}={}) => {
    const { list, lib } = edgeOps[type]
    let key, itemId
    if (uid) {
      key = "uid"
      itemId = uid
    } else {
      key = "id"
      itemId = id
    }

    let newState = {...state}
    if (list) {
      let foundInList = false
      newState[list] = state[list].map(c => {
        let out = c
        if (c[key] === itemId) {
          out = { ...c, ...item }
          foundInList = true
        }
        return out
      })
      // if item not found in list, add to list
      if (!foundInList) newState[list].push(item)
    }
    if (lib) {
      newState[lib] = {
        ...state[lib],
        [itemId]: { ...state[lib][key], ...item }
      }
    }
    return newState
  },
  [updateNestedItemAction]: (state, { payload: { type, item, idName="id", id, path }}) => {
    return nestedProcessor({
      state,
      type,
      path,
      listHandler: makeUpdateList({ idName, id, item }),
      libHandler:  makeUpdateLib({ idName, id, item })
    })
  },
  [removeItemAction]: (state, { payload: {type, ids, idName="id"}={}}={}) => {
    const { list, lib } = edgeOps[type]
    let nextLib = {...state[lib]}
    //Remove deleted resources from state.resources and state.resourcesLib
    ids.forEach(id => {
      delete nextLib[id]
    })
    return {
      ...state,
      [list]: state[list].filter(item => !ids.includes(item[idName])),
      [lib]: nextLib
    }
  },
  [removeNestedItemAction]: (state, { payload: { type, ids, idName="id", path }}) => {
    return nestedProcessor({
      state,
      type,
      path,
      listHandler: list => list.filter(item => !ids.includes(item[idName])),
      libHandler:  lib => {
        ids.forEach(id => {
          delete lib[id]
        })
        return lib
      }
    })
  },

  [setPagingAction]:  (state, { payload: { paging }}) => {
    return {
      ...state,
      paging
    }
  },
  [updateResourceSearchAction]: (state, { payload: { text, tags }}) => {
    return {
      ...state,
      resourceSearchText: text,
      resourceSearchTags: tags
    }
  },
  [updateEdgeAppSearchAction]: (state, { payload: { text, tags }}) => {
    return {
      ...state,
      edgeAppSearchText: text,
      edgeAppSearchTags: tags
    }
  },
  [addSyncedThingsAction]: (state, { payload: { things } }) => {
    const {
      syncedThings,
      syncedThingsLib
    } = state
    const newLib = things.reduce((lib, thing) => {
      lib[thing.uid] = thing
      return lib
    }, {...syncedThingsLib})
    const newThings = [...syncedThings, ...things]
    return {
      ...state,
      syncedThings: newThings,
      syncedThingsLib: newLib
    }
  },
  [removeSyncedThingsAction]: (state, { payload: { ids } }) => {
    const {
      syncedThings,
      syncedThingsLib
    } = state
    const nextSyncedThings = [...syncedThings].filter(thing => !ids.includes(thing.uid))
    const nextLib = {...syncedThingsLib}
    for (let id of ids) {
      delete nextLib[id]
    }
    return {
      ...state,
      syncedThings: nextSyncedThings,
      syncedThingsLib: nextLib,
    }
  },
  [setSyncedThingsAction]: (state, { payload: { syncedThings } }) => {
    return {
      ...state,
      syncedThings,
      syncedThingsLib: makeLib({ data: syncedThings, key: "uid"})
    }
  },
  [setSyncedThingsSchemasAction]: (state, { payload: { schemas } }) => {
    return {
      ...state,
      syncedThingsSchemas: schemas,
      syncedThingsSchemasLib: makeLib({ data: schemas, key: "uid"})
    }
  },
  [updateSyncedThingsAction]: (state, { payload: { things } }) => {
    const {
      syncedThings,
      syncedThingsLib
    } = state
    const newSyncedThings = syncedThings.map(thing => {
      const updatedThing = things.find(t => t.uid === thing.uid)
      return updatedThing ?? thing
    })
    const newLib = {...syncedThingsLib}
    for (const thing of things) {
      newLib[thing.uid] = thing
    }
    return {
      ...state,
      syncedThings: newSyncedThings,
      syncedThingsLib: newLib
    }
  },
  [updateSelectedSyncedThingsAction]: (state, { payload: { selected } }) => ({
    ...state,
    selectedSyncedThings: selected
  }),
  [setClusterAppsAction]: (state, { payload: { nextClusterApps } }) => ({
    ...state,
    clusterApps: nextClusterApps,
    clusterAppsLib: makeLib({ data: nextClusterApps, key: "edgeAppId" })
  }),
  [clearClusterAppsAction]: (state) => ({
    ...state,
    clusterApps: [],
    clusterAppsLib: {}
  }),
  [fetchClusterSoftwareStatusAction]: (state, {payload: { clusterId, status }}) => ({
    ...state,
    clusterSoftware: status,
    clusterSoftwareLib: {
      [clusterId]: status
    }
  }),

  [CLEAN_SPACE]: () => initialState
})

/* === GENERIC DISPATCHERS === */
// id/s in the context of these dispatchers reference the URL props needed
// to build with the endpoint library they xItem funcs reference
// (see /utils/apiActions and /constants/routes.requestEndpoints)
const fetchList = (type, { id, ids, format, options={}, key, path=[] }={}) => {
  return async (dispatch, getState) => {
    // fetching endpoint
    let data = []
    try {
      const { paging, ...response} = await getList(type, {...options, id, ids})
      if (paging) dispatch(setPagingAction({ paging }))
      data = response.data
      if (format instanceof Function) data = format(data)
    }
    catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: `${fetchingError} ${edgeOps[type].labelPlural}.`,
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
      const {
        edgeOrchestration: {
          [edgeOps[type].list]: oldData
        }={}
      } = getState()
      data = oldData
    }

    if (typeof options?.params?.next_cursor !== "undefined") {
      const {
        edgeOrchestration: {
          [edgeOps[type].list]: list
        }={}
      } = getState()
      data = [...list, ...data]
    }

    if (Array.isArray(path) && path.length > 0) {
      dispatch(setNestedListAction({ type, data, key, path }))
    } else {
      dispatch(setListAction({ type, data, key }))
    }
    return data
  }
}

const fetchItemsById = (type, ids, format, options={}) => {
  return async (dispatch) => {
    try {
      let data = await Promise.all(ids.map(id => getItem({type, id}, options)))
      if (format instanceof Function) data = data.map(item => format(item))
      dispatch(setListAction({ type, data }))
      return data
    }
    catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: `${fetchingError} ${edgeOps[type].label}`,
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

const fetchItem = (type, id, {format, options={}}={}) => {
  return async (dispatch) => {
    try {
      let inputs = { type }
      if (Array.isArray(id)) inputs.ids = id
      else inputs.id = id
      let item = await getItem(inputs, options)
      if (format instanceof Function) item = format(item)
      dispatch(updateItemAction({ type, item, id: item.id }))
      return item
    }
    catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: `${fetchingError} ${edgeOps[type].label}`,
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

const addItem = (type, item, {id, ids, options={}, key, path=[]}={}) => {
  return async dispatch => {
    try {
      const data = await postItem({type, body: item, id, ids}, options)
      if (Array.isArray(path) && path.length > 0) {
        dispatch(addNestedItemAction({type, item: data, key, path}))
      } else {
        dispatch(addItemAction({type, item: data, key}))
      }
      return data
    }
    catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: `Error creating ${edgeOps[type].label}.`,
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
      throw error
    }
  }
}

const updateItem = ({type, id, ids, updatedItem={}, path=[]}, {usePatch, ...options}={}) => {
  return async dispatch => {
    try {
      const request = usePatch ? patchItem : putItem
      const data = await request({type, id, ids, body: updatedItem}, options)
      if (Array.isArray(path) && path.length > 0) {
        dispatch(updateNestedItemAction({ type, item: data, id, path}))
      } else {
        dispatch(updateItemAction({ type, item: data, id}))
      }
      return data
    }
    catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: `Error updating ${edgeOps[type].label}.`,
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

const removeItems = ({type, ids, options={}, autoFetch=true, path=[] }) => {
  return async dispatch => {
    try {
      // Instead of resolving each individual promise, we store them in an array and resolve
      // all together with a Promise.all. If any of the request fails, the catch block will handle
      // the error.
      // Note: if we want to notify the user which specific resource could not be deleted, we should
      // handle this differently
      const responses = ids.map(async id => {
        if (Array.isArray(id)) return deleteItem({ type, ids: id}, options)
        else return deleteItem({type, id}, options)
      })
      await Promise.all(responses)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: `${ids.length === 1 ? edgeOps[type].label: `${edgeOps[type].labelPlural}`} deleted successfully`,
        type: MESSAGE_TYPE_SUCCESS
      })

      if (Array.isArray(path) && path.length > 0) {
        dispatch(removeNestedItemAction({ type, ids, path }))
      } else {
        dispatch(removeItemAction({type, ids}))
      }
    }
    catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: `${ids.length === 1 ? edgeOps[type].label: `Some ${edgeOps[type].labelPlural}`} could not be deleted`,
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
    autoFetch && dispatch(fetchList(type))
  }
}


/* === DISPATCHERS === */

// we are expecting this to be wrapped in a dispatch, so it should be find to not wrap them here
export const getResources = (options={}) => {
  return (dispatch, getState) => {
    const { edgeOrchestration: { resources } } = getState()
    return dispatch(fetchList(RESOURCES, zipLists(resources), {params: { limit: 200 }, ...options}))
  }
}

export const getResourcesById = resourceIds => {
  return fetchItemsById(RESOURCES, resourceIds)
}

export const getFullResource = resourceId => {
  return fetchItem(RESOURCES, resourceId)
}

export const addResource = resource => {
  return async dispatch => {
    const type = RESOURCES
    let data
    try {
      data = await postItem({type, body: resource})
    }
    catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: `Error creating ${edgeOps[type].label}.`,
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
    if (data) {
      await dispatch(addItemAction({ type, item: { ...data, file: resource.file }}))
      return data
    }
  }
}

export const updateResource = (resource={}, updatedResource={}) => {
  return async dispatch => {
    const type = RESOURCES
    try {
      const data = await putItem({type, id: resource.id, body: updatedResource})

      dispatch(updateItemAction({ type, item: data, id: data.id }))

      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: `${edgeOps[type].label} updated successfully`,
        type: MESSAGE_TYPE_SUCCESS
      })

    }
    catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: `Error updating ${edgeOps[type].label}.`,
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const addLabelToResource = (label, resourceId) => {
  return async (dispatch, getState) => {
    const type = RESOURCES
    let data
    try {
      const {
        edgeOrchestration: {
          [edgeOps[type].lib]: lib
        }={},
        labels: {
          labels: labelsList
        }={}
      } = getState()
      const { [resourceId]: resource } = lib
      const updatedResource = {
        ...resource,
        labels: [...(resource.labels || []), label]
      }

      data = await putItem({type, id: resourceId, body: updatedResource})
      if(!labelsList.some(l=> l.label_name === label)) dispatch(addLabelToStore({ label_name: label }))
    }
    catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Label could not be added.",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
    if (data) dispatch(getFullResource(resourceId))
  }
}

export const removeLabelFromResource = (label, resourceId) => {
  return async (dispatch, getState) => {
    const type = RESOURCES
    let data
    try {
      const {
        edgeOrchestration: {
          [edgeOps[type].lib]: lib
        }={}
      } = getState()
      const resource = lib[resourceId]
      const { labels } = resource
      const updatedResource = {
        ...resource,
        labels: labels.filter(l => l !== label)
      }
      data = await putItem({type, id: resourceId, body: updatedResource})
    }
    catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Label could not be removed.",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
    if (data) dispatch(getFullResource(resourceId))
  }
}

export const deleteResources = resourceIds => {
  return removeItems({type: RESOURCES, ids: resourceIds})
}

export const getEdgeApps = () => {
  return fetchList(EDGE_APPS)
}

export const getEdgeAppsById = edgeAppIds => {
  return fetchItemsById(EDGE_APPS, edgeAppIds)
}

export const getFullEdgeApp = edgeAppId => {
  return fetchItem(EDGE_APPS, edgeAppId)
}

export const addEdgeApp = edgeApp => {
  if (edgeApp.id && edgeApp.clone_allow){
    const item = {
      id: edgeApp.id,
      suffix: edgeApp.suffix
    }
    return addItem(EDGE_APPS, item)
  } else if (!edgeApp.id){
    return addItem(EDGE_APPS, edgeApp)
  } else {
    const body = {
      id: edgeApp.id
    }
    return addItem(EDGE_APPS, body)
  }
}

export const updateEdgeApp = (edgeApp={}, updatedEdgeApp={}) => {
  return updateItem({type: EDGE_APPS, id: edgeApp.id, updatedItem: updatedEdgeApp})
}

export const deleteEdgeApps = edgeAppIds => {
  return removeItems({type: EDGE_APPS, ids: edgeAppIds})
}

export const getVersions = edgeAppId => {
  return fetchList(CONFIG_VERSION, { id: edgeAppId, key: "version", path: [edgeAppId] })
}

export const addVersion = ({edgeAppId, version}) => {
  return addItem(CONFIG_VERSION, version, { id: edgeAppId, key: "version", path: [edgeAppId] })
}

export const getDeployments = ({ edgeAppId, versionId }) => {
  const idList = [edgeAppId, versionId]
  return fetchList(VERSION_DEPLOYMENT, { ids: idList, path: idList, key: "name" })
}

export const addDeployment = ({ edgeAppId, versionId, deployment}) => {
  const idList = [edgeAppId, versionId]
  return addItem(VERSION_DEPLOYMENT, deployment, { ids: idList, path: idList, key: "name" })
}

export const updateDeployment = ({ edgeAppId, versionId, deploymentId, deployment }) => {
  const idList = [edgeAppId, versionId, deploymentId]
  return updateItem({
    type: VERSION_DEPLOYMENT,
    updatedItem: deployment,
    ids: idList,
    path: idList.slice(0, -1) // this is to remove the ID of the item, list is an array
  })
}

export const deleteDeployment = ({ edgeAppId, versionId, deploymentId }) => {
  const idList = [edgeAppId, versionId, deploymentId]
  return removeItems({
    type: VERSION_DEPLOYMENT,
    ids: [idList],
    path: idList.slice(0,-1), // this is to remove the ID of the item, list is an array
    autoFetch: false
  })
}

export const updateResourceSearch = ({ text, tags }) => {
  return dispatch => dispatch(updateResourceSearchAction({ text, tags }))
}

export const updateEdgeAppSearch = ({ text, tags }) => {
  return dispatch => dispatch(updateEdgeAppSearchAction({ text, tags }))
}

export const getPackages = () => {
  return fetchList(PACKAGES, { options: { params: { limit: 50 }, rootRequest: true } })
}

export const getPackagesEdgeApp = id => {
  return fetchItem(PACKAGES, id, { options: { rootRequest: true } })
}

export const getNextPackages = (params={}) => {
  return async (dispatch, getState) => {
    const {
      edgeOrchestration: {
        paging: oldPaging
      }={}
    } = getState()

    return dispatch(fetchList(PACKAGES, { options: { params: { limit: 200, next_cursor: oldPaging.next_cursor, ...params } , rootRequest: true } }))
  }
}

export const addEdgeAppsFromPackages = (edgeApps=[]) => {
  return async dispatch => {
    try {
      // Instead of resolving each individual promise, we store them in an array and resolve
      // all together with a Promise.all. If any of the request fails, the catch block will handle
      // the error.
      const requestGenerators = edgeApps.map(edgeApp => () => dispatch(addEdgeApp(edgeApp)))
      const data = await paceRequests({ requestGenerators })
      return data
    }
    catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: `${edgeApps.length === 1 ? edgeOps[EDGE_APPS].label: `Some ${edgeOps[EDGE_APPS].labelPlural}`} could not be created`,
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

/* === UTILS === */

// this is a util func for handling traversal for store items with nested keys
// these are things like versions and deployments, where the lists need to be stored like:
//   edgeAppVersions[edgeAppId] = [...versions]
//   versionDeployments[edgeAppId][versionId] = [...deployments]
// Requires:
//   state object, type, keys array of string nested keys, list and lib handler functions
//   key array should not include the idea if a single item is intended to be changed
//   list and lib handler are processing functions, given the list or lib needing updating
//   they should return the list or lib updated with the new changes
// Returns:
//   new state to apply to the store
const nestedProcessor = ({ state={}, type, path=[], listHandler=()=>{}, libHandler=()=>{} }) => {
  const { list, lib } = edgeOps[type]
  let nextList = {...state[list]}
  let nextLib = {...state[lib]}
  let targetList = nextList
  let targetLib = nextLib

  const last = path.length - 1

  path.forEach((key, i) => {
    if (i === last) {
      if (list)
        targetList[key] = listHandler instanceof Function ? listHandler(targetList[key]) : listHandler
      if (lib)
        targetLib[key] = libHandler instanceof Function ? libHandler(targetLib[key]) : libHandler
    } else {
      // set to make sure key exists
      targetList[key] = {...targetList[key]}
      targetLib[key] = {...targetLib[key]}

      // update position for advancing
      targetList = targetList[key]
      targetLib = targetLib[key]
    }
  })

  return {
    ...state,
    [list]: nextList,
    [lib]:  nextLib
  }
}

// function to update a single item in the list
// intended for use with nestedProcessor
// Require:
//   idName to reference list item against updated item, id value of idName, item the updated obj
// Returns:
//   new list with the updated item values
const makeUpdateList = ({ idName, id, item }) => list => {
  let foundInList = false
  let out = list.map(listItem => {
    let out = listItem
    if (listItem[idName] === id) {
      out = {...listItem, ...item}
      foundInList = true
    }
    return out
  })
  if (!foundInList) out = [...out, item]
  return out
}

// same as above, but for libs
const makeUpdateLib = ({ id, item }) => (lib) => {
  return {
    ...lib,
    [id]: {
      ...lib[id],
      ...item
    }
  }
}
