import React, {useMemo, useState} from "react"

import { MQTT_HOST } from "constants/config"

import TopicTreeNode from "./TopicTreeNode"


//Renders tree menu of topics
const TopicTree = ({
  data={},
  selectedTopic,
  onNodeClick
}={}) => {
  const [openNodes, setOpenNodes] = useState(new Set([MQTT_HOST]))

  //Add isOpen and key to nodes
  const formatNode = (node) => {
    return {
      ...node,
      key: node?.topic,
      isOpen: openNodes.has(node?.topic),
      children: node?.children?.map(formatNode)
    }
  }
  const tree = useMemo(() => {
    return formatNode(data)
  }, [openNodes, data])

  //Open node that is passed in, also open all children except when nodes have siblings.
  const expandOnlyChild = ({topic, children=[]}) => {
    let nextOpenNodes = new Set(openNodes)
    let nextChildren = children
    let nextTopic = topic

    //Always open first node
    nextOpenNodes.add(topic)

    while(nextChildren.length === 1) {
      const child = nextChildren[0]

      //expand current node
      nextOpenNodes.add(nextTopic)
      nextChildren = child?.children || []
      nextTopic = child.topic
    }
    //Open next node
    nextOpenNodes.add(nextTopic)

    setOpenNodes(nextOpenNodes)
  }

  const handleToggleNode = ({topic, name, isOpen, children}) => {
    const nextOpenNodes = new Set(openNodes)
    if (isOpen) {
      nextOpenNodes.delete(topic)
      setOpenNodes(nextOpenNodes)
    } else {
      //recursively expand children if only one child in node
      expandOnlyChild({topic, name, children})
    }
  }

  return <TopicTreeNode
    {...tree}
    key='root-node'
    selectedTopic={selectedTopic}
    onToggleNode={handleToggleNode}
    onNodeClick={onNodeClick}
  />
}

export default TopicTree
