import {
  UnityDropdown,
  UnityTypography
} from "@bit/smartworks.unity-react.unity-core-react"
import React, {useEffect} from "react"
import { connect } from "react-redux"

// import {colors} from 'assets/stylesheets/common'
import { FUNCTIONS } from "constants/routes"
import { getFunctions } from "reduxModules/serverlessFunctions"
import { updateTabInBelt } from "utils/utilityBelt"


const stateToProps = ({
  serverlessFunctions: {
    functions=[]
  }={}
}) => ({
  functions
})

const FunctionLogsSelection = ({
  functionName,
  functions=[],
  getFunctions
}) => {
  useEffect(() => {
    //Fetch functions if they do not already exist in store
    if (functions.length === 0) {
      getFunctions()
    }
  }, [])

  const functionOptions = functions.map(func => ({
    label: func.name,
    id: func.name
  }))

  const handleFunctionSelect = (name) => {
    if (name && name !== functionName) {
      updateTabInBelt({
        id: name,
        type: FUNCTIONS,
        prevId: functionName
      })
    }
  }
  return (
    <>
      <UnityTypography key="log-title">Function Logs</UnityTypography>
      <UnityDropdown
        id="function-log-selection-dropdown"
        key="log-selection-dropdown"
        selected={[functionName]}
        options={functionOptions}
        style={styles.dropdown}
        inputType='single-select'
        onValueChange={handleFunctionSelect}
        searchBox={true}
      />
    </>
  )
}

const styles = {
  dropdown: {
    marginLeft: 6,
    marginRight: 20,
    "--dropdown-width": "160px"
  }
}

export default connect(stateToProps, {getFunctions})(FunctionLogsSelection)