import axios from "axios"

import { API_GATEWAY_VERSION, PROXY_URL } from "constants/config"
import { LOGIN, LOGOUT } from "constants/routes"
import { routes } from "utils/routes"
import { getSpace } from "utils/storage"

const BASE_URL = PROXY_URL

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true
})

const SCOPE_REGEX = new RegExp(/^Scope \w+\.\w+ was not granted$/)

// add access token to requests
axiosInstance.interceptors.request.use(
  config => {
    if (config?.beta) {
      config.headers.Prefer = `preview=${API_GATEWAY_VERSION}`
    }
    if (!config.rootRequest) {
      config.baseURL += `/spaces/${getSpace()}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// Manage response errors
axiosInstance.interceptors.response.use(
  response => response,
  error => handleErrorResponse(error.response)
)

function redirectToLogIn() {
  window.location.pathname = routes[LOGIN]
}

function redirectToLogOut() {
  window.location.pathname = routes[LOGOUT]
}

const handleErrorResponse = async (response = {}) => {
  const { config = {}, data = {}, status } = response
  if (status === 401 && !config?.bypassInterceptors) {
    // Initiate login
    // NOTE that attempting to logout after the access token has expired causes errors.
    // This is why login is used instead of logout
    redirectToLogIn()
    return
  } else if (
    status === 403 &&
    SCOPE_REGEX.test(data.error?.details?.reason) &&
    !config?.bypassInterceptors
  ) {
    redirectToLogOut()
  }
  return Promise.reject(response)
}

export default axiosInstance
