import { notification } from "antd"
import React from "react"

import { ChatIcon, CircleCheckIcon, CloseIcon, WarningTriangleFillIcon } from "assets/icons"
import { colors } from "assets/stylesheets/common"
import UnityIcon from "components/unity/UnityIcon"

import "./notifications.css"


// === CONSTANTS ===
export const GLOBAL_NOTIFICATIONS = "global-notifications"
export const ADDING_THINGS_NOTIFICATIONS = "adding-things-notifications"

export const MESSAGE_TYPE_ERROR = "error"
export const MESSAGE_TYPE_WARNING = "warning"
export const MESSAGE_TYPE_SUCCESS = "success"
export const MESSAGE_TYPE_HELP = "help"
export const MESSAGE_TYPE_TIP = "tip"

const TIMEOUT = {
  [MESSAGE_TYPE_ERROR]: 8,
  [MESSAGE_TYPE_WARNING]: 5,
  [MESSAGE_TYPE_SUCCESS]: 4,
  [MESSAGE_TYPE_HELP]: 5,
  [MESSAGE_TYPE_TIP]: 5,
}

notification.config({
  bottom: 0,
  closeIcon: <UnityIcon Component={CloseIcon}/>,
  getContainer: () => document.getElementById("notifications-wrapper"),
  placement: "bottom",
})

const iconButtonProps = {
  height: 24,
  width: 24
}

// === MESSAGE MGMT UTILS ===
export function addMessage({ id, text, timeout, subtext, type=MESSAGE_TYPE_TIP }) {

  const notificationObject = {
    key: id,
    className: id,
    message: text,
    description: subtext,
    duration: timeout ? timeout / 1000 : TIMEOUT[type]
  }

  let notificationAction
  switch (type) {
  case MESSAGE_TYPE_SUCCESS:
    notificationAction = notification.success
    notificationObject.icon = <UnityIcon Component={CircleCheckIcon} color={colors.success} {...iconButtonProps}/>
    break
  case MESSAGE_TYPE_ERROR:
    notificationAction = notification.error
    notificationObject.icon = <UnityIcon Component={WarningTriangleFillIcon} color={colors.redOrange} {...iconButtonProps}/>
    break
  case MESSAGE_TYPE_WARNING:
    notificationAction = notification.warning
    notificationObject.icon = <UnityIcon Component={WarningTriangleFillIcon} color={colors.danger1} {...iconButtonProps}/>
    break
  case MESSAGE_TYPE_HELP:
    notificationAction = notification.info
    notificationObject.icon = <UnityIcon Component={ChatIcon} color={colors.secondary} {...iconButtonProps}/>
    break
  default:
    notificationAction = notification.open
    break
  }

  notificationAction(notificationObject)
}
