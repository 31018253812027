import moment from "moment"

import { del, get, post, put } from "utils/api"

import { EQUAL_OPERATOR } from "./tableColumns"

export const UBUNTU_CL200 = "ubuntu_cl200"
export const AMD_64 = "ubuntu_amd64_general"

export async function getEcpImagesRequest(params) {
  const response = await get("/ecpimages", params)
  const { status, data } = response
  if (status === 200) return data
  throw new Error(`Request failed with status ${response.status} - ${response.data}`)
}

export async function createEcpImageRequest(ecpImageData) {
  const response = await post("/ecpimages", ecpImageData)
  const { status, data } = response
  if (status !== 200) throw new Error(`Create EcpImage failed with status ${response.status} - ${response.data}`)
  return data
}

export async function updateEcpImageRequest(id, ecpImageData) {
  const response = await put(`/ecpimages/${id}`, ecpImageData)
  const { status, data } = response
  if (status === 200) return data
  throw new Error(`Request failed with status ${response.status} - ${response.data}`)
}

export async function deleteEcpImageRequest(id) {
  const response = await del(`/ecpimages/${id}`)
  // if (response.status !== 204) throw response // delete user requests return null when successful, and an error object on failure
  if (response) throw response //TODO: replace this line with the one above when api response changes
  return response
}

export async function getECPImagesSoftware() {
  const response = await get("/internal-ota-images")
  const { status, data } = response
  if (status === 200) return data.data
  throw new Error(`Request failed with status ${status} - ${data}`)
}

export const initialColumns = [
  {
    key: "title",
    label: "Title",
    showFilter: true,
    singleFilter: true,
    operators: [EQUAL_OPERATOR]

  },
  {
    key: "id",
    label: "ID"
  },
  // {
  //   key: 'description',
  //   label: 'Description'
  // },
  {
    key: "status",
    label: "Status"
  },
  //Comment type out until there are more than one type of image
  // {
  //   key: 'type',
  //   label: 'Type'
  // },
  {
    key: "created",
    label: "Created"
  },
  {
    key: "expired",
    label: "Expires",
    formatLabel: (colValue) => colValue ? moment.utc(colValue).fromNow() : ""
  },
  {
    key: "download_url",
    label: "Download",
  },
  //Comment labels column out until it is confirmed that ecpImages can have labels
  // {
  //   key: 'labels',
  //   label: 'Labels'
  // },
]
