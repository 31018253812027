import Cookie from "js-cookie"

const API_KEY = "SE_API_KEY"
// const USER_CD = 'SE_USER_CD'
const COOKIE = "COOKIE"
const SESSION_STORAGE = "SESSION_STORAGE"
const LOCAL_STORAGE = "LOCAL_STORAGE"

export const THINGS_TABLE = "things"
export const CLUSTERS_TABLE = "clusters"
export const RESOURCES_TABLE = "resources"
export const FUNCTIONS_TABLE = "functions"
export const TRIGGERS_TABLE = "triggers"

export const setKey = (apiKey) => {
  try {
    Cookie.set(API_KEY, apiKey)
  } catch (error) {
    // Error saving apikey
  }
}

export const getKey = () => {
  try {
    const value = Cookie.get(API_KEY)
    if (value !== null) {
      return value
    }
  } catch (error) {
    // Error retrieving data
  }
}

export const clearKey = () => {
  try {
    Cookie.remove(API_KEY)
  } catch (error) {
    // Error clearing apikey
  }
}

// user preferences for visible columns in Things table
export const getTableColumns = (table) => {
  const columns = getFromStorage(table, LOCAL_STORAGE)
  return columns && JSON.parse(columns)
}

export const setTableColumns = (table, columns) => {
  const jsonColumns = JSON.stringify(columns)
  saveInStorage(table, jsonColumns, LOCAL_STORAGE)
}

export const clearTableColumns = (table) => {
  removeFromStorage(table, LOCAL_STORAGE)
}

export const getTableSort = (table, username) => {
  const sortBy = getFromStorage(`${table}-${username}-sort-by`, LOCAL_STORAGE)
  return sortBy && JSON.parse(sortBy)
}

export const setTableSort = (table, sortBy, username) => {
  const jsonSortBy = JSON.stringify(sortBy)
  saveInStorage(`${table}-${username}-sort-by`, jsonSortBy, LOCAL_STORAGE)
}

export const getTableFilters = (table, username) => {
  const space = getSpace()
  const storageKey = `${username}-${space}-${table}-filters`
  const columnFilters = getFromStorage(storageKey, LOCAL_STORAGE)
  return (columnFilters && JSON.parse(columnFilters)) || {}
}

export const setTableFilters = (table, filters, username) => {
  const space = getSpace()
  const jsonFilters = JSON.stringify(filters)
  const storageKey = `${username}-${space}-${table}-filters`
  saveInStorage(storageKey, jsonFilters, LOCAL_STORAGE)
}

export const getLastRoute = () => {
  return getFromStorage("lastRoute", SESSION_STORAGE)
}

export const setLastRoute = authState => {
  saveInStorage("lastRoute", authState, SESSION_STORAGE)
}

export const clearLastRoute = () => {
  removeFromStorage("lastRoute", SESSION_STORAGE)
}

export const initDesignSession = () => {
  saveInStorage("designSession", Date.now(), COOKIE) //30 minutes
}

export const getDesignSession = () => {
  return getFromStorage("designSession", COOKIE)
}

export const initStreamsSession = () => {
  saveInStorage("streamsSession", Date.now(), COOKIE) //30 minutes
}

export const getStreamsSession = () => {
  return getFromStorage("streamsSession", COOKIE)
}

// SPACES

export const getSpace = () => {
  return getFromStorage("space", LOCAL_STORAGE)
}

export const setSpace = (space) => {
  saveInStorage("space", space, LOCAL_STORAGE)
}

export const clearSpace = () => {
  removeFromStorage("space", LOCAL_STORAGE)
}

const removeOldSpaceLabel = (spaceId) => {
  const spaceLabel = getFromStorage(`${spaceId}-collection`)
  if (spaceLabel) {
    removeFromStorage(`${spaceId}-collection`, LOCAL_STORAGE)
  }
}

export const getSpaceCategory = (spaceId) => {
  removeOldSpaceLabel(spaceId)
  return getFromStorage(`${spaceId}-category`, LOCAL_STORAGE)
}

export const setSpaceCategory = (spaceId, category) => {
  removeOldSpaceLabel(spaceId)
  saveInStorage(`${spaceId}-category`, category, LOCAL_STORAGE)
}

export const clearSpaceCategory = (spaceId) => {
  removeOldSpaceLabel(spaceId)
  removeFromStorage(`${spaceId}-category`, LOCAL_STORAGE)
}

//NOTE: this is a global setting that applies to all spaces.
export const setPinnedCategory = (pinned, username) => {
  saveInStorage(`pinned-category-${username}`, pinned, LOCAL_STORAGE)
}

export const getPinnedCategory = (username) => {
  const storedPinned = getFromStorage(`pinned-category-${username}`, LOCAL_STORAGE)
  try {
    return storedPinned && JSON.parse(storedPinned)
  } catch (e) {
    return null
  }
}

export const setPinnedFleet = (pinned) => {
  saveInStorage("pinned-fleet", pinned, LOCAL_STORAGE)
}

export const getPinnedFleet = () => {
  const storedPinned = getFromStorage("pinned-fleet", LOCAL_STORAGE)
  try {
    return storedPinned && JSON.parse(storedPinned)
  } catch (e) {
    return null
  }
}

// TUTORIALS

const SPACES_TUTORIAL_COMPLETE = "spacesTutorialComplete"
const ANYTHING_DB_TUTORIAL_COMPLETE = "anythingDbTutorialComplete"
export const setSpacesTutorialComplete = (user) => {
  saveInStorage(`${SPACES_TUTORIAL_COMPLETE}-${user}`, true, LOCAL_STORAGE)
}

export const getSpacesTutorialComplete = (user) => {
  return getFromStorage(`${SPACES_TUTORIAL_COMPLETE}-${user}`, LOCAL_STORAGE)
}

export const setAnythingDbTutorialComplete = (user) => {
  saveInStorage(`${ANYTHING_DB_TUTORIAL_COMPLETE}-${user}`, true, LOCAL_STORAGE)
}

export const getAnythingDbTutorialComplete = (user) => {
  return getFromStorage(`${ANYTHING_DB_TUTORIAL_COMPLETE}-${user}`, LOCAL_STORAGE)
}

export const clearAllTutorialsComplete = (user) => {
  removeFromStorage(`${SPACES_TUTORIAL_COMPLETE}-${user}`, LOCAL_STORAGE)
  removeFromStorage(`${ANYTHING_DB_TUTORIAL_COMPLETE}-${user}`, LOCAL_STORAGE)
  return
}

// DIAGRAMDB
export const setViewport = (viewport) => {
  const spaceId = getSpace()
  saveInStorage(`${spaceId}-viewport`, JSON.stringify(viewport), LOCAL_STORAGE)
}

export const getViewport = () => {
  const spaceId = getSpace()
  const viewport = getFromStorage(`${spaceId}-viewport`, LOCAL_STORAGE)
  if (viewport) {
    return JSON.parse(viewport)
  }
  return null
}

export const setNodePositions = (nodes) => {
  const spaceId = getSpace()
  saveInStorage(
    `${spaceId}-node-positions`,
    JSON.stringify(nodes),
    LOCAL_STORAGE
  )
}

export const getNodePositions = () => {
  const spaceId = getSpace()
  const nodePositions = getFromStorage(
    `${spaceId}-node-positions`,
    LOCAL_STORAGE
  )
  if (nodePositions) {
    return JSON.parse(nodePositions, (key, value) => {
      if (key === "className") {
        return "category-node"
      }
      return value
    })
  }
  return null
}

export const setNeedsAutoLayout = (value) => {
  const spaceId = getSpace()
  saveInStorage(
    `${spaceId}-node-needsAutoLayout`,
    JSON.stringify(value),
    LOCAL_STORAGE
  )
}

export const getNeedsAutoLayout = () => {
  const spaceId = getSpace()
  const needsAutoLayout = JSON.parse(
    getFromStorage(`${spaceId}-node-needsAutoLayout`, LOCAL_STORAGE)
  )
  return needsAutoLayout
}

// UTIL FUNCTIONS

function saveInStorage(key, value, storageType) {
  try {
    switch (storageType) {
    case COOKIE:
      Cookie.set(key, value)
      break
    case SESSION_STORAGE:
      sessionStorage.setItem(key, value)
      break
    case LOCAL_STORAGE:
      localStorage.setItem(key, value)
      break
    default:
      return
    }
  } catch (error) {
    console.error(
      "saveInStorage error: ",
      error,
      ", key: ",
      key,
      ", value: ",
      value,
      ", storageType: ",
      storageType
    )
  }
}

function getFromStorage(key, storageType) {
  try {
    switch (storageType) {
    case COOKIE:
      return Cookie.get(key)
    case SESSION_STORAGE:
      return sessionStorage.getItem(key)
    case LOCAL_STORAGE:
      return localStorage.getItem(key)
    default:
      return
    }
  } catch (error) {
    console.error(
      "getFromStorage error: ",
      error,
      ", key: ",
      key,
      ", storageType: ",
      storageType
    )
  }
}

function removeFromStorage(key, storageType) {
  try {
    switch (storageType) {
    case COOKIE:
      Cookie.remove(key)
      break
    case SESSION_STORAGE:
      sessionStorage.removeItem(key)
      break
    case LOCAL_STORAGE:
      localStorage.removeItem(key)
      break
    default:
      return
    }
  } catch (error) {
    console.error(
      "removeFromStorage error: ",
      error,
      ", key: ",
      key,
      ", storageType: ",
      storageType
    )
  }
}

//UTILS EDGE OPS

export const setEdgeAppAdded = (added) => {
  saveInStorage("added-edgeapp", added, LOCAL_STORAGE)
}

export const getEdgeAppAdded = () => {
  const storedAddedEdgeApp = getFromStorage("added-edgeapp", LOCAL_STORAGE)
  try {
    return storedAddedEdgeApp && JSON.parse(storedAddedEdgeApp)
  } catch (e) {
    return null
  }
}

export const setDistributionAdded = (added) => {
  saveInStorage("added-distribution", added, LOCAL_STORAGE)
}

export const getDistributionAdded = () => {
  const storedAddedDistribution = getFromStorage("added-distribution", LOCAL_STORAGE)
  try {
    return storedAddedDistribution && JSON.parse(storedAddedDistribution)
  } catch (e) {
    return null
  }
}
