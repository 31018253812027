import { del, get, post, put } from "utils/api"


export async function getHfdRequest() {
  const response = await get("hfd/mqtt-credentials")
  const {
    status,
    data
  } = response
  if(status === 200) {
    return data
  }
  else {
    return []
  }
}

export async function getHfdDetailsRequest(id) {
  const response = await get(`hfd/mqtt-credentials/${id}`)
  const {
    status,
    data
  } = response
  if(status === 200) {
    return data
  }
  else {
    return {}
  }
}

export async function createHfdRequest(hfdBody) {
  const response = await post("hfd/mqtt-credentials", hfdBody)
  const {
    status,
    data
  } = response
  if (status === 200) return data
  throw response
}

export async function updateHfdRequest(id, hfdBody) {
  const response = await put(`hfd/mqtt-credentials/${id}`, hfdBody)
  const {
    status,
    data
  } = response
  if (status === 200) return data
  throw response
}

export async function deleteHfdRequest(id) {
  const response = await del(`hfd/mqtt-credentials/${id}`)
  
  //TODO: This is temporarily commented out until api response changes
  // if (response.status !== 204) throw response // delete app requests return null when successful, and an error object on failure
  if (response) throw response //TODO: replace this line with the one above when api response changes
  return response
}