/* === IMPORTS === */
import { createAction, createReducer } from "@reduxjs/toolkit"

import { makeLib } from "utils/misc"
import {
  CLUSTERS_TABLE,
  FUNCTIONS_TABLE,
  getTableColumns,
  RESOURCES_TABLE,
  setTableColumns
} from "utils/storage"
import { initialColumns } from "utils/things"

const setColumnsAction = createAction("SET_COLUMNS")
const setVisibleColumnsAction = createAction("SET_VISIBLE_COLUMNS")
const initialColumnsKeys = initialColumns.map(({ key }) => key)
/* === INITIAL STATE === */
// Model DB table names are saved in following format:
// [`${workspaceId}-${collection}-collection]: {
//   columns: [],
//   visibleColumns: []
// },
const initialState = {
  [CLUSTERS_TABLE]: {
    columns: [],
    visibleColumns: []
  },
  [RESOURCES_TABLE]: {
    columns: [],
    visibleColumns: []
  },
  [FUNCTIONS_TABLE]: {
    columns: [],
    visibleColumns: []
  },

  //TODO: add other tables
}

/* === Reducer === */
export default createReducer(initialState, {
  [setColumnsAction]: (state, { payload: { table="", columns=[], visibleColumns=[] } }) => {
    return {
      ...state,
      [table]: {
        ...state[table],
        columns,
        visibleColumns
      }
    }
  },
  [setVisibleColumnsAction]: (state, { payload: { table="", visibleColumns=[] } }) => ({
    ...state,
    [table]: {
      ...state[table],
      visibleColumns
    }
  })
})

/* === DISPATCHERS === */
//Sets columns, if visibleColumns is empty, sets that to columns too
export const setColumns = ({ table, columns }) => {
  return (dispatch, getState) => {
    const {
      tableColumns: {
        [table]: {
          visibleColumns: originalVisible=[]
        }={}
      }={}
    } = getState()
    const localColumns = getTableColumns(table)
    const colLib = makeLib({ data: columns, key: "key" })
    // compare original vs columns
    // start with saved cookie if visible is empty
    const testColumns = (originalVisible.length === 0 ? localColumns : originalVisible) || []
    // filter out old columns
    let nextVisibleColumns = testColumns.filter(({ key }) => colLib[key])
    // if empty at the end, use all columns
    if (nextVisibleColumns.length === 0) nextVisibleColumns = columns.filter(({key}) => initialColumnsKeys.includes(key))

    // set local storage columns
    setTableColumns(table, nextVisibleColumns)
    return dispatch(setColumnsAction({ table, columns, visibleColumns: nextVisibleColumns }))
  }
}

export const setVisibleColumns = ({ table, visibleColumns }) => {
  return (dispatch, getState) => {
    const {
      tableColumns: {
        [table]: {
          columns=[]
        }={}
      }={}
    } = getState()
    setTableColumns(table, visibleColumns)

    return columns.length === 0
      ? dispatch(setColumnsAction({ table, columns: visibleColumns, visibleColumns }))
      : dispatch(setVisibleColumnsAction({ table, visibleColumns }))
  }
}
