/* === IMPORTS === */
import { createReducer } from "@reduxjs/toolkit"

import { CLEAN_SPACE } from "actions/auth"
import { parseApiErrorMessage } from "utils/api"
import { makeLib } from "utils/misc"
import { getMessageContent, getTriggerTopicParams} from "utils/mqtt"
import {
  addMessage,
  GLOBAL_NOTIFICATIONS,
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS
} from "utils/notifications"
import {
  createTriggerRequest,
  deleteTriggerRequest,
  getTriggersRequest,
  updateTriggerRequest
} from "utils/triggers"

import { makeActions } from "./utiliducks"


/* == ACTIONS === */
const actionList = [
  "setTriggersAction",
  "addTriggerAction",
  "updateTriggerAction",
  "deleteTriggersAction",
  "updateTriggerStatusAction",
  "setPagingAction",
]
const {
  setTriggersAction,
  addTriggerAction,
  updateTriggerAction,
  deleteTriggersAction,
  updateTriggerStatusAction,
  setPagingAction,
} = makeActions("triggers", actionList)

/* === INITIAL STATE === */
const initialState = {
  triggers: [],
  triggersLib: {},
  paging: {
    previous_cursor: "",
    next_cursor: ""
  },
}

/* === Reducer === */
export default createReducer(initialState, {
  [setTriggersAction]: (state, { payload: { triggers } }) => ({
    ...state,
    triggers,
    triggersLib: makeLib({ data: triggers, key: "id" }),
  }),
  [addTriggerAction]: (state, { payload: { triggerData } }) => ({
    ...state,
    triggers: [
      ...state.triggers,
      triggerData
    ],
    triggersLib: {
      ...state.triggersLib,
      [triggerData.id]: triggerData
    }
  }),
  [updateTriggerAction]: (state, { payload: { id, triggerData } }) => ({
    ...state,
    triggers: state.triggers.map(trigger => trigger.id === id ? triggerData : trigger),
    triggersLib: {
      ...state.triggersLib,
      [id]: {
        ...state.triggersLib[id],
        ...triggerData
      }
    }
  }),
  [updateTriggerStatusAction]: (state, { payload: { id, status } }) => ({
    ...state,
    triggers: state.triggers.map(trigger => trigger.id === id ? {...trigger, status} : trigger),
    triggersLib: {
      ...state.triggersLib,
      [id]: {
        ...state.triggersLib[id],
        status
      }
    }
  }),
  [deleteTriggersAction]: (state, { payload: { ids=[] } }) => {
    const triggers = state.triggers.filter(({ id }) => !ids.includes(id))
    return {
      ...state,
      triggers,
      triggersLib: makeLib({ data: triggers })
    }
  },
  [setPagingAction]: (state, { payload: { paging } }) => ({
    ...state,
    paging
  }),
  [CLEAN_SPACE]: () => initialState
})

/* === DISPATCHERS === */
export const getTriggers = () => {
  return async (dispatch) => {
    try {
      const response = await getTriggersRequest()
      const { data=[], paging } = response
      if (!Array.isArray(data)) throw new Error(`Invalid data format: ${JSON.stringify(data)}`)

      data.forEach((d) => {
        if (d.type === "mqtt") {
          if (d.config && !("password" in d.config)) {
            d.config.password=""
          }
        }
      })
      dispatch(setTriggersAction({ triggers: data }))
      if (paging) dispatch(setPaging({ paging }))
      return data
    }
    catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Event triggers could not be retrieved",
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
      return []
    }
  }
}

export const getNextTriggers = () => {
  return async (dispatch, getState) => {
    const {
      triggers: {
        triggers,
        paging: {
          next_cursor
        }={}
      }
    } = getState()
    const { data, paging } = await getTriggersRequest({ next_cursor })
    dispatch(setPaging({ paging }))
    dispatch(setTriggersAction({ triggers: [ ...triggers, ...data ]}))
  }
}

export const createTrigger = (triggerData) => {
  return async (dispatch) => {
    try {
      const response = await createTriggerRequest(triggerData)
      //modify response data format as needed
      const nextTriggerData = {
        ...response
      }
      dispatch(addTriggerAction({ triggerData: nextTriggerData }))
      return nextTriggerData
      
    } catch (error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Event trigger could not be created",
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
      throw error
    }
  }
}

export const addTriggerToStore = (triggerData) => {
  return async (dispatch) => {
    try {
      dispatch(addTriggerAction({ triggerData: triggerData }))
      return true
    }
    catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Failed to add event trigger.",
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
      return false
    }
  }
}

export const updateTrigger = (id, triggerData) => {
  return async (dispatch) => {
    try {
      const response = await updateTriggerRequest(id, triggerData) || {}
      //Update response format as needed
      const nextTriggerData = {
        ...response
      }
      dispatch(updateTriggerAction({
        id,
        triggerData: nextTriggerData
      }))
      return nextTriggerData
    } catch(error) {
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Event trigger could not be updated",
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
      throw error
    }
  }
}

//Can pass in single trigger ID to delete, or an array of trigger IDs
export const deleteTriggers = (triggerIds=[]) => {
  triggerIds = Array.isArray(triggerIds) ? triggerIds : [ triggerIds ]
  return async dispatch => {
    try {
      // TODO: we might need to pace request as we are doing with Things
      const responses = triggerIds.map(async id => {
        return deleteTriggerRequest(id)
      })
      await Promise.all(responses)
      dispatch(deleteTriggersAction({ ids: triggerIds }))

      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Delete Successful",
        subtext: `Successfully deleted ${triggerIds.length} trigger${triggerIds.length !== 1 ? "s" : ""}`,
        type: MESSAGE_TYPE_SUCCESS,
        timeout: 4000
      })
    } catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: `${triggerIds.length === 1 ? "Event trigger" : "Some event triggers"} could not be deleted`,
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const setPaging = ({ paging }) => {
  return dispatch => dispatch(setPagingAction({ paging }))
}

// Handler for trigger status mqtt messages
// Updates trigger status attribute
export const onTriggerStatusMessage = (topic, message) => {
  return (dispatch) => {
    const match = getTriggerTopicParams(topic)
    if (!match) return

    const { triggerId="" } = match
    if (triggerId) {
      const { status="" } = getMessageContent(message) || {}
      if (status) dispatch(updateTriggerStatusAction({ id: triggerId.toUpperCase(), status }))

    }
  }
}