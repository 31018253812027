import { del, get, post, put } from "utils/api"


// REQUESTS

export async function getModelsRequest() {
  const response = await get("/models", null, { beta: true })
  const {
    status,
    data={}
  } = response
  if(status === 200) {
    return data
  }
  else {
    return {}
  }
}

export async function createModelRequest(model) {
  const response = await post("/models", model, { beta: true })
  const {
    status,
    data
  } = response
  if (status === 200) return data
  throw response
}

export async function updateModelRequest(modelName, model) {
  const response = await put(`/models/${modelName}`, model, { beta: true })
  const {
    status,
    data
  } = response
  if (status === 200) return data
  throw response
}

export async function deleteModelRequest(id) {
  const response = await del(`/models/${id}`, null, { beta: true })
  //TODO: This is temporarily commented out until api response changes
  // if (response.status !== 204) throw response // delete model requests return null when successful, and an error object on failure
  if (response) throw response //TODO: replace this line with the one above when api response changes
  return response
}

export async function getModelVersionRequest(model, version) {
  const { data } = await get(`/models/${model}/versions/${version}`, null, { beta: true })
  return data
}

export async function getModelVersionsRequest(model) {
  const response = await get(`/models/${model}/versions`, null, { beta: true })
  const {
    status,
    data={}
  } = response
  if(status === 200) {
    return data
  }
  else {
    return {}
  }
}

export async function createModelVersionRequest(model, template) {
  const response = await post(`/models/${model}/versions`, template, { beta: true })
  const {
    status,
    data
  } = response
  if (status === 200) return data
  throw response
}

export async function updateModelVersionRequest(model, version, template) {
  const response = await put(`/models/${model}/versions/${version}`, template, { beta: true })
  const {
    status,
    data
  } = response
  if (status === 200) return data
  throw response
}

export async function deleteModelVersionRequest(model, version) {
  const response = await del(`/models/${model}/versions/${version}`, null, { beta: true })
  
  //TODO: This is temporarily commented out until api response changes
  // if (response.status !== 204) throw response // delete model requests return null when successful, and an error object on failure
  if (response) throw response //TODO: replace this line with the one above when api response changes
  return response
}


// CONSTANTS

export const INVALID_MODEL_FIELDS = [
  "space",
  "category",
  "categories",
  "model",
  "href",
  "id",
  "uid",
  "credentials",
  "labels",
  "base",
  "created",
  "modified"
]

export const EMPTY_VERSION_SCHEMA = {
  title: "",
  description: "",
  template: {},
}

// FUNCTIONS

export function getLatestModelVersionTemplate(state) {
  const { modelVersions, modelVersionsLib } = state
  const latestModelVersion = getVersionToNavigate(modelVersions)
  return modelVersionsLib[latestModelVersion] || EMPTY_VERSION_SCHEMA
}

export function getVersionToNavigate(models, queryVersion) {
  if (queryVersion) {
    const queryVersionExists = models.some(({ version }) => version == queryVersion)
    if (queryVersionExists) return queryVersion
  }
  
  return Math.max(...models.map(({ version }) => version), 0)
}
