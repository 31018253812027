import moment from "moment"

import { del, get, patch, post, put } from "utils/api"
import {
  addMessage,
  MESSAGE_TYPE_ERROR
} from "utils/notifications"
import { getTableFilters } from "utils/storage"

// REQUESTS

export async function getFilesRequest(params) {
  const { data } = await get("/objects", params)
  return data
}

export async function getFoldersRequest(params) {
  const { data } = await get("/objects-directories", params)
  return data
}

export async function addFileRequest(file, onUploadProgress) {
  const { data } = await post("/objects", file, {
    onUploadProgress: (progressEvent) => {
      if (progressEvent.total !== undefined) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        onUploadProgress(percentCompleted)
      }
    }
  })
  return data
}

export async function addMultipartFileRequest(file, onUploadProgress) {
  const { data } = await post("/objects", file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      if (progressEvent.total !== undefined) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        onUploadProgress(percentCompleted)
      }
    }
  })
  return data
}

export async function addFolderRequest(dummyFile) { // We need to upload a dummy (hidden) file with an specific path in the body (metadata.path) to create that path
  const { data } = await post("/objects", dummyFile)
  return data
}

export async function updateTtlRequest(id, ttl) {
  const { data } = await patch(`/objects/${id}`, ttl)
  return data
}

export async function updateFolderRequest(path) { //check if objects-directories endpoint will accept post and put with the dummy file to the choosen path
  const { data } = await put("/objects-directories", path)
  return data
}

export async function deleteFilesRequest(ids) {
  const { data } = await del("/objects", ids)
  return data
}

export async function deleteFoldersRequest(path) {
  return await del("/objects", path)
}

export function isFolder(record) {
  return !record.name
}

export function filterDummyFiles(files) {
  return files.filter(f => f.name !== "dummy.dmy")
}

export async function getFileLinkRequest(fileID, link) {
  const { data } = await get(`/objects/${fileID}/download`, link)
  return data
}

export async function downloadFile (fileLink, fileName) {
  try {
    const response = await fetch(fileLink)
    const blob = await response.blob()
    const blobUrl = URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.href = blobUrl
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  catch(error) {
    console.error(`${error.name}: ${error.message}`)
    addMessage({
      text: "File could not be downloaded",
      subtext: error.message,
      type: MESSAGE_TYPE_ERROR
    })
  }
}

export const getFilterQueryParams = (tableName, columns, username) => {
  const columnFilters = getTableFilters(tableName, username) || {}
  const filterQueryParams = getColumnFilterQuery(columnFilters, columns)
  return filterQueryParams
}

export function getColumnFilterQuery(columnFilters={}) {
  let filters = {}
  for(let colKey in columnFilters) {
    const {[colKey]: colFilters=[]} = columnFilters

    //skip if column has no filters
    if (colFilters.length === 0) continue

    if (colKey === "name") {
      if (colFilters[0]?.value) {
        filters[colKey] = colFilters[0].value
      }
    } else if (colKey === "content_type") {
      if (colFilters[0]?.value) {
        if (!colFilters[0].value.startsWith(".")) {
          // If the string doesn't start with a dot, prepend it
          filters[colKey] = "." + colFilters[0].value
        } else filters[colKey] = colFilters[0].value
      }
    } else if (colKey === "created_at") {
      if (colFilters[0]?.value) {
        //Allow various filter at the same time
        filters[colKey] = colFilters.map(f => {
          let filterValue = moment(f.value).format("YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]")
          //We check if the user inputs exactly the date with the API format to avoid the zerofill moment format in the last 3 milisecs
          if(f.value.length === 27) {
            filterValue = f.value
          }
          switch (f.operator) {
          case "bt":
            return "gt:" + filterValue
          case "st":
            return "lt:" + filterValue
          case "bte":
            return "gte:" + filterValue
          case "ste":
            return "lte:" + filterValue
          default:
            return "eq:" + filterValue
          }
        })
      }
    }
  }
  return filters
}

export const hasColumnFilters = (tableName, username) => {
  const filters = getTableFilters(tableName, username)
  return Object.keys(filters).some(f => !!filters[f].length)
}

export const expirationTimeUnitOptions = [
  {id: "hours", label: "hours"},
  {id: "days", label: "days"},
  {id: "months", label: "months"},
]

export async function monthsDaysToHours(expirationTime, expirationTimeUnit) {
  let hours = String(expirationTime)
  if(expirationTimeUnit === "months"){
    hours = String(expirationTime * 720)
  } else if (expirationTimeUnit === "days"){
    hours = String(expirationTime * 24)
  }
  return hours + "h00m00s"
}

export const getSortQuery = sort => {
  const { direction, column } = sort
  let query
  switch (direction) {
  case "Ascending":
    query = `+${column}`
    break
  case "Descending":
    query = `-${column}`
    break
  default:
    break
  }
  return query? { sort: query } : {}
}
export async function updateLinkTtlRequest(id, ttl) {
  const { data } = await post(`/objects/${id}/links`, ttl)

  return data
}