import { Typography } from "antd"
import React from "react"

import {colors} from "assets/stylesheets/common"

const UnityProgress = ({ progress }) => {
  return (
    <div id="progress-bar" style={styles.container}>
      <div style={styles.progressBarContainer}>
        <div style={styles.progressBar}>
          <div style={{ ...styles.progress, width: `${progress}%` }}></div>
        </div>
        <Typography style={styles.percent}>{progress}%</Typography>
      </div>
    </div>
  )
}

const styles = {
  container: {
    padding: "20px",
    backgroundColor: "#fff",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    textAlign: "left",
  },
  title: {
    marginBottom: "10px",
    color: colors.text1,
  },
  progressBarContainer: {
    display: "flex",
    alignItems: "center",
  },
  progressBar: {
    flex: 1,
    height: "10px",
    backgroundColor: "#ccc",
    borderRadius: "10px",
    overflow: "hidden",
    marginRight: "10px",
  },
  progress: {
    height: "100%",
    backgroundColor: colors.skyBlue,
  },
  percent: {
    marginLeft: "auto",
  },
}

export default UnityProgress
