import { AUTH_HYDRA_CLIENT, PANO_STREAMS_URL, PANO_VIZ_URL, PROXY_URL } from "constants/config"
import { getSpace } from "utils/storage"

import { get } from "./api"


export const SCOPES_LIST = ["app", "edge-app", "category", "cluster", "edge-distribution", "edge-provisioning", "edge-rollout", "function", "invitation", "label", "model", "mqtt", "object", "policy", "query", "resource", "role", "space", "thing", "trigger", "usage", "user", "secret", "variable"]

const CLIENT_ID = AUTH_HYDRA_CLIENT

const MOCK = process.env.REACT_APP_ENVIRONMENT === "development"

export const BASE_URL = PROXY_URL + "/oauth2"
const DATA_VIZ_URL = PANO_VIZ_URL
const STREAMS_URL = PANO_STREAMS_URL

export function generateRandomString() {
  return Math.random().toString(36).slice(2)
}

export function getSWTokenURL() {
  return `${BASE_URL}/token`
}

export async function revokeTokenRequest(token, clientId=CLIENT_ID, clientSecret) {
  if (MOCK) return

  const body = {
    token: token,
    client_id: clientId
  }

  if (clientSecret) {
    body.client_secret = clientSecret
  }

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded"
  }

  try {
    const resp = await fetch(BASE_URL + "/revoke",
      { method: "POST",
        headers: headers,
        body: new URLSearchParams(body).toString()
      }
    )
    return resp
  }
  catch (error) {
    console.error(error)
  }
}

export async function requestClientCredentialsToken(clientId, clientSecret, scope=[]) {
  if (MOCK) return

  try {
    const tokenUrl = getSWTokenURL()
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    }
    const body = {
      grant_type: "client_credentials",
      client_id: clientId,
      client_secret: clientSecret,
      scope: scope.join(" ")
    }

    // send access_code to the auth service
    const resp = await fetch(tokenUrl, {
      method: "POST",
      headers,
      body: new URLSearchParams(body).toString()
    })

    const json = resp.json()
    return json
  } catch (err) {
    console.error(err)
  }
}

export function getSpaceId() {
  return getSpace()
}

//Create temporary iframe to call pano's logout endpoint to clear session cookies
export function panoDesignerLogout() {
  const logout_uri = DATA_VIZ_URL + "/panopticon/server/rest/auth/logout"
  return iframeEndpoint(logout_uri)
}

export function panoStreamsLogout() {
  const logout_uri = STREAMS_URL + "/streams/server/rest/auth/logout"
  return iframeEndpoint(logout_uri)
}

//Allows Pano logout endpoints to be called when logging out of Studio
//Returns Promise
function iframeEndpoint(uri) {
  return new Promise((resolve, reject) => {
    try {
      const {
        document,
        document: {
          body={}
        }={}
      } = window
      const iframe = document.createElement("iframe")

      iframe.src = uri
      iframe.style="display:none;"
      iframe.onload=(resp)=>{
        body.removeChild(iframe)
        resolve(resp)
      }

      body.appendChild(iframe)
    } catch(err) {
      reject(err)
    }
  })
}

export function getUserInfoRequest() {
  return get("/user-info", null, { rootRequest: true })
}

export function getCheckAuthenticateRequest() {
  return get("/check-authenticate", null, { rootRequest: true })
}

export function getAltairOneStatusRequest() {
  return get("/boundaries/altairone/status", null, { rootRequest: true })
}

export function getUsernameAltairOneStatusRequest(username) {
  return get(`boundaries/username/${username}/altairone/status`, null, { rootRequest: true})
}

export async function getUsersAltairOneStatusRequest(usersID) {
  const usersQueryParams= usersID.map((id) => `username[]=${encodeURIComponent(id)}`).join("&")
  const { data } = await get("boundaries/users/altairone/status?"+usersQueryParams, null, { rootRequest: true})
  return data
}
export function unlinkAltairOneRequest() {
  return get("/boundaries/altairone/unlink", null, { rootRequest: true })
}