import moment from "moment"

import { del, get, post } from "utils/api"

import { EQUAL_OPERATOR } from "./tableColumns"

export async function getDistributionsRequest(params) {
  const response = await get("/edge-distributions", params)
  const { status, data } = response
  if (status === 200) return data
  throw new Error(`Request failed with status ${response.status} - ${response.data}`)
}

export async function getDistributionVersionsRequest(distName) {
  const response = await get(`/edge-distributions/${distName}`)
  const { status, data } = response
  if (status === 200) return data
  throw new Error(`Request failed with status ${response.status} - ${response.data}`)
}

export async function getDistributionRequest(distName, distVersion, distType) {
  const response = await get(`/edge-distributions/${distName}/versions/${distVersion}/types/${distType}`)
  const { status, data } = response
  if (status === 200) return data
  throw new Error(`Request failed with status ${response.status} - ${response.data}`)
}

// In order to update a distribution we will also use the createDistributionRequest with the same name.
export async function createDistributionRequest(distributionData) {
  const response = await post("/edge-distributions", distributionData)
  const { status, data } = response
  if (status !== 200) throw new Error(`Request failed with status ${response.status} - ${response.data}`)
  return data
}

export async function deleteDistributionRequest(id) {
  const response = await del(`/edge-distributions/${id.name}/versions/${id.version}/types/${id.type}`)
  return response
}

export async function deleteDistributionsRequest(dist) {
  const response = await del(`/edge-distributions/${dist.name}/types/${dist.type}`)
  return response
}


export const initialColumns = [
  {
    key: "name",
    label: "Name",
    showFilter: true,
    singleFilter: true,
    operators: [EQUAL_OPERATOR]
  },
  {
    key: "description",
    label: "Description"
  },
  {
    key: "type",
    label: "Type",
    formatLabel: (colValue) => colValue === "ota_edge_apps" ? "OTA Edge Apps" :
      colValue === "ota_system" ? "OTA System" : ""
  },
  {
    key: "latest_version",
    label: "Latest version"
  },
  {
    key: "created",
    label: "Created",
    formatLabel: (colValue) => colValue ? moment.utc(colValue).fromNow() : ""
  }
]
