import {ROLE_ADMIN, ROLE_SUPERADMIN, ROLE_USER} from "constants/admin"
import {get, patch} from "utils/api"

import {getTableFilters} from "./storage"

export const TABLE_USERS_LIST_NAME = "platform-users-list"

// REQUESTS

export async function getUsersRequest(params) {
  const { data } = await get("/admin/users", params, { rootRequest: true })
  return data
}

export async function updateUserRoleRequest(id, role) {
  const { data } = await patch(`/admin/users/${id}`, role, { rootRequest: true })
  return data
}

export async function getSpacesRequest(params) {
  const { data } = await get("/admin/spaces", params, { rootRequest: true })
  return data
}

export const getFilterQueryParams = (tableName, columns, username) => {
  const columnFilters = getTableFilters(tableName, username) || {}

  if (tableName === TABLE_USERS_LIST_NAME){
    return getUsersColumnFilterQuery(columnFilters, columns)
  }

  return getSpacesColumnFilterQuery(columnFilters, columns)
}

export function getUsersColumnFilterQuery(columnFilters={}) {
  let filters = {}
  for(let colKey in columnFilters) {
    const {[colKey]: colFilters=[]} = columnFilters
    //skip if column has no filters
    if (colFilters.length === 0) continue

    if (colKey === "id" || colKey === "email" || colKey === "role" ) {
      if (colFilters[0]?.value) {
        filters[colKey] = colFilters[0].value
      }
    } else if (colKey === "created_at") {
      filters["at"] = colFilters[0].value
    }
  }
  return filters
}

export function getSpacesColumnFilterQuery(columnFilters={}) {
  let filters = {}
  for(let colKey in columnFilters) {
    const {[colKey]: colFilters=[]} = columnFilters
    //skip if column has no filters
    if (colFilters.length === 0) continue

    if (colKey === "id" || colKey === "owner" ) {
      if (colFilters[0]?.value) {
        filters[colKey] = colFilters[0].value
      }
    } else if (colKey === "created_at") {
      filters["at"] = colFilters[0].value
    }else if (colKey === "allow_admin_access") {
      let filterValue = colFilters[0].value.toLowerCase()
      if (filterValue === "denied") {
        filterValue = false
      } else if (filterValue === "allowed") {
        filterValue = true
      }
      filters["allow_admin_access"] = filterValue
    }
  }
  return filters
}

export const hasColumnFilters = (tableName, username) => {
  const filters = getTableFilters(tableName, username)
  return Object.keys(filters).some(f => !!filters[f].length)
}
export const getSortQuery = sort => {
  const { direction, column } = sort
  let query
  switch (direction) {
  case "Ascending":
    query = `+${column}`
    break
  case "Descending":
    query = `-${column}`
    break
  default:
    break
  }
  return query? { sort: query } : {}
}

export const roleOptions = [
  {id: ROLE_SUPERADMIN, label: ROLE_SUPERADMIN},
  {id: ROLE_ADMIN, label: ROLE_ADMIN},
  {id: ROLE_USER, label: ROLE_USER},
]


export function isSuperadmin(swxRole) {
  return swxRole === ROLE_SUPERADMIN
}

export function isAdmin(swxRole) {
  return swxRole === ROLE_ADMIN
}
