import { del, get, post, put } from "utils/api"
import { formatUriParams } from "utils/routes"

import { getTagStyles } from "./misc"


export const ENTITIES = "entities"


/** LABELS */

export async function getLabelsRequest(params) {
  const response = await get("/labels", params)
  const {
    status,
    data={}
  } = response
  if(status === 200) {
    return data
  }
  else {
    return {}
  }
}

export async function createLabelRequest(label) {
  const response = await post("/labels", label)
  const {
    status,
    data
  } = response
  if(status === 200) {
    return data
  }
}

export async function updateLabelRequest(id, label) {
  const response = await put(`/labels/${id}`, label)
  const {
    status,
    data
  } = response
  if (status === 200) return data
  throw new Error(`Request failed with status ${response.status} - ${response.data}`)
}

export async function deleteLabelRequest(id) {
  const response = await del(`/labels/${id}`)
  //TODO: This is temporarily commented out until api response changes
  // if (response.status !== 204) throw response // delete app requests return null when successful, and an error object on failure
  if (response) throw response //TODO: replace this line with the one above when api response changes
  return response
}


/** LABELED ENTITIES */

export async function getLabeledEntitiesRequest(query) {
  const response = await get("/labeled-entities" + query)
  const {
    status,
    data={},
  } = response
  if(status === 200) {
    return data
  }
  else {
    return {}
  }
}


/** LABEL RELATIONS */

export async function getLabelRelationsRequest(id, params) {
  const response = await get(`/labels/${id}/relations`, params)
  const {
    status,
    data={}
  } = response
  if(status === 200) {
    return data
  }
  else {
    return {}
  }
}

export async function createLabelRelationRequest(id, relation) {
  const response = await post(`/labels/${id}/relations`, relation)
  const {
    status,
    data={}
  } = response
  if(status === 200) return data
}

export async function deleteLabelRelationRequest(labelId, entityId, entityType, entityName) {
  const queryParams = {}
  if (entityType) {
    queryParams.entity_type = entityType
  }
  if (entityId) {
    queryParams.entity_id = entityId
  }
  if (entityName) {
    queryParams.entity_name = entityName
  }
  //TODO: format url query params
  const url = `/labels/${labelId}/relations${formatUriParams(queryParams)}`
  const response = await del(url)
  // if (response.status !== 204) throw response // delete user requests return null when successful, and an error object on failure
  if (response) throw response //TODO: replace this line with the one above when api response changes
  return response
}

export function buildLabeledEntitiesQuery(ids, entityType, filterType, categoryName) {
  let queryString = `?entity_type=${entityType}`
  if (categoryName) queryString += `&category_name=${categoryName}`
  ids.forEach(id => queryString += `&${filterType}=${id}`)
  return queryString
}


// Table

export function getLabelsContainer(event) {
  const path = event.path || (event.composedPath && event.composedPath())
  return path.find(p => p.className === "labels-container")
}

export function hasAddLabel(container) {
  return container?.firstChild?.id.includes("add-label-dropdown")
}

export const getPopoverOffset = ({ placement, reference }) => {
  if (placement === "bottom-start" || placement === "top-start") {
    return [0, -reference.height]
  } else {
    return []
  }
}

export const getLabelTags = (labels) => {
  return labels.map(({ color, id, label_name }) => {
    let out = { value: id, label: label_name }
    if (color) out.styles = getTagStyles(color)
    return out
  })
}
