/* === IMPORTS === */
import { createReducer } from "@reduxjs/toolkit"

import { CLEAN_SPACE } from "actions/auth"
import {
  buildLabeledEntitiesQuery,
  createLabelRelationRequest,
  createLabelRequest,
  deleteLabelRelationRequest,
  deleteLabelRequest,
  getLabeledEntitiesRequest,
  getLabelRelationsRequest,
  getLabelsRequest,
  updateLabelRequest }
  from "utils/labels"
import { createBatches, makeLib } from "utils/misc"
import {
  addMessage,
  GLOBAL_NOTIFICATIONS,
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS
} from "utils/notifications"


import { makeActions } from "./utiliducks"

/* == ACTIONS === */
const actionList = [
  "setLabelsAction",
  "addLabelAction",
  "updateLabelAction",
  "removeLabelsAction",

  "setLabeledEntitiesAction",
  "addLabeledEntityAction",
  "removeLabeledEntityAction",

  "setLabelRelationsAction",
  "addLabelRelationAction",
  "removeLabelRelationAction",

  "setPagingAction",
  "setLabelRelationsPagingAction",
]
const {
  setLabelsAction,
  addLabelAction,
  updateLabelAction,
  removeLabelsAction,

  setLabeledEntitiesAction,
  addLabeledEntityAction,
  removeLabeledEntityAction,

  setLabelRelationsAction,
  // These two actions are currently unused, because we are displaying relations using labeledEntities. They would be needed only
  // if we add a feature to add/remove relations from the Entities pane in Label Details.
  addLabelRelationAction,
  removeLabelRelationAction,

  setPagingAction,
  setLabelRelationsPagingAction,
} = makeActions("labels", actionList)

/* === INITIAL STATE === */
const initialState = {
  labels: [],
  labelsLib: {},
  labelRelations: {},
  labeledEntities: {
    anything: [],
    edge_app: [],
    function: [],
    resource: [],
    thing: []
  },
  labeledEntitiesLib: {
    anything: {},
    edge_app: {},
    function: {},
    resource: {},
    thing: {}
  },
  paging: { previous_cursor: "", next_cursor: "" },
  labelRelationsPaging: { previous_cursor: "", next_cursor: "" }
}

/* === Reducer === */
export default createReducer(initialState, {
  [setLabelsAction]: (state, { payload: { labels }}={}) => ({
    ...state,
    labels,
    labelsLib: makeLib({data: labels}),
  }),
  [addLabelAction]: (state, { payload: { label }}={}) => ({
    ...state,
    labels: [
      ...state.labels,
      label
    ],
    labelsLib: {
      ...state.labelsLib,
      [label.id]: label
    }
  }),
  [updateLabelAction]: (state, { payload: { id, label: updatedLabel }}) => ({
    ...state,
    labels: state.labels.map(label => label.id === id? updatedLabel : label),
    labelsLib: {
      ...state.labelsLib,
      [id]: {
        ...state.labelsLib[id], ...updatedLabel
      }
    }
  }),
  [removeLabelsAction]: (state, { payload: { ids }}) => {
    const labels = state.labels.filter(label => !ids.includes(label.id))
    return {
      ...state,
      labels,
      labelsLib: makeLib({data: labels})
    }
  },

  [setLabeledEntitiesAction]: (state, { payload: { type, labeledEntities } }) => ({
    ...state,
    labeledEntities: {
      ...state.labeledEntities,
      [type]: labeledEntities
    },
    labeledEntitiesLib: {
      ...state.labeledEntitiesLib,
      [type]: makeLib({data: labeledEntities, key: "entity_id"})
    }
  }),
  [addLabeledEntityAction]: (state, { payload: { type, label, entityId } }) => {
    let newLabeledEntities
    const { labeledEntities, labeledEntitiesLib } = state
    const currentTypeEntities = labeledEntities[type] || []
    if(!labeledEntitiesLib[type][entityId]) {
      // create new labeled entity if it doesn't exist
      newLabeledEntities = [...currentTypeEntities, { entity_id: entityId, labels: [label]}]
    }
    else {
      // add label to the existing labeled entity
      newLabeledEntities = currentTypeEntities.map(labeledEntity => {
        return labeledEntity.entity_id === entityId ?
          {...labeledEntity, labels: [...labeledEntity.labels, label]}
          : labeledEntity
      })
    }
    return {
      ...state,
      labeledEntities: {
        ...labeledEntities,
        [type]: newLabeledEntities
      },
      labeledEntitiesLib: {
        ...labeledEntitiesLib,
        [type]: makeLib({data: newLabeledEntities, key: "entity_id"})
      }
    }
  },
  [removeLabeledEntityAction]: (state, { payload: { type, labelId, entityId, entityName } }) => {
    const newLabeledEntities = state.labeledEntities[type].map(labeledEntity => {
      return ((entityId && labeledEntity.entity_id === entityId) || (entityName && labeledEntity.entity_name === entityName)) ?
        {...labeledEntity, labels: labeledEntity.labels.filter(label => label.id !== labelId)}
        : labeledEntity
    })
    return {
      ...state,
      labeledEntities: {
        ...state.labeledEntities,
        [type]: newLabeledEntities
      },
      labeledEntitiesLib: {
        ...state.labeledEntitiesLib,
        [type]: makeLib({data: newLabeledEntities, key: "entity_id"})
      }
    }
  },
  [setLabelRelationsAction]: (state, { payload: { id, relations }}={}) => ({
    ...state,
    labelRelations: {
      ...state.labelRelations,
      [id]: relations
    }
  }),
  [addLabelRelationAction]: (state, { payload: { id, relation }}={}) => ({
    ...state,
    labelRelations: {
      ...state.labelRelations,
      [id]: [
        ...state.labelRelations[id],
        relation
      ]
    }
  }),
  [removeLabelRelationAction]: (state, { payload: { id, entityId, entityType }}={}) => ({
    ...state,
    labelRelations: {
      ...state.labelRelations,
      [id]: state.labelRelations[id].filter(relation => relation.entity_id !== entityId || relation.entityType !== entityType)
      // although extremely rare, there might be two entities of different type with the same ID. That's why the type comparison is needed.
    }
  }),

  [setPagingAction]: (state, { payload: { paging } }) => ({
    ...state,
    paging
  }),
  [setLabelRelationsPagingAction]: (state, { payload: { paging } }) => ({
    ...state,
    labelRelationsPaging: paging
  }),
  [CLEAN_SPACE]: () => initialState
})

/* === DISPATCHERS === */
export const getLabels = (params, store=true) => {
  return async (dispatch) => {
    try {
      const response = await getLabelsRequest(params)
      const { data: labels, paging } = response
      if(store) {
        dispatch(setLabelsAction({ labels }))
        if (paging) dispatch(setPaging({ paging }))
      }
      return labels
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "User labels could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const getNextLabels = () => {
  return async (dispatch, getState) => {
    const {
      labels: {
        labels,
        paging: oldPaging
      }
    } = getState()
    const { data, paging } = await getLabelsRequest({next_cursor: oldPaging.next_cursor})
    dispatch(setPaging({paging}))
    dispatch(setLabelsAction({ labels: [...labels, ...data]}))
  }
}

export const createLabel = (labelData) => {
  return async (dispatch) => {
    try {
      const label = await createLabelRequest(labelData)
      dispatch(addLabelAction({ label }))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Label created successfully",
        type: MESSAGE_TYPE_SUCCESS
      })
      return label.id

    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Label could not be created",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
      return false
    }
  }
}

export const addLabelToStore = (label) => {
  return async (dispatch) => {
    try {
      dispatch(addLabelAction({ label }))
      return true
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Label could not be created",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
      return false
    }
  }
}

export const updateLabel = (id, label) => {
  return async dispatch => {
    try {
      const updatedLabel = await updateLabelRequest(id, label)
      dispatch(updateLabelAction({id: updatedLabel.id, label: updatedLabel}))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Label updated successfully",
        type: MESSAGE_TYPE_SUCCESS
      })
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Label could not be updated",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const updateLabelInStore = (label) => {
  return async dispatch => {
    try {
      return dispatch(updateLabelAction({id: label.id, label}))
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Label could not be updated",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}



export const deleteLabels = (ids) => {
  return async dispatch => {
    try {
      // TODO: we might need to pace request as we are doing with Things
      const responses = ids.map(async id => {
        return deleteLabelRequest(id)
      })
      await Promise.all(responses)
      dispatch(removeLabelsAction({ids}))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: `${ids.length === 1 ? "Label": "Labels"} deleted successfully`,
        type: MESSAGE_TYPE_SUCCESS
      })
    }
    catch(error) {
      console.error({[error.name]: { status: error.status, error: error.error, message: error.message }})
      let text, subtext
      if(error.message === "EntityRelatedError") {
        text = "Cannot delete labels that are still in use"
        subtext = "Please unlabel any associated entity before deleting a label"
      }
      else {
        text = `${ids.length===1? "Label": "Some labels"} could not be deleted`
        subtext = error.message
      }
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text,
        subtext,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const getLabelRelations = id => {
  return async dispatch => {
    try {
      const response = await getLabelRelationsRequest(id)
      const { data, paging } = response
      dispatch(setLabelRelationsAction({id, relations: data}))
      if(paging) dispatch(setLabelRelationsPaging({paging}))
    }
    catch (error) {
      if(error.message !== "NoEntityRelatedError") {
        console.error({[error.name]: { status: error.status, error: error.error, message: error.message }})
        addMessage({
          target: GLOBAL_NOTIFICATIONS,
          text: "Could not retrieve linked entities",
          subtext: error.message,
          type: MESSAGE_TYPE_ERROR,
          timeout: 4000
        })
      }
    }
  }
}

export const getNextLabelRelations = id => {
  return async (dispatch, getState) => {
    try {
      const {
        labels: {
          labelRelations,
          labelRelationsPaging: oldPaging
        }
      } = getState()
      const { data, paging } = await getLabelRelationsRequest(id, {next_cursor: oldPaging.next_cursor})
      dispatch(setLabelRelationsAction({ id, relations: [...labelRelations, ...data]}))
      dispatch(setLabelRelationsPaging({paging}))
    }
    catch (error) {
      console.error({[error.name]: { status: error.status, error: error.error, message: error.message }})
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Could not retrieve linked entities",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR,
        timeout: 4000
      })
    }
  }
}

export const createLabelRelation = (labelId, entityId, entityType) => {
  return async (dispatch, getState) => {
    try {
      const {
        labels: {
          labelsLib: {
            [labelId]: label
          }
        }
      } = getState()
      const relation = await createLabelRelationRequest(labelId, {entity_id: entityId, entity_type: entityType})
      dispatch(addLabeledEntity(label, relation.entity_id, entityType))
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Could not assign label to entity",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const addLabeledEntity = (label, entityId, entityType) => {
  return dispatch => dispatch(addLabeledEntityAction({type: entityType, label, entityId}))
}

export const deleteLabelRelation = (labelId, entityId, entityType, entityName) => {
  return async dispatch => {
    try {
      await deleteLabelRelationRequest(labelId, entityId, entityType, entityName)

      dispatch(removeLabeledEntityAction({type: entityType, entityId, entityName, labelId}))
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Could not remove label from entity",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }

  }
}

export const getLabeledEntities = (ids, entityType, options={}) => {
  return async dispatch => {
    try {
      const {
        filterType="entity_id",
        collectionName="",
        categoryName=""
      } = options
      const batches = createBatches(ids)
      let data = []
      for (let i = 0; i < batches.length; i++) {
        const ids = batches[i]
        const queryString = buildLabeledEntitiesQuery(ids, entityType, filterType, collectionName || categoryName)
        const { data: batchData=[] } = await getLabeledEntitiesRequest(queryString)
        if (batchData) data.push(...batchData)
      }
      // NOTE: for now, since we send the request using the items IDs for filtering, we do not make use of pagination.
      // But we have to make sure that the limit param for the entities request is equal to that of the getLabeledEntities request.
      // Otherwise, retrieved data will be paginated and we will miss that information.
      dispatch(setLabeledEntitiesAction({type: entityType, labeledEntities: data || []}))
      return data || []
    }
    catch (error) {
      if(error.message !== "NoEntityRelatedError") {
        console.error({[error.name]: { status: error.status, error: error.error, message: error.message }})
        addMessage({
          target: GLOBAL_NOTIFICATIONS,
          text: "Labels could not be retrieved",
          subtext: error.message,
          type: MESSAGE_TYPE_ERROR
        })
      }
    }
  }
}

export const getNextLabeledEntities = (ids, entityType, options={}) => {
  return async (dispatch, getState) => {
    const {
      labels: {
        labeledEntities: {
          [entityType]: entities=[]
        },
      }
    } = getState()
    try {
      const {
        filterType="entity_id",
        collectionName=""
      } = options
      const batches = createBatches(ids)
      let data = []
      for (let i = 0; i < batches.length; i++) {
        const ids = batches[i]
        const queryString = buildLabeledEntitiesQuery(ids, entityType, filterType, collectionName)
        const { data: batchData=[] } = await getLabeledEntitiesRequest(queryString)
        if (batchData) data.push(...batchData)
      }
      dispatch(setLabeledEntitiesAction({type: entityType, labeledEntities: [...entities, ...data]}))
    }
    catch (error) {
      if(error.message !== "NoEntityRelatedError") {
        console.error({[error.name]: { status: error.status, error: error.error, message: error.message }})
        addMessage({
          target: GLOBAL_NOTIFICATIONS,
          text: "Labels could not be retrieved",
          subtext: error.message,
          type: MESSAGE_TYPE_ERROR,
        })
      }
    }
  }
}


export const setPaging = ( {paging} ) => {
  return dispatch => dispatch(setPagingAction({paging}))
}

export const setLabelRelationsPaging = ( {paging} ) => {
  return dispatch => dispatch(setLabelRelationsPagingAction({paging}))
}

/* === UTILS === */
