import { Typography } from "antd"
import React from "react"

function UnityLink({ children, ...props}) {
  return (
    <Typography.Link {...props}>
      {children}
    </Typography.Link>
  )
}

export default UnityLink
