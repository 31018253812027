import {
  UnityTypography,
  UnityUtilityBelt
} from "@bit/smartworks.unity-react.unity-core-react"
import React, { Component } from "react"
import { connect } from "react-redux"

import FunctionLogs from "components/Functions/FunctionLogs"
import FunctionLogsDateSearch from "components/Functions/FunctionLogsDateSearch"
import FunctionLogsSearch from "components/Functions/FunctionLogsSearch"
import FunctionLogsSelection from "components/Functions/FunctionLogsSelection"
import HttpInspector from "components/HttpInspector/HttpInspector"
import MqttInspector from "components/MqttInspector/MqttInspector"
import MqttInspectorSubscriptions from "components/MqttInspector/MqttInspectorSubscriptions"
import MqttInspectorTopicSelection from "components/MqttInspector/MqttInspectorTopicSelection"
import { FUNCTIONS, HTTP_INSPECTOR, MQTT_INSPECTOR } from "constants/routes"
import {
  ADD_TAB_TO_BELT,
  CLOSE_TAB_FROM_BELT,
  UPDATE_TAB_IN_BELT
} from "utils/utilityBelt"

import UtilityFooterAdd from "./UtilityFooterAdd"



const stateToProps = () => ({
})

//TODO: refactor as functional component
class UtilityBelt extends Component {
  state = {
    tabs: []
  }

  componentDidMount() {
    document.addEventListener(ADD_TAB_TO_BELT, this.handleAddTab)
    document.addEventListener(CLOSE_TAB_FROM_BELT, this.handleTabCloseFromEvent)
    document.addEventListener(UPDATE_TAB_IN_BELT, this.handleUpdateTab)
  }

  componentWillUnmount() {
    document.removeEventListener(ADD_TAB_TO_BELT, this.handleAddTab)
    document.removeEventListener(CLOSE_TAB_FROM_BELT, this.handleTabCloseFromEvent)
    document.removeEventListener(UPDATE_TAB_IN_BELT, this.handleUpdateTab)
  }

  handleTabCloseFromEvent = ({detail: tabId}) => this.handleTabClose(tabId)

  handleAddTab = ({detail: newTab={}}) => {
    const {tabs=[]} = this.state
    const tabIndex = tabs.findIndex(tab => tab.id === newTab.id && tab.type === newTab.type)
    const newTabs = [...tabs]
    
    if (tabIndex === -1) {
      newTabs.push(newTab)
    } else {
      newTabs[tabIndex] = {...newTabs[tabIndex], ...newTab}
    }

    this.setState({
      tabs: newTabs,
      selectedTab: newTab.id
    })
  }

  handleUpdateTab = ({detail: {type, id, prevId, name, options}={}}) => {
    const {tabs=[]} = this.state
    //Check if new tab already exists, if so, select and close current tab
    // otherwise, update current tab and keep selected
    if (type !== HTTP_INSPECTOR && tabs.some(({id: tabId}) => tabId === id )) {
      this.setState({
        selectedTab: id,
        tabs: tabs.filter(({id: tabId}) => tabId !== prevId)
      })
    } else {
      this.setState({
        selectedTab: id,
        tabs: tabs.map(tab => {
          return tab.id === prevId
            ? {
              ...tab,
              type: type || tab.type,
              id: id || tab.id,
              name,
              options
            }
            : tab
        })
      })
    }
  }

  handleTabClose = (tabId) => {
    const {tabs=[]} = this.state
    const nextTabs = tabs.filter(tab => tab.id !== tabId)

    this.setState({tabs: nextTabs})
  }

  handleTabSelect = (tabId) => {
    this.setState({selectedTab: tabId})
  }

  renderFooterRightActions = () => {
    return <UtilityFooterAdd/>
  }

  generateTabs = () => {
    const {tabs=[]} = this.state

    return tabs.map(({id, type, name, options}) => {
      if (type === FUNCTIONS) {
        return {
          id,
          type,
          name: id,
          renderHeaderLeftContent: (tab) => {
            return <div style={styles.functionLogsContainer}>
              <FunctionLogsSelection functionName={tab.id}/>
              <FunctionLogsDateSearch />
            </div>
          },
          renderHeaderRightContent: (tab) => {
            return <FunctionLogsSearch functionName={tab.id}/>
          },
          renderPane: (tab) => (
            <FunctionLogs functionName={tab.id}/>
          )
        }
      } else if (type === MQTT_INSPECTOR) {
        return {
          id,
          type,
          name: "MQTT Inspector",
          renderHeaderLeftContent: () => {
            return <MqttInspectorTopicSelection/>
          },
          renderHeaderRightContent: () => {
            return <MqttInspectorSubscriptions/>
          },
          renderPane: () => (
            <MqttInspector />
          )
        }
      } else if (type === HTTP_INSPECTOR) {
        return {
          id,
          type,
          name: name || "API Inspector",
          renderHeaderLeftContent: () => {
            return <UnityTypography key="api-inspector-title">API Inspector</UnityTypography>
          },
          renderHeaderRightContent: () => {
            return <div/>
          },
          renderPane: (tab) => (
            <HttpInspector tab={tab} initialValues={options?.initialValues}/>
          )
        }
      }
    })
  }

  render() {
    const {authorized, children} = this.props
    const {selectedTab=""} = this.state
    const tabsProp = this.generateTabs()

    if(!authorized) return children

    return (
      <UnityUtilityBelt
        tabs={tabsProp}
        selectedTab={selectedTab}
        onTabClose={this.handleTabClose}
        onTabSelect={this.handleTabSelect}
        renderFooterRightActions={this.renderFooterRightActions}
        style={styles.belt}
      >
        {children}
      </UnityUtilityBelt>
    )
  }

}

export default connect(stateToProps, {
})(UtilityBelt)

const styles = {
  belt: {
    "--page-header-left-wrapper-overflow": "visible",
    // "--panel-z-index": 0 fix mqtt inspector modal overlapping elements
  },
  functionLogsContainer: {
    display: "flex",
    alignItems: "center"
  }
}
