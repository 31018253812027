/* === IMPORTS === */
import { createReducer } from "@reduxjs/toolkit"

import { makeLib } from "utils/misc"
import {
  addMessage,
  GLOBAL_NOTIFICATIONS,
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS,
  MESSAGE_TYPE_WARNING
} from "utils/notifications"
import { getPoliciesRequest, updatePoliciesRequest } from "utils/policies"
import { createRoleRequest, deleteMultipleRoles, deleteRolesRequest, getRolesRequest, updateRolesRequest } from "utils/roles"

import { makeActions } from "./utiliducks"


/* == ACTIONS === */
const actionList = [
  "setRolesAction",
  "setSubjectsAction",
  "addRoleAction",
  "addSubjectsAction",
  "updateRoleAction",
  "removeRolesAction",
  "removeSubjectsAction",
  "setRolePoliciesAction",
  "setPagingAction",
  "setSubjectsPagingAction",
]
const {
  setRolesAction,
  setSubjectsAction,
  addRoleAction,
  addSubjectsAction,
  updateRoleAction,
  removeRolesAction,
  removeSubjectsAction,
  setRolePoliciesAction,
  setPagingAction,
  setSubjectsPagingAction,
} = makeActions("roles", actionList)

/* === INITIAL STATE === */
const initialState = {
  rolesList: [],
  roles: [],
  rolesLib: {},
  rolePolicies: [],
  subjects: [],
  subjectsPaging: { previous_cursor: "", next_cursor: "" }
}

/* === Reducer === */
export default createReducer(initialState, {
  [setRolesAction]: (state, { payload: { roles }}={}) => {
    let transformedRoles = []

    for(const r of roles) {
      const { role, domain, ...subjectObject} = r
      const existingRole = transformedRoles.find(r => r.role === role)

      if(existingRole) existingRole.subjects = [...existingRole.subjects, subjectObject]
      else transformedRoles.push({ id:role, role, domain, subjects: [subjectObject]})
    }

    return {
      ...state,
      roles: transformedRoles,
      rolesLib: makeLib({data: transformedRoles, key: "role"}),
    }
  },
  [setSubjectsAction]: (state, { payload: { subjects } }) => {
    state.subjects = subjects
  },
  [addRoleAction]: (state, { payload: { role }}) => {
    return {
      ...state,
      roles: [...state.roles, role],
      rolesLib: { ...state.rolesLib, [role.id]: role},
    }
  },
  [addSubjectsAction]:  (state, { payload: { role, subjects }}) => {
    return ({
      ...state,
      roles: state.roles.map( r => {
        const { role: roleName } = r
        if(roleName === role) {
          return {
            ...r,
            subjects: [
              ...r.subjects,
              ...subjects
            ]
          }
        }
        else return r
      }),
      rolesLib: {
        ...state.rolesLib,
        [role]: {
          ...state.rolesLib[role],
          subjects: [
            ...state.rolesLib[role].subjects,
            ...subjects
          ]
        }
      },
      subjects: [...state.subjects, ...subjects]
    })
  },
  //NOTE: this seems to cause issues when a subject is not added successfully
  [updateRoleAction]: (state, { payload: { oldName, newName, subjects }}) => {
    // if the list of subjects to update is shorter than the list of subjects in the role
    const partialUpdate = (subjects.length !== state.roles.find(r => r.role === oldName)?.subjects.length)
   
    const oldRole = state.roles.find(r => r.role === oldName)

    let newRoles = state.roles.map(r => (r.role === oldName)? { ...r, role: newName, id: newName, subjects} : r)
    const newLib = {...state.rolesLib}
    newLib[newName] = {...newLib[oldName], id: newName, role: newName, subjects }

    if(partialUpdate) {
      // get list of old role associations that must be preserved
      const rolesToPreserve = newLib[oldName].subjects.filter(subject =>
        !subjects.map(s => s.subject).includes(subject.subject)
      )
      newRoles = [
        ...newRoles,
        { ...oldRole, subjects: rolesToPreserve}
      ]
      newLib[oldName] = {
        ...newLib[oldName],
        subjects: rolesToPreserve
      }
    }
    else {
      delete newLib[oldName]
    }

    return {
      ...state,
      roles: newRoles,
      rolesLib: newLib
    }
  },
  [removeRolesAction]: (state, { payload: { roleNames }}) => {
    const roles = state.roles.filter(role => !roleNames.includes(role.id))
    return (
      {
        ...state,
        roles,
        rolesLib: makeLib({data: roles, key: "role"})
      })
  },
  [removeSubjectsAction]: (state, { payload: { roleName, ids }}) => {
    const { roles, rolesLib } = state
    const newRoles = roles.map(role =>
      (role.id === roleName)?
        { ...role, subjects: role.subjects.filter(r => !ids.includes(r.id))}
        : role
    )
    const newLib = {
      ...rolesLib,
      [roleName]: {
        ...rolesLib[roleName],
        subjects: rolesLib[roleName].subjects.filter(s => !ids.includes(s.id))
      }
    }
    return (
      {
        ...state,
        roles: newRoles,
        rolesLib: newLib,
        subjects: state.subjects.filter(s => !ids.includes(s.id))
      })
  },
  [setRolePoliciesAction]: (state, { payload: { subject, policies }}={}) => ({
    ...state,
    rolePolicies: {
      ...state.rolePolicies,
      [subject]: policies
    }
  }),
  [setPagingAction]: (state, { payload: { paging } }) => ({
    ...state,
    paging
  }),
  [setSubjectsPagingAction]: (state, { payload: { paging } }) => ({
    ...state,
    subjectsPaging: paging
  }),
  //Not clearing roles when switching spaces because they should be preserved.
  // [CLEAN_SPACE]: (state, action) => initialState
})

/* === DISPATCHERS === */
export const getRoles = ( { next=false }={} ) => {
  return async (dispatch, getState) => {
    try {
      const {
        roles: {
          roles: oldRoles,
          paging: oldPaging
        }
      } = getState()

      const requestParams = { field: "role" }
      if (next) {
        requestParams.next_cursor = oldPaging.next_cursor // for pagination
      }

      // get list of unique roles
      const { data, paging } = await getRolesRequest(requestParams)
      if (paging) dispatch(setPaging({ paging }))

      // get full roles list for every role
      const { data: roles } = await getRolesRequest({ "role[]": data.map(({ role }) => role )})

      const updatedRoles = next ? [...oldRoles, ...roles] : roles
      dispatch(setRolesAction({ roles: updatedRoles}))
      return roles
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Roles could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const getRoleSubjects = (role) => {
  return async dispatch => {
    try {
      const { data, paging } = await getRolesRequest({ "role[]": role })
      dispatch(setSubjectsAction({ subjects: data }))
      dispatch(setSubjectsPagingAction({ paging }))
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Role subjects could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const getNextSubjects = (role) => {
  return async (dispatch, getState) => {
    try {
      const {
        roles: {
          subjects,
          subjectsPaging: oldPaging
        }
      } = getState()
      const { data, paging } = await getRolesRequest({ "role[]": role, next_cursor: oldPaging.next_cursor })
      dispatch(setSubjectsPagingAction({ paging }))
      dispatch(setSubjectsAction({ subjects: [...subjects, ...data]}))
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Role subjects could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const createRoles = (role, subjects) => {
  return async dispatch => {
    try {
      const { has_errors, results } = await createRoleRequest(subjects.map(s => ({role, subject: s})))

      const idsLength = subjects.length
      const numberOfErrors = has_errors.length

      if (numberOfErrors) {
        const numberOfSuccessful = idsLength - numberOfErrors

        if(numberOfErrors < idsLength) {
          addMessage({
            text: "Action Complete",
            subtext: `${numberOfSuccessful} ${numberOfSuccessful === 1 ? "role": "roles"} created successfully, ${numberOfErrors} ${numberOfErrors === 1 ? "role": "roles"} could not be created`,
            type: MESSAGE_TYPE_WARNING,
          })
        }
        else {
          addMessage({
            text: "Role could not be created",
            subtext: idsLength === 1 ? results[0].response.error.message : "Roles could not be created",
            type: MESSAGE_TYPE_ERROR,
          })
        }
      }
      const successful = results.filter((r, index) => !has_errors.includes(index)).map(r => r.response)

      // group into a single object by role name
      const newSubjects = successful.map(r => ({ subject: r.subject, id: r.id }) )
      const [{domain}={}] = successful || []
      const newRole = { role, id: role, domain, subjects: newSubjects }
 
      dispatch(addRoleAction({role: newRole}))
      addMessage({
        text: "Role created successfully",
        type: MESSAGE_TYPE_SUCCESS,
      })
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        text: "Role could not be created",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const addSubject = (role, subject) => {
  return async (dispatch) => {
    try {
      const newRole = await createRoleRequest({role, subject})
      dispatch(addSubjectsAction({role: newRole.role, subjects: [ newRole ]}))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Subject successfully added",
        type: MESSAGE_TYPE_SUCCESS
      })
      return newRole
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Subject could not be added",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const addRoles = (roles) => {
  return async () => {
    try {
      const { has_errors, results } = await createRoleRequest(roles)
      if ( has_errors.length ) {
        addMessage({
          text: "Some roles could not be updated",
          type: MESSAGE_TYPE_WARNING,
        })
      }
      const successful = results.filter((r, index) => !has_errors.includes(index)).map(r => r.response)
      return successful
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        text: "Roles could not be updated",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const updateRole = (role, newName) => {
  
  return async dispatch => {
    try {
      
      const { role: roleName, subjects=[] } = role
      const { has_errors } = await updateRolesRequest(subjects.map(s => ({id: s.id, role: newName, subject: s.subject})))
      
      if(!has_errors.length) {
        dispatch(updateRoleAction({oldName: roleName, newName, subjects}))
        addMessage({
          text: "Role updated successfully",
          type: MESSAGE_TYPE_SUCCESS,
        })
        return true
      } else {
        dispatch(getRoles()) // for now, if a request fails, get roles again instead of trying to partially update redux lib
        addMessage({
          text: "Role could not be updated properly",
          type: MESSAGE_TYPE_WARNING
        })
        return false
      }

    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        text: "Role name could not be updated",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

//Call when updating role name
export const updateRolePolicySubjects = (oldRoleName, newRoleName) => {
  return async (dispatch, getState) => {
    try {
      //update role policy subjects to new role name
      const {
        roles: {
          rolePolicies: {
            [oldRoleName]: policies=[]
          }={}
        }={}
      } = getState()

      const { has_errors } = await updatePoliciesRequest(policies.map(policy => ({
        ...policy,
        subject: newRoleName
      })))

      if(has_errors.length) {
        addMessage({
          text: "Some role policies failed to update",
          type: MESSAGE_TYPE_WARNING
        })
      } else {
        addMessage({
          text: "Role policies updated successfully",
          type: MESSAGE_TYPE_SUCCESS,
        })
      }
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        text: "Some role policies failed to update",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const deleteRoles = (roleNames) => {
  return async (dispatch, getState) => {
    try {
      const ids = []
      const { rolesLib } = getState().roles

      // get the id of every role association with that role name
      for (const r of roleNames) {
        ids.push(...rolesLib[r].subjects.map(s => s.id))
      }

      const { has_errors } = await deleteRolesRequest({ "roleID[]": ids })

      if (has_errors.length) {
        addMessage({
          text: `${roleNames.length===1? "Role": "Some roles"} could not be deleted`,
          type: MESSAGE_TYPE_ERROR
        })
      }
      else {
        dispatch(removeRolesAction({roleNames}))
        addMessage({
          text: "Delete Successful",
          subtext: `Successfully deleted ${roleNames.length} role${roleNames.length !== 1 ? "s" : ""}`,
          type: MESSAGE_TYPE_SUCCESS,
        })
      }
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        text: `${roleNames.length===1? "Role": "Some roles"} could not be deleted`,
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const deleteSubjects = (roleName, ids) => {
  return async (dispatch) => {
    try {
      const { has_errors } = await deleteMultipleRoles(ids)
      const idsLength = ids.length
      const numberOfErrors = has_errors.length

      if (numberOfErrors) {
        const numberOfSuccessful = idsLength - numberOfErrors

        if(numberOfErrors < idsLength) {
          addMessage({
            target: GLOBAL_NOTIFICATIONS,
            text: "Delete Complete",
            subtext: `${numberOfSuccessful} ${numberOfSuccessful === 1 ? "subject": "subjects"} deleted successfully, ${numberOfErrors} ${numberOfErrors === 1 ? "subject": "subjects"} could not be deleted`,
            type: MESSAGE_TYPE_WARNING,
          })
          dispatch(removeSubjectsAction({roleName, ids: ids.filter((id, index) => !has_errors.includes(index) )}))
        }
        else {
          addMessage({
            target: GLOBAL_NOTIFICATIONS,
            text: "Delete Failed",
            subtext: `${idsLength === 1 ? "Subject" : "Subjects"} could not be deleted`,
            type: MESSAGE_TYPE_ERROR,
          })
        }
        return false
      }

      dispatch(removeSubjectsAction({roleName, ids}))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Delete Complete",
        subtext: `${idsLength === 1 ? "Subject was": `${idsLength} Subjects were`} deleted successfully`,
        type: MESSAGE_TYPE_SUCCESS,
      })
    }
    catch (error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Subjects could not be deleted",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR,
      })
    }
  }
}

export const getRolePolicies = subject => {
  return async dispatch => {
    try {

      const response = await getPoliciesRequest({subject, limit: 1000})
      const { data } = response
      const policies = data? data : response // preserve backwards compatibility
      //NOTE: no longer checking if policies array is not empty before setting in state
      // want to set empty array if role has no policies
      // if (policies.length) dispatch(setRolePolicies(policies))
      dispatch(setRolePolicies(policies, subject))
      return policies
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Could not get list of user roles",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const setRolePolicies = (policies=[], optionalSubject) => {
  return dispatch => {
    const [{subject: firstSubject}={}] = policies
    const subject = firstSubject || optionalSubject
    dispatch(setRolePoliciesAction({subject, policies}))
  }
}


/* === UTILS === */

export const setPaging = ( {paging} ) => {
  return dispatch => dispatch(setPagingAction({paging}))
}
