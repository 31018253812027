import axiosInstance from "utils/axios"
import { formatUriParams } from "utils/routes"

const MOCKED = process.env.REACT_APP_ENVIRONMENT === "development"

export const UNAUTH = "UNAUTH"

let getMock = () => {}
const mockImports = () => import("./mocks.js")
const loadMocks = async () => {
  const { getMock: getMockFromModule } = await mockImports()
  getMock = getMockFromModule
}

if(MOCKED) {
  loadMocks()
}


//NOTE: do not need to wrap with dispatcher
export async function get(endpoint, urlParams, options={}) {
  if (MOCKED) return getMock("get", endpoint, urlParams, !options.rootRequest)
  return requestAction({ method: "get", endpoint, urlParams, options })
}

export function post(endpoint, body={}, options={}) {
  if (MOCKED) return getMock("post", endpoint, body, !options.rootRequest)
  return requestAction({ method: "post", endpoint, body, options })
}

export function put(endpoint, body={}, options={}) {
  if (MOCKED) return getMock("put", endpoint, body, !options.rootRequest)
  return requestAction({ method: "put", endpoint, body, options })
}

export function del(endpoint, urlParams, options={}) {
  if (MOCKED) return getMock("delete", endpoint, {}, !options.rootRequest)
  return requestAction({ method: "delete", endpoint, urlParams, options })
}

export function patch(endpoint, body={}, options={}) {
  if (MOCKED) return getMock("patch", endpoint, body, !options.rootRequest)
  return requestAction({ method: "patch", endpoint, body, options })
}

//NOTE: requestAction has diverged from toggled_common
export async function requestAction({ method, endpoint, body, urlParams, options={} }) {
  let uri = endpoint
  if (urlParams instanceof Object) uri += formatUriParams(urlParams)
  if (typeof urlParams === "string" && urlParams.startsWith("?")) uri += urlParams
  try {
    const response = await axiosInstance({ method, url: uri, data: body, ...options })
    const { status } = response
    if (status === 204) return // changing this will break every delete request function
    // TODO: after the API update, some successful responses have a status code different to 200 (e.g., 201 for succesfully created)
    // However, inside every request function, we are checking that the status code = 200 before returning the response, and therefore
    // those functions break when we receive a status code in the 2xx range but different to 200.
    // The following line is a temporary patch to avoid refactoring how we handle that in every request function.
    response.status = 200
    return response
  } catch (error) {
    const {
      status,
      data: http_body
    } = error || {}
    let body
    try {
      body = http_body ? JSON.parse(http_body) : {}
    } catch {
      body = http_body
    }
    // backend error responses are not unified. Until that, we have to handle all possible variations:
    const errorBody = body?.error || body?.Error || body
    const message = errorBody?.message || errorBody?.Message
    if (status && body) throw new ApiRequestError(message || http_body, status)
    else throw new ApiRequestError(error?.message, error?.status)
  }
}


//Used if api endpoint may return 409 with required uriParameters
//Will recursively call the endpoint (only once) with the required uriParameters received from intial response
export function satisfyRequiredParams({request, url, urlParams={}, isRecursive}) {
  return dispatch => {
    return dispatch(request(url, urlParams)).then(resp => {
      const {meta: {httpStatus, messages=[]}={}} = resp || {}

      if (isRecursive) return resp

      if (httpStatus === 409) {
        const requiredParamsMsg = messages.find(msg => !!msg.queryOptions) || {}
        const {queryOptions=[]} = requiredParamsMsg

        for(let i = 0; i < queryOptions.length; i++) {
          const {uriParameters} = queryOptions[i] || {}

          if (uriParameters) {
            //Recurse
            return dispatch(satisfyRequiredParams({
              request,
              url: `${url}`,
              urlParams: {
                ...urlParams,
                ...uriParameters
              },
              isRecursive: true
            }))
          }
        }
      }

      return resp
    })
  }
}

//Returns Boolean - true if all responses are 200 or 204
export function okResponseArray(promiseArray) {
  return promiseArray.reduce((totalResp=true, resp={}) => {
    const { status } = resp || {}

    return (status === 204 || status === 200) && totalResp
  }, true)
}

export function urlParse({ url, query={}, ...urlVars}) {
  let out = url || ""
  // determine if URL vars
  const hasVars = out.includes(":")
  // if URL vars
  if (hasVars) {
    // replace url vars with refs in 'urlVars'
    // split into individual url parts on '/'
    let parts = out.split("/").slice(1)
    // iterate over parts
    let {path} = parts.reduce(({path, stop}, part) => {
      if (stop) return {path, stop}
      // no url var, add to path
      if (!part.includes(":")) return {path: `${path}/${part}`}
      // get url var
      const replace = urlVars[part.slice(1)]
      // stop if not found
      if (!replace) return {path, stop: true}
      // add urlVar
      return {path: `${path}/${urlVars[part.slice(1)]}`}
    }, {path: ""})
    out = path
  }
  // add query handling on navigation
  const queryParams = Object.entries(query)
  // go over entries
  if (queryParams.length) {
    // on first pass, add ?
    let queryList = ""
    queryList = queryParams.reduce((list, [key, value]) => {
      // if not first, add &
      if (list.length) list = `${list}&`
      // add param=value
      return `${list}${key}=${value}`
    }, queryList)
    out = `${out}?${queryList}`
  }
  return out
}

// takes path to navigate to, array of safe paths, and func to handle navigation
// if not safe, calls callback w/ first safe path
export function authDirect({path="", safe=["/"], authorized=false, fail=()=>{}}) {
  if(!authorized && !safe.includes(path)) {
    fail(safe[0])
    return false
  } else {
    return true
  }
}

/**
 * Pace out multiple requests, provide updates as requests are resolved
 * @param {array} requestGenerators - array of requests wrapped in functions
 * @param {integer} batchSize - maximum number of pending requests at any given time
 * @callBack onProgress - callback fires each time a request is resolved
 * Returns Promise that resolves with array of all responses
 */
export function paceRequests({
  requestGenerators=[],
  batchSize=3,
  onProgress=()=>{}
}) {
  return new Promise((resolve) => {
    let requestIndex = 0 //index of last request made in requestGenerators
    let running = 0 //number of concurrent pending requests
    let responses = [] //array of responses to be returned to calling function
    let totalResolved = 0 //number of resolved requests can be different than responses.length if resolved out of order

    //Returns boolean - can next request be fired
    const canProcess = () =>
      running < batchSize && (requestIndex + 1) < requestGenerators.length

    const processRequest = (index) => {
      //increment number of pending requests
      running++

      //process next request in queue
      //fire onProgress callback and update responses when resolved
      requestGenerators[index]()
        .then(resp => {
          onProgress(resp, index)
          responses[index] = resp
          return resp
        })
        .catch(error => {
          onProgress(error, index)
          responses[index] = error
          //NOTE that throwing the error will crash cloning workflow
          return error
        })
        .finally(() => {
          running--
          totalResolved++

          //process next request in queue
          if (canProcess()) {
            processRequest(++requestIndex)
          }

          //return responses once all requests have resolved
          if (totalResolved === requestGenerators.length) {
            resolve(responses)
            return
          }
        })

      //fire up to {batchSize} concurrent requests
      if (canProcess()) {
        processRequest(++requestIndex)
      }

    }

    //Start processing request queue
    processRequest(requestIndex)
  })
}

export function findBatchRequestErrors(responses) {
  const error = responses.find(r => r instanceof Error)
  if (error) throw error
}

export function filterSuccessfulRequests(responses) {
  return responses.filter(r => !(r instanceof Error))
}

class ApiRequestError extends Error {
  constructor(message, status) {
    super(message)
    this.message = message
    this.name = "ApiRequestError"
    this.status = status
  }
}

export const parseApiErrorMessage = (error) => {
  if (typeof error === "string") {
    if (error.includes("ApiRequestError")) {
      const message = error.slice(": ")[1]
      if (message) {
        const capitalizedMessage = message.charAt(0).toUpperCase() + message.slice(1)
        return capitalizedMessage
      }
    }
  } else if (error?.message) {
    const capitalizedMessage = error.message.charAt(0).toUpperCase() + error.message.slice(1)
    return capitalizedMessage
  }
  return error || ""
}

export function getThingBaseUrl(category) {
  return category ? `/categories/${category}` : ""
}

export const fileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => {
    let encoded = reader.result.toString().replace(/^data:(.*,)?/, "")
    if ((encoded.length % 4) > 0) {
      encoded += "=".repeat(4 - (encoded.length % 4))
    }
    resolve(encoded)
  }
  reader.onerror = error => reject(error)
})