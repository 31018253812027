import { UnityButton } from "@bit/smartworks.unity-react.unity-core-react"
import { Typography } from "antd"
import { noop } from "lodash"
import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import "@bit/smartworks.unity.unity-core/unity-icon"
import styled from "styled-components"
import { v4 as uuid } from "uuid"

import { getUserRole } from "actions/auth"
import { navigate } from "actions/navigate"
import { HomeIcon } from "assets/icons"
import { colors } from "assets/stylesheets/common"
import UnityIcon from "components/unity/UnityIcon"
import UnityProgressModal from "components/unity/UnityProgress/UnityProgressModal"
import UnitySidebar from "components/unity/UnitySidebar/UnitySidebar"
import { BOUNDARIES_ENABLED, FREE_TRIAL_EXPIRATION_ENABLED } from "constants/featureFlags"
import {
  ACCESS_CONTROL,
  ALERTS,
  ANYTHING_DB,
  APPLICATIONS,
  APPS,
  ASSET_MANAGEMENT,
  AU_MANAGEMENT,
  CATEGORIES,
  CLUSTER_MANAGEMENT,
  CUSTOM_QUERIES,
  DATA_SOURCES,
  DATA_VIZ,
  DISTRIBUTIONS,
  ECP_IMAGES,
  EDGE_APPS,
  EDGE_APPS_MANAGEMENT,
  EDGE_OPS,
  EVERY_THING,
  FUNCTION_LOG,
  FUNCTIONS,
  HIGH_FREQ_DATA,
  HOME_PAGE,
  HTTP_INSPECTOR,
  LABELS,
  labels,
  MODELS,
  MQTT_INSPECTOR,
  newIcons,
  OBJECT_STORAGE,
  PACKAGES,
  RESOURCES,
  ROLES,
  ROLLOUTS,
  SERVERLESS,
  SPACE_GENERAL,
  SPACE_SETTINGS,
  STREAM_PARAMETERS,
  STREAMS,
  TRIGGERS,
  USERS,
  UTILITY_BELT,
  VARIABLES_STORAGE,
  VIS_PARAMETERS,
  WORKBOOKS
} from "constants/routes"
import {getStatus} from "reduxModules/auManagement"
import { closePane } from "reduxModules/layout"
import { getSpaceId } from "utils/auth"
import { getColorBrightness } from "utils/misc"
import { endpointToRoute } from "utils/routes"
import { getSpace } from "utils/storage"
import { addTabToBelt, NEW_FUNCTION_LOG } from "utils/utilityBelt"

import FreeTrialModal from "./FreeTrialModal"


const stateToProps = ({
  authentication: {
    userInfo: {
      username
    }
  },
  login,
  spaces: {
    spacesLib={}
  }={},
  admin: {
    spacesLib: adminSpacesLib={}
  },
  auManagement: {
    status
  },
  objects: {
    uploadProgress,
    fetchingUpload,
  }
}) => {
  const space = spacesLib[getSpace()]
  const adminSpace = adminSpacesLib[getSpace()]
  const settings = space?.settings
  return {
    settings,
    login,
    status,
    isOwner: spacesLib[getSpaceId()]?.owner === username,
    fetching: false,
    currentSpace: getSpaceId(),
    currentSpaceName: space?.name,
    spaceColor: space?.color,
    spaceNavUserOptions: space
      ? space.settings?.filter(el => el.name === "menu")[0]?.options?.visibility
      : adminSpace?.settings?.filter(el => el.name === "menu")[0]?.options?.visibility,
    uploadProgress,
    fetchingUpload
  }
}

// to add a nav item, add a route const based obj in navList arr
// order is important
// key: the route const
// children: arr of more, top and top level only
// bottom: bool for if item should be on bottom
export const getNavList = (spaceNavUserOptions, authorized=true) => {
  const unauthorized = !authorized
  return [
    {
      key: ANYTHING_DB,
      hide: unauthorized || !spaceNavUserOptions?.anything_db,
      children: [
        { key: EVERY_THING, hide: !spaceNavUserOptions?.anything_db_submenu.every_thing },
        { key: MODELS, hide: !spaceNavUserOptions?.anything_db_submenu.models },
        // TODO: remove structure option from condtional below once it has been renamed in the backend
        { key: CATEGORIES, hide: !(spaceNavUserOptions?.anything_db_submenu.structure || spaceNavUserOptions?.anything_db_submenu.categories) },
        { key: CUSTOM_QUERIES, hide: !spaceNavUserOptions?.anything_db_submenu.custom_queries }
      ]
    },
    {
      key: SERVERLESS,
      hide: unauthorized || !spaceNavUserOptions?.functions,
      children: [
        { key: FUNCTIONS, hide: !spaceNavUserOptions?.functions_submenu.workers },
        { key: TRIGGERS, hide: !spaceNavUserOptions?.functions_submenu.triggers }
      ]
    },
    {
      key: STREAMS,
      hide: unauthorized || !spaceNavUserOptions?.stream_processing,
      children: [
        { key: APPLICATIONS, hide: !spaceNavUserOptions?.stream_processing_submenu.applications },
        { key: DATA_SOURCES, hide: !spaceNavUserOptions?.stream_processing_submenu.data_sources },
        { key: STREAM_PARAMETERS, hide: !spaceNavUserOptions?.stream_processing_submenu.parameters },
      ]
    },
    {
      key: DATA_VIZ,
      hide: unauthorized || !spaceNavUserOptions?.real_time_visualizations,
      children: [
        { key: WORKBOOKS, hide: !spaceNavUserOptions?.real_time_visualizations_submenu.workbooks },
        { key: ALERTS, hide: !spaceNavUserOptions?.real_time_visualizations_submenu.alerts },
        { key: VIS_PARAMETERS, hide: !spaceNavUserOptions?.real_time_visualizations_submenu.parameters },
      ]
    },
    {
      key: EDGE_OPS,
      hide: unauthorized || !spaceNavUserOptions?.edge_ops,
      children: [
        {
          key: ASSET_MANAGEMENT,
          children: [
            { key: CLUSTER_MANAGEMENT , hide: !spaceNavUserOptions?.edge_ops_submenu.fleet_management },
            { key: ECP_IMAGES, hide: !spaceNavUserOptions?.edge_ops_submenu.images },
          ]
        },
        {
          key: EDGE_APPS_MANAGEMENT,
          children: [
            { key: EDGE_APPS, hide: !spaceNavUserOptions?.edge_ops_submenu.edge_applications },
            { key: RESOURCES, hide: !spaceNavUserOptions?.edge_ops_submenu.resource_catalog },
          ]
        },
        { key: DISTRIBUTIONS, hide: !spaceNavUserOptions?.edge_ops_submenu.distributions },
        { key: ROLLOUTS, hide: !spaceNavUserOptions?.edge_ops_submenu.rollouts }
      ]
    },
    { key: PACKAGES, hide: unauthorized || !spaceNavUserOptions?.market_place },
    {
      key: ACCESS_CONTROL,
      admin: true,
      hide: unauthorized || !spaceNavUserOptions?.access_control,
      children: [
        { key: USERS, admin: true, hide: !spaceNavUserOptions?.access_control_submenu.users },
        { key: ROLES, admin: true, hide: !spaceNavUserOptions?.access_control_submenu.roles },
        { key: APPS, admin: true, hide: !spaceNavUserOptions?.access_control_submenu.apps }
      ]
    },
    { key: LABELS, hide: unauthorized || !spaceNavUserOptions?.labels },
    { key: OBJECT_STORAGE, hide: unauthorized || !spaceNavUserOptions?.object_storage },
    {
      key: SPACE_SETTINGS,
      children: [
        { key: SPACE_GENERAL, hide: unauthorized },
        { key: HIGH_FREQ_DATA, hide: unauthorized },
        { key: VARIABLES_STORAGE, hide: unauthorized},
        { key: AU_MANAGEMENT, hide: !BOUNDARIES_ENABLED }
      ]
    },
    { key: UTILITY_BELT,
      hide: unauthorized,
      children: [
        { key: FUNCTION_LOG },
        { key: MQTT_INSPECTOR },
        { key: HTTP_INSPECTOR }
      ]
    }
  ]
}

const bottomList = []

export const makeItems = (list=[]) => {

  // exclude admin sections if user is not root
  const role = getUserRole()
  let visibleList = list.filter(item => !( (role !=="root" && item.admin) || item.hide) )

  // scale tree
  // if element has child, set children to result of makeItems
  return visibleList.reduce((allItems, item) => {
    // if item is an array, split into key and children
    allItems.push(makeNavItem(item))
    return allItems
  }, [])
}

const makeNavItem = ({ key, children=[]}) => {
  return {
    key,
    label: labels[key],
    icon: <UnityIcon Component={newIcons[key]} style={{minWidth: 16}}/>,
    children: children.length ? makeItems(children) : []
  }
}

const SubHeader = ({ collapsed, currentSpaceName, className, onClick, authorized }) => {
  return (
    <div
      id="subheader" // we need to change this ID to be more specific, but this will involve QA changes so make it separated
      title="Home"
      onClick={authorized ? onClick : noop}
      className={className}
    >
      {!collapsed ? <p>{currentSpaceName}</p> : null}
      <HomeIcon className="icon" />
    </div>
  )
}

const StyledSubHeader = styled(SubHeader)`
display: flex;
align-items: center;
justify-content: space-between;
padding: 4px 16px 6px 12px;
height: 32px;

${({ authorized }) => authorized && `
  cursor: pointer;
`}
${({ collapsed }) => collapsed && `
  justify-content: center;
  padding: 4px 0 6px 0;
`}
${({ spaceColor }) => spaceColor && `
background-color: ${spaceColor};

&:hover {
    background-color: ${spaceColor};
  }
`}

& p {
  color: ${({ lightColor }) => lightColor ? "black" : "white"};
  min-width: max-content;
}


& .icon {
  fill: ${({ lightColor }) => lightColor ? "black" : "white"}!important;
}
`

class SideNav extends Component {
  state = {
    showFreeTrialModal: false,
    uploadProgress: 0,
    showProgressModal: false,
    showBoundaryStatusInformation: false
  }
  componentDidMount = async () => {
    this.showProgress()
    const { currentSpace, getStatus} = this.props
    if (FREE_TRIAL_EXPIRATION_ENABLED) {
      await getStatus(currentSpace)
      this.setState({showBoundaryStatusInformation: true})
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.uploadProgress !== prevProps.uploadProgress) {
      this.showProgress()
    }
  }
  showProgress() {
    const { uploadProgress } = this.props
    if (uploadProgress >= 100) {
      this.setState({ showProgressModal: true })
      setTimeout(() => {
        this.setState({ showProgressModal: false })
      }, 5000)
    }
  }


  getSubHeader = (collapsed) => {
    const { authorized, currentSpaceName, navigate, spaceColor } = this.props
    const brightness = spaceColor? getColorBrightness(spaceColor) : "white"
    const lightColor = brightness >= 0.5

    if (!currentSpaceName) {
      return null
    }

    return (
      <StyledSubHeader
        authorized={authorized}
        onClick={() => navigate({ to: HOME_PAGE })}
        collapsed={collapsed}
        spaceColor={spaceColor}
        lightColor={lightColor}
        currentSpaceName={currentSpaceName}
      />
    )
  }


  getBottomContent = (collapsed) => {
    if (FREE_TRIAL_EXPIRATION_ENABLED) {
      const {isOwner, status} = this.props

      const showBoundaryStatusInformation = this.state.showBoundaryStatusInformation
      const label = status.free ? "Free trial version " : "Commercial version"

      let formattedDate
      const expirationDate = new Date(status.expiration)
      const currentDate = new Date()
      const daysUntilExpiration = Math.ceil((expirationDate - currentDate) / (1000 * 60 * 60 * 24))

      if (daysUntilExpiration > 30) {
        formattedDate = `Expires on ${expirationDate.toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })}`
      } else {
        formattedDate = `Expires in ${daysUntilExpiration} days`
      }

      return (
        <>
          {
            !collapsed && !isNaN(status.free) && showBoundaryStatusInformation &&
          <div style={{...styles.freeTrialInformation }}>
            <Typography.Title level={2} style={{...styles.freeTrialText, paddingBottom: "0px"}} id={"side-nav-space-version-information"} >
              {label}
            </Typography.Title>
            {status.free && (
              <>
                <Typography style={ styles.freeTrialText } id={"side-nav-space-version-expire-date"} >
                  {formattedDate}
                </Typography>
                {isOwner &&
                <UnityButton
                  id="side-nav-space-version-button"
                  label={"Upgrade to commercial version"}
                  onClick={this.toggleModal}
                  type= "secondary"
                  style={styles.modalButton}
                />
                }
              </>
            )}
          </div>
          }
        </>
      )
    }
  }
  toggleModal = mode => {
    this.setState({ showFreeTrialModal: typeof mode === "boolean" ? mode : !this.state.showFreeTrialModal })
  }
  render() {
    const {
      authorized,
      location: {
        pathname
      }={},
      children,
      navigate,
      closePane,
      status,
      spaceNavUserOptions,
      uploadProgress,
      fetchingUpload
    } = this.props

    const splitPath = pathname.split("/")
    const endpoint = splitPath.slice(-2)

    const selectedEndpoint = endpoint[1] === "anything-db" ? null : endpoint[1] // path to properly select old anythingdb menu
    // call makeNavItem for each item needed
    // item with children can be spread into obj and add children of makeNavItem
    let items = makeItems(getNavList(spaceNavUserOptions, authorized))
    const bottomItems = makeItems(bottomList)
    let navConfig = {
      top: items,
      bottom: bottomItems
    }
    const { showProgressModal } = this.state

    return (
      <div className="main" style={styles.mainContainer}>
        <div className="nav-container" style={styles.navWrapper}>
          <UnitySidebar
            collapsible
            customHeader={<></>}
            onSelect={(key) => {
              closePane(key, true)
              let target = { to: key }
              //Utility Belt menu
              if (key === FUNCTION_LOG) return addTabToBelt({type: FUNCTIONS, id: NEW_FUNCTION_LOG})
              if (key === MQTT_INSPECTOR) return addTabToBelt({type: MQTT_INSPECTOR, id: MQTT_INSPECTOR})
              if (key === HTTP_INSPECTOR) return addTabToBelt({type: HTTP_INSPECTOR, id: uuid()})
              navigate(target)
            }}
            items={navConfig}
            selected={endpointToRoute[selectedEndpoint]}
            subHeader={this.getSubHeader}
            bottomContent={this.getBottomContent}
          />
        </div>
        <div className="view" style={styles.viewContainer}>
          {
            FREE_TRIAL_EXPIRATION_ENABLED && status.free && status.expiration !=="" &&
              <FreeTrialModal show={this.state.showFreeTrialModal} handleToggle={this.toggleModal}></FreeTrialModal>
          }
          {children}
          {((uploadProgress > 0 && uploadProgress < 100) || showProgressModal) && (
            <UnityProgressModal title="Uploading Objects..." progress={uploadProgress} fetching={fetchingUpload}/>
          )}
        </div>
      </div>
    )
  }
}

export default connect(stateToProps, {
  navigate,
  closePane,
  getStatus
})(withRouter(SideNav))

const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    flex: 1,
    position: "relative",
    height: "100%",
    width: "100%",
    maxWidth: "100%"
  },
  navWrapper: {
    alignSelf: "stretch",
    display: "flex"
  },
  navContainer: {
    flex: 1,
    position: "relative",
    alignSelf: "stretch"
  },
  viewContainer: {
    flex: 1,
    overflowY: "auto"
  },
  collapsedSubHeader: {
    justifyContent: "center",
    padding: "4px 0 6px 0"
  },
  subHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "4px 16px 6px 12px",
    borderTop: `1px solid ${colors.gray}`,
  },
  freeTrialInformation:{
    display: "block",
    width: "100%",
    background:"#FFFFFF",
    flex: 0
  },
  modalButton:{
    padding: "0px 4px 2px 4px",
    "--button-width": "100%",
    "--unity-border-radius": "2px",
    "--default-button-secondary-text-color": "var(--tertiary-1-color)",
    "--button-secondary-color": "var(--tertiary-1-color)",
    "--button-hover-color": "#FC977E"
  },
  freeTrialText:{
    display: "flex",
    alignItems: "center",
    padding: "0px 16px 6px 12px",
  }
}
