import React from "react"

import { PROXY_URL } from "constants/config"
import {ALTAIR_ONE_REFERER_PARAM_KEY, ALTAIR_ONE_REFERER_PARAM_VALUE, LOGIN} from "constants/routes"
import { routes } from "utils/routes"


class SignIn extends React.Component {
  componentDidMount() {

    const referrerParam = new URLSearchParams(window.location.search).get(ALTAIR_ONE_REFERER_PARAM_KEY)
    const redirectUrl = referrerParam === ALTAIR_ONE_REFERER_PARAM_VALUE ?
      `${PROXY_URL}${routes[LOGIN]}?${ALTAIR_ONE_REFERER_PARAM_KEY}=${ALTAIR_ONE_REFERER_PARAM_VALUE}` :
      `${PROXY_URL}${routes[LOGIN]}`

    window.location.replace(redirectUrl)
  }

  render() {
    return null
  }
}

export default SignIn
