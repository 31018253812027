import { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

export const openNewTab = url => {
  window.open(url, "_blank", "noopener,noreferrer")
}

const stateToProps = (
  {
    router: {
      history
    }={},
  }={},
  {
    page
  }={}
) => ({
  history,
  page
})

class NewTabLink extends Component {

  componentDidMount() {
    const { history, page } = this.props
    openNewTab(page)
    history.goBack()
  }

  render () {
    return null
  }
}

export default connect(stateToProps, {})(withRouter(NewTabLink))
