import { UnityTypography } from "@bit/smartworks.unity-react.unity-core-react"
import moment from "moment"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

import DateInput from "components/common/Inputs/DateInput"
import { setFunctionsDateSearch } from "reduxModules/serverlessFunctions"

const FunctionLogsDateSearch = () => {

  const dispatch = useDispatch()
  const functionsDateSearch = state => state.serverlessFunctions.functionsDateSearch

  const dateSearch = moment(useSelector(functionsDateSearch)).format("YYYY-MM-DD")

  const updateDateSearch = date => {
    return dispatch(setFunctionsDateSearch(moment(date).format("YYYY-MM-DDTHH:mm:ss[Z]")))
  }

  return (
    <div style={styles.datepickerContainer}>
      <UnityTypography>From: </UnityTypography>
      <DateInput
        id={"date-from-function-logs"}
        value={dateSearch}
        style={{...styles.datepickerContainer, ...styles.datepicker}}
        onChange={updateDateSearch}
      />
      <UnityTypography>To: {new Date().toLocaleDateString(undefined, {year: "numeric", month: "2-digit", day: "2-digit"})}</UnityTypography>
    </div>
  )
}

export default FunctionLogsDateSearch

const styles = {
  datepickerContainer: {
    display: "flex",
    alignItems: "center"
  },
  datepicker: {
    marginLeft: 6,
    marginRight: 20,
    width: "176px"
  }
}