import { getSpace } from "utils/storage"


export const getTableNameByCategory = (category) => {
  const spaceId = getSpace()
  return `${spaceId}-${category}-category`
}

export const haveColFiltersChanged = (originalFilters, columnFilters) => {
  if (Object.keys(originalFilters).length !== Object.keys(columnFilters).length) return true

  for(let colKey in originalFilters) {
    if (!columnFilters[colKey]) return true
    const orig = originalFilters[colKey]
    const next = columnFilters[colKey]

    if (orig?.length !== next?.length) return true

    const colHasChanged = orig.some((filter, i) => {
      const nextFilter = next[i]
      return filter.operator !== nextFilter.operator || filter.value !== nextFilter.value
    })

    if (colHasChanged) return true
  }
  return false
}

export const EQUAL_OPERATOR = { label: "==", id: "eq" }
export const HAS_CATEGORY_OPERATOR =
  { label: "Has category", id: "in_category" }

export const titleColumn = {
  key: "title",
  label: "Title",
  showFilter: true,
  singleFilter: true,
  operators: [EQUAL_OPERATOR],
}

export const uidColumn = {
  key: "uid",
  label: "UID",
  showFilter: true,
  operators: [EQUAL_OPERATOR]
}

export const labelsColumn = {
  key: "labels",
  label: "Labels",
  hideSort: true,
}

export const categoriesColumn = {
  key: "categories",
  label: "Categories",
  showFilter: true,
  operators: [HAS_CATEGORY_OPERATOR],
  formatLabel: value => value.length ? value.join(", ") : null
}

export const modifiedColumn = {
  key: "modified",
  label: "Modified",
  // formatLabel: (val) => formatDate(val) || val
}

