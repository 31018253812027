/* === IMPORTS === */
import { createAction, createReducer } from "@reduxjs/toolkit"

import { CLEAN_SPACE } from "actions/auth"

const setColumnFiltersAction = createAction("SET_COLUMN_FILTERS")

/* === INITIAL STATE === */
const initialState = {
}

/* === Reducer === */
export default createReducer(initialState, {
  [setColumnFiltersAction]: (state, { payload: { table, columnFilters } }) => {
    return {
      ...state,
      [table]: columnFilters
    }
  },
  [CLEAN_SPACE]: () => initialState
})

/* === DISPATCHERS === */
//Sets columns, if visibleColumns is empty, sets that to columns too
export const setColumnFilters = ({ table, columnFilters }) => {
  return dispatch => {
    return dispatch(setColumnFiltersAction({ table, columnFilters }))
  }
}