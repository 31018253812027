/* === IMPORTS === */
import { createReducer } from "@reduxjs/toolkit"

import { CLEAN_SPACE } from "actions/auth"

import { makeActions } from "./utiliducks"

/* === ACTIONS === */
const actionList = [
  "openModalAction",
  "closeModalAction"
]

const {
  openModalAction,
  closeModalAction
} = makeActions("modal", actionList)

/*
  There is an issue with handling modal content through the store:
  When the modal renders, it skips the onMount step for some components. This
  means that if the modal content you are trying to render needs to have
  obj/arr/func injected, it won't actually update the ref as it's supposed to.
  The strangest part is that it worked for the button going into top/bottom, but
  not for the dropzone going into the body content. I'll have to look into this
  later. For now, it'll remain here until we think it's an inefficient way of
  handling modal content or whatver other reason we'll want to get rid of it..
*/

/* === INITIAL STATE === */
const initialState = {
  show: false,
  title: "",
  top: "",
  body: "",
  bottom: "",
  cancelOnOutsideClick: false
}

/* === REDUCER === */
export default createReducer(initialState, {
  [openModalAction]: (state, { payload: openModalData={} }={}) => ({
    ...state,
    ...openModalData,
    show: true
  }),
  [closeModalAction]: () => ({
    ...initialState
  }),
  [CLEAN_SPACE]: () => initialState
})

/* === DISPATCHERS === */
export const openModal = (modalProps={}) => {
  return dispatch => {
    return dispatch(openModalAction(modalProps))
  }
}

export const closeModal = () => {
  return dispatch => {
    return dispatch(closeModalAction())
  }
}
