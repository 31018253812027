/* === IMPORTS === */
import { createReducer } from "@reduxjs/toolkit"

import { CLEAN_SPACE } from "actions/auth"
import { parseApiErrorMessage } from "utils/api"
import { makeLib } from "utils/misc"
import {
  addMessage,
  GLOBAL_NOTIFICATIONS,
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS,
} from "utils/notifications"
import {
  createRolloutRequest,
  deleteRolloutRequest,
  getRolloutRequest,
  getRolloutsRequest,
  pauseRolloutRequest,
  playRolloutRequest,
  resumeRolloutRequest
} from "utils/rollouts"
  
import { makeActions } from "./utiliducks"

/* == ACTIONS === */
const actionList = [
  "setRolloutAction",
  "setRolloutsAction",
  "addRolloutAction",
  "deleteRolloutsAction",
  "setPagingAction"
]
const {
  setRolloutAction,
  setRolloutsAction,
  addRolloutAction,
  deleteRolloutsAction,
  setPagingAction
} = makeActions("rollouts", actionList)

/* === INITIAL STATE === */
const initialState = {
  rollouts: [],
  rolloutsLib: {},
  paging: { previous_cursor: "", next_cursor: "" },
  currentRollout: undefined
}

/* === Reducer === */
export default createReducer(initialState, {
  [setRolloutsAction]: (state, { payload: { rollouts }}={}) => ({
    ...state,
    rollouts,
    rolloutsLib: makeLib({data: rollouts})
  }),
  [setRolloutAction]: (state, { payload: { rollout }}={}) => ({
    ...state,
    currentRollout: rollout
  }),
  [addRolloutAction]: (state, { payload: { rollout }}) => ({
    ...state,
    rollouts: [
      ...state.rollouts,
      rollout
    ],
    rolloutsLib: {
      ...state.rolloutsLib,
      [rollout.id]: rollout
    }
  }),
  [deleteRolloutsAction]: (state, { payload: { ids }}) => {
    const rollouts = state.rollouts.filter(({ id }) => !ids.includes(id))
    return (
      {
        ...state,
        rollouts,
        rolloutsLib: makeLib({data: rollouts})
      })
  },
  [CLEAN_SPACE]: () => initialState,
  [setPagingAction]: (state, { payload: { paging } }) => ({
    ...state,
    paging
  })
})

/* === DISPATCHERS === */
export const getRollouts = () => {
  return async (dispatch) => {
    try {
      const response = await getRolloutsRequest()
      const { data=[], paging } = response
      if (!Array.isArray(data)) throw new Error(`Invalid data format: ${JSON.stringify(data)}`)

      const rollouts = data.map(rollout => {
        return {
          ...rollout,
          id: rollout.name
        }
      })
  
      dispatch(setRolloutsAction({ rollouts }))
      if (paging) dispatch(setPaging({ paging }))
      return data
    }
    catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Rollouts could not be retrieved",
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
      return []
    }
  }
}

export const getRollout = (rolloutName) => {
  return async dispatch => {
    try {
      if (!rolloutName) {
        dispatch(setRolloutAction({rolloutName, rollout: undefined}))
        return
      }
      const response = await getRolloutRequest(rolloutName)
      dispatch(setRolloutAction({rollout: response}))
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Rollout could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const getNextRollouts = () => {
  return async (dispatch, getState) => {
    const {
      rollouts: {
        rollouts,
        paging: {
          next_cursor
        }={}
      }
    } = getState()
    const { data, paging } = await getRolloutsRequest({ next_cursor })

    const nextRollouts = data.map(rollout => {
      return {
        ...rollout,
        id: rollout.name
      }
    })

    dispatch(setPaging({ paging }))
    dispatch(setRolloutsAction({ rollouts: [ ...rollouts, ...nextRollouts ]}))
  }
}

export const createRollout = (rolloutData) => {
  return async (dispatch) => {
    try {
      const response = await createRolloutRequest(rolloutData)
      const nextRolloutData = {
        ...response,
        id: response.name
      }
      dispatch(addRolloutAction({ rollout: nextRolloutData }))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Rollout created successfully.",
        type: MESSAGE_TYPE_SUCCESS,
        timeout: 4000,
      })
      return nextRolloutData
      
    } catch (error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Rollout could not be created",
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
      throw error
    }
  }
}

export const deleteRollouts = (ids=[]) => {
  ids = Array.isArray(ids) ? ids : [ ids ]
  return async (dispatch) => {
    try {
      const responses = ids.map(async d => {
        return deleteRolloutRequest(d)
      })
      await Promise.all(responses)
      dispatch(deleteRolloutsAction({ ids }))

      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Delete Successful",
        subtext: `Successfully deleted ${ids.length} rollout${ids.length !== 1 ? "s" : ""}`,
        type: MESSAGE_TYPE_SUCCESS,
        timeout: 4000
      })
    } catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: `${ids.length === 1 ? "Rollout" : "Some rollouts"} could not be deleted`,
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const actionRollout = async (rolloutName, action) => {
  switch (action){
  case "play":
    await playRolloutRequest(rolloutName)
    return async dispatch => {
      try {
        const response = await getRolloutRequest(rolloutName)
        const { data } = response
        dispatch(setRolloutAction({data}))
      }
      catch(error) {
        addMessage({
          target: GLOBAL_NOTIFICATIONS,
          text: "Rollout could not be started.",
          subtext: error.message,
          type: MESSAGE_TYPE_ERROR
        })
      }
    }

  case "pause":
    await pauseRolloutRequest(rolloutName)
    return async dispatch => {
      try {
        const response = await getRolloutRequest(rolloutName)
        const { data } = response
        dispatch(setRolloutAction({data}))
      }
      catch(error) {
        addMessage({
          target: GLOBAL_NOTIFICATIONS,
          text: "Rollout could not be paused.",
          subtext: error.message,
          type: MESSAGE_TYPE_ERROR
        })
      }
    }

  case "resume":
    await resumeRolloutRequest(rolloutName)
    return async dispatch => {
      try {
        const response = await getRolloutRequest(rolloutName)
        const { data } = response
        dispatch(setRolloutAction({data}))
      }
      catch(error) {
        addMessage({
          target: GLOBAL_NOTIFICATIONS,
          text: "Rollout could not be resumed.",
          subtext: error.message,
          type: MESSAGE_TYPE_ERROR
        })
      }
    }
    
  }
}


export const setPaging = ({ paging }) => {
  return dispatch => dispatch(setPagingAction({ paging }))
}