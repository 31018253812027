import { AUTHENTICATE, SET_ALTAIR_ONE_STATUS, SET_USER_INFO, UNAUTHENTICATE } from "actions/auth"

const initialState = {
  isAuthenticated: false,
  role: "",
  userInfo: {},
  altairOneStatus: {
    linked: false,
    expired: true,
  }
}

export function authentication(state = initialState, action) {
  switch (action.type) {
  case AUTHENTICATE:
    return {
      ...state, isAuthenticated: true, role: action.role
    }
  case UNAUTHENTICATE:
    return initialState
  case SET_USER_INFO:
    return {
      ...state,
      userInfo: action.userInfo
    }
  case SET_ALTAIR_ONE_STATUS:
    return {
      ...state,
      altairOneStatus: action.altairOneStatus
    }
  default:
    return state
  }
}
