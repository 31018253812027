import { DOCS_ROOT } from "constants/routes"

export const hardwareAgreement = "I confirm that the hardware I want to install Altair® IoT Edge Compute Platform onto is in working condition."

export const clusterAgreement = "I confirm that the asset I am connecting to Altair® IoT Studio™ is online, is running a supported operating system, and is running a supported version of Kubernetes."

export const stopBuildWarning = "This will stop the image build and it will need to be started over.\nDo you wish to continue?"

export const supportedPlatforms = ["When in doubt, please consult the list of ", "Supported Platforms", " for additional information on what does and does not work. Trying to install on an unsupported platform is likely to result in a poor experience."]

export const platformUrl = `${DOCS_ROOT}/topics/edge_compute/installation.htm`

export const documentationLink = ["Additional information, resources, and help about the installation process is available in the ", "Altair® IoT Studio™ documentation."]

export const documentationUrl = `${DOCS_ROOT}/index.htm`

export const connectingText = "Waiting for connection..."
export const connectedText = "Connection established."

export const NONE = "none"

export const buildInProgress = "Image Build in progress..."
export const downloadImage = "Download image and install:"

export const buildingMessage = "It is safe to close this panel. You will receive a notification when the build has finished. You can go to the gateway properties to check on the status of the build and download the finished image."

export const missingInstallInstructions = "There are no install instructions found. Please check that the asset isn't currently being worked on, and use the \"Recreate Setup\" action above to receive new instructions."


export const requestErrors = {
  get: {
    text: "Error fetching Assets",
    subtext: "Please try fetching Assets again."
  },
  post: {
    text: "Error creating the Asset",
    subtext: "Please try creating the Asset again."
  },
  put: {
    text: "Error updating the Asset",
    subtext: "Please try updating the Asset again."
  },
  delete: {
    one: {
      text: "Error deleting the Asset",
      subtext: "Please try deleting the Asset again."
    },
    many: {
      text: "Error deleting some Assets",
      subtext: "Please try deleting those Assets again."
    }
  }
}

export const clusterLabel = "Asset"
export const clusterLabelPlural = "Assets"

export const clusterSoftwareLabel = "Asset software status."

export const clusterListTitle = "Assets"
export const newClusterTitle = "New Asset"
export const newAssetPreInstallOSTitle = "New Asset: Pre-installed OS"
export const clusterDetailsLabel = "Details"
export const clusterDetailsOverviewLabel = "Overview"
export const clusterDetailsConfigurationLabel = "Applications"
export const clusterInstallationLabel = "Installation Type"
export const deleteClusterTitle = "Delete Asset"
export const deleteClusterTitlePlural = "Delete Assets"

export const newClusterButtonLabel = "New Asset"
export const refreshButtonLabel = "Refresh"
export const deleteButtonLabel = "Delete"

export const makeDeleteClusterText = name => `Are you sure you want to delete the "${name}" asset?`
export const makeDeleteClustersText = amount => `Are you sure you want to delete these ${amount} assets?`
export const makeRecreateClusterText = name => `Are you sure you want to recreate the setup files for the "${name}" asset?`

export const newEdgeAppVersion = "A newer version is available."

export const currentSoftwareLabel = "Installed Software:"
export const updateSoftwareLabel = "Asset Software update available:"
export const updateSoftwareButton = "Update Now"
export const updateSoftwareOngoingLabel = "Asset Software Update in progress:"
export const installingLabel = "Installing"

export const updateSoftwareModalTitle = "Asset will be unavailable during reboots"
export const updateSoftwareModalBody = "The Software update will take approximately 15 minutes, during which the asset will be rebooted. The asset will be unavailable when it is rebooting."

export const preInstalledOSDescription = "For standalone installation on a platform with pre-installed OS and supported version of Kubernetes with fleet management of Edge Applications: "
export const onBareMetalDescription = "For installation on bare metal; this will install the OS, the orchestration layer and enable fleet management of OS over-the-air updates as well as Edge Application updates: "
export const whatsNextModalText = "Access the Edge Applications section to add custom capabilites/software to your Assets"