/* === IMPORTS === */
import { createReducer } from "@reduxjs/toolkit"
import moment from "moment"

import { CLEAN_SPACE } from "actions/auth"
import { parseApiErrorMessage } from "utils/api"
import { makeLib } from "utils/misc"
import { getFunctionTopicParams, getMessageContent } from "utils/mqtt"
import {
  addMessage,
  GLOBAL_NOTIFICATIONS,
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS,
} from "utils/notifications"
import {
  createFunctionRequest,
  deleteFunctionRequest,
  getFunctionCodeRequest,
  getFunctionsRequest,
  getTemplateLibrariesRequest,
  invokeFunctionRequest,
  updateFunctionRequest
} from "utils/serverlessFunctions"

import { makeActions } from "./utiliducks"


/* == ACTIONS === */
const actionList = [
  "setFunctionsAction",
  "addFunctionAction",
  "setFunctionCodeAction",
  "updateFunctionAction",
  "deleteFunctionsAction",
  "updateFunctionStatusAction",
  "setFunctionsDateSearchAction",
  "setPagingAction",
]
const {
  setFunctionsAction,
  addFunctionAction,
  setFunctionCodeAction,
  updateFunctionAction,
  deleteFunctionsAction,
  updateFunctionStatusAction,
  setFunctionsDateSearchAction,
  setPagingAction,
} = makeActions("functions", actionList)

/* === INITIAL STATE === */
const initialState = {
  functions: [],
  functionsLib: {},
  codeLib: {},
  functionsDateSearch: moment().subtract(7, "d").format("YYYY-MM-DDTHH:mm:ss[Z]"),
  paging: {
    previous_cursor: "",
    next_cursor: ""
  },
}

/* === Reducer === */
export default createReducer(initialState, {
  [setFunctionsAction]: (state, { payload: { functions } }) => ({
    ...state,
    functions,
    functionsLib: makeLib({ data: functions, key: "name" }),
  }),
  [addFunctionAction]: (state, { payload: { functionData } }) => ({
    ...state,
    functions: [
      ...state.functions,
      functionData
    ],
    functionsLib: {
      ...state.functionsLib,
      [functionData.name]: functionData
    }
  }),
  [setFunctionCodeAction]: (state, { payload: { name, code } }) => ({
    ...state,
    codeLib: {
      ...state.codeLib,
      [name]: code
    }
  }),
  [setFunctionsDateSearchAction]: (state, { payload: { date } }) => {
    return ({
      ...state,
      functionsDateSearch: date
    })
  },
  [updateFunctionAction]: (state, { payload: { name, functionData } }) => ({
    ...state,
    functions: state.functions.map(func => func.name === name ? functionData : func),
    functionsLib: {
      ...state.functionsLib,
      [name]: {
        ...state.functionsLib[name], ...functionData
      }
    }
  }),
  [updateFunctionStatusAction]: (state, { payload: { name, status } }) => ({
    ...state,
    functions: state.functions.map(func => func.name === name ? {...func, status} : func),
    functionsLib: {
      ...state.functionsLib,
      [name]: {
        ...state.functionsLib[name],
        status
      }
    }
  }),
  [deleteFunctionsAction]: (state, { payload: { names } }) => {
    const functions = state.functions.filter(({ name }) => !names.includes(name))
    return {
      ...state,
      functions,
      functionsLib: makeLib({ data: functions, key: "name" })
    }
  },
  [setPagingAction]: (state, { payload: { paging } }) => ({
    ...state,
    paging
  }),
  [CLEAN_SPACE]: () => initialState
})

/* === DISPATCHERS === */
export const getFunctions = () => {
  return async (dispatch) => {
    try {
      const response = await getFunctionsRequest()
      const { data=[], paging } = response
      if (!Array.isArray(data)) throw new Error(`Invalid data format: ${JSON.stringify(data)}`)
      dispatch(setFunctionsAction({ functions: data }))
      if (paging) dispatch(setPaging({ paging }))
      return data
    }
    catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Serverless Functions could not be retrieved",
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
      return []
    }
  }
}

export const getNextFunctions = () => {
  return async (dispatch, getState) => {
    const {
      serverlessFunctions: {
        functions,
        paging: {
          next_cursor
        }={}
      }
    } = getState()
    const { data, paging } = await getFunctionsRequest({ next_cursor })
    dispatch(setPaging({ paging }))
    dispatch(setFunctionsAction({ functions: [ ...functions, ...data ]}))
  }
}

export const getFunctionCode = (name) => {
  return async (dispatch) => {
    try {
      const response = await getFunctionCodeRequest(name)
      const {
        code: apiCode,
        data: { // TODO: This is an ugly workaround relevant only to mocks. Remove this for prod
          code: mockedCode
        }={}
      } = response
      const code = apiCode || mockedCode
      if (!code) throw `No code available for function "${name}"`
      dispatch(setFunctionCodeAction({ name, code }))
      return code
    } catch (error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Could not retrieve Function code",
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
      return null
    }
  }
}

export const getTemplateLibraries = (template) => {
  return async () => {
    try {
      const response = await getTemplateLibrariesRequest(template)
      const { libraries } = response
      if (libraries.length > 0) return response
    } catch (error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Could not retrieve available libraries",
        type: MESSAGE_TYPE_ERROR
      })
      return null
    }
  }
}

export const createFunction = (functionData) => {
  return async (dispatch) => {
    try {
      const response = await createFunctionRequest(functionData)
      const newFunctionData = {
        ...response,
        status: response.status
      }
      dispatch(addFunctionAction({ functionData: newFunctionData }))
      return newFunctionData

    } catch (error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Function could not be created",
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
      return false
    }
  }
}

export const addFunctionToStore = (functionData) => {
  return async (dispatch) => {
    try {
      dispatch(addFunctionAction({ functionData: functionData }))
      return true
    }
    catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Failed to add function.",
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
      return false
    }
  }
}

export const updateFunction = (name, functionData) => {
  return async (dispatch) => {
    try {
      const response = await updateFunctionRequest(name, functionData) || {}
      const newFunctionData = {
        ...response,
        // The status is returned as "Running", though it always rebuilds
        // This will set the status to a more user friendly "Pending" when any change is made
        status: "Pending"
      }
      dispatch(updateFunctionAction({
        name,
        functionData: newFunctionData
      }))
      return newFunctionData

    } catch(error) {
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Function could not be updated",
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const deleteFunctions = (listOfNames=[], successMsg=true) => {
  const names = Array.isArray(listOfNames) ? listOfNames : [ listOfNames ]
  return async dispatch => {
    try {
      const functionsToRemove = []
      // TODO: we might need to pace request as we are doing with Things
      const responses = names.map(async name => {
        functionsToRemove.push(name)
        return deleteFunctionRequest(name)
      })
      await Promise.all(responses)
      dispatch(deleteFunctionsAction({ names: functionsToRemove }))
      
      if (functionsToRemove.length && successMsg) {
        addMessage({
          target: GLOBAL_NOTIFICATIONS,
          text: "Delete Successful",
          subtext: `Successfully deleted ${functionsToRemove.length} function${functionsToRemove.length !== 1 ? "s" : ""}`,
          type: MESSAGE_TYPE_SUCCESS,
          timeout: 4000
        })
        return true
      }
      else return false
    } catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: `${names.length === 1 ? "Function" : "Some Functions"} could not be deleted`,
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const setFunctionsDateSearch = (date) => {
  return dispatch => dispatch(setFunctionsDateSearchAction({ date }))
}

export const setPaging = ({ paging }) => {
  return dispatch => dispatch(setPagingAction({ paging }))
}

export function uploadFunctionCode() {
  return () => {
    // TODO: This will control uploading code in the upload modal
    return
  }
}

// TODO: If we don't need to store this in the store, this can be moved to utils.
// Wait on this, though, as there might be some telemetry or need for the response to be stored.
export function invokeFunction(functionName, body) {
  return async () => {
    try {
      const response = await invokeFunctionRequest(functionName, body)
      return response
    } catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Failed to invoke function",
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

// Handler for function status mqtt messages
// Updates function status attribute
export const onFuctionStatusMessage = (topic, message) => {
  return (dispatch) => {
    const match = getFunctionTopicParams(topic)
    if (!match) return

    const { functionId="" } = match
    if (functionId) {
      const { status="" } = getMessageContent(message) || {}
      if (status) dispatch(updateFunctionStatusAction({ name: functionId, status }))
    }
  }
}