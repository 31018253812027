import { PROXY_URL } from "constants/config"
import { del, get, post, put } from "utils/api"


export async function getSpacesRequest() {
  try {
    const response = await get("/spaces", null, { rootRequest: true })
    const {
      status,
      data: {
        collection
      }={}
    } = response
    if(status === 200) {
      return collection
    }
    else {
      return []
    }
  }
  catch (error) {
    console.error(error)
  }
}

export async function getUsageRequest(space, params) {
  try {
    const response = await get(`/spaces/${space}/usage`, params, { rootRequest: true })
    const {
      status,
      data
    } = response
    if(status === 200) {
      return data
    }
    else {
      return {}
    }
  }
  catch (error) {
    console.error(error)
  }
}

export async function createSpaceRequest(space) {
  const response = await post("/spaces", space, { rootRequest: true })
  const {status, data} = response

  if (status === 200) {
    return data
  }
}

export async function updateSpaceRequest(id, updatedSpace) {
  const response = await put(`/spaces/${id}`, updatedSpace, { rootRequest: true })
  const {status, data} = response

  if (status === 200) {
    return data
  }
}

//NOTE: This api endpoint is not working
export async function deleteSpaceRequest(id) {
  const response = await del(`/spaces/${id}`, null, {rootRequest: true})
  // if (response.status !== 204) throw response // delete space requests return null when successful, and an error object on failure
  if (response) throw response //TODO: replace this line with the one above when api response changes
  return response
}

export async function enterSpace(id) {
  return await get(`/boundaries/${id}/developer`, {}, { rootRequest: true })
}

export function getOffSpace(id) {
  fetch(`${PROXY_URL}/boundaries/${id}/developer/clear`, { credentials: "include", keepalive: true})
}

export async function keepDeveloperAlive(id) {
  return get(`/boundaries/${id}/developer/keep-alive`, {}, { rootRequest: true })
}

export async function leaveSpaceRequest(id) {
  return await del(`/spaces/${id}/leave`, null, { rootRequest: true })
}
