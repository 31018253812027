import React from "react"

const UnityIcon = ({ Component, color="#333", height=16, style={}, title="", width=16, id="" }) => {
  return (
    <Component
      height={height}
      id={id}
      title={title}
      width={width}
      style={{
        fill: color,
        ...style
      }}
    />
  )
}

export default UnityIcon
