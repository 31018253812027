import { del, get, patch, post, put } from "utils/api"

export async function getUsersRequest() {
  const response = await get("/users")
  const {
    status,
    data: {
      collection
    }={}
  } = response
  if(status === 200) {
    return collection
  }
  else {
    return []
  }
}

export async function deleteUserRequest(id) {
  const response = await del(`/users/${id}`)

  //TODO: This is temporarily commented out until api response changes
  // if (response.status !== 204) throw response // delete user requests return null when successful, and an error object on failure
  if (response) throw new Error(response) //TODO: replace this line with the one above when api response changes
  return response
}

export async function getSentInvitationsRequest() {
  const response = await get("/invitations")
  const {
    status,
    data: {
      collection
    }={}
  } = response
  if(status === 200) {
    return collection
  }
  else {
    return []
  }
}

export async function createInvitationRequest(invitation) {
  const response = await post("/invitations", invitation)
  const {
    status,
    data
  } = response || {}
  if(status === 200) {
    return data
  }
}

export async function updateInvitationRequest(id, invitation) {
  const response = await put(`/invitations/${id}`, invitation)
  const {
    status,
    data
  } = response || {}
  if(status === 200) {
    return data
  }
}

export async function deleteInvitationRequest(id) {
  const response = await del(`/invitations/${id}`)

  //TODO: This is temporarily commented out until api response changes
  // if (response.status !== 204) throw response // delete user requests return null when successful, and an error object on failure
  if (response) throw new Error(response) //TODO: replace this line with the one above when api response changes
  return response
}

export async function getReceivedInvitationsRequest() {
  const response = await get("/invitations", {}, { rootRequest: true })
  const {
    status,
    data: {
      collection
    }={}
  } = response
  if(status === 200) {
    return collection
  }
  else {
    return []
  }
}

export async function updateInvitationStatusRequest(id, body) {
  const response = await patch(`/invitations/${id}`, body, { rootRequest: true })
  const {
    status,
    data
  } = response
  if(status === 200) {
    return data
  }
  else {
    return []
  }
}
