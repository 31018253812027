import { del, get, post, put } from "utils/api"
import { customQueryRequest } from "utils/customQueries.js"
import mqttClient, { getMessageContent, getStatusTopicParams, syncSubscribe, syncUnsubscribe } from "utils/mqtt"
import { getSpace } from "utils/storage"
import { runActionRequest } from "utils/things"

export const SEND_KUBERNETES_API_EDGE_APPS = "/apis/swx/v1/edge-apps"
const SEND_KUBERNETES_API_APPLY_URL = "/apis/swx/v1/apply"
export const SEND_KUBERNETES_API_EVENT = "send-kubernetes-api"
const CORRELATION_ID_SPLIT_BY = "::"
export const CUSTOM_CONFIG = "custom-config"
export const CLUSTER_STATUS = {
  PENDING_CONNECTION: "Pending connection",
  REGISTERED: "Registered",
  CONNECTED: "Connected",
  SUBSCRIBED: "Subscribed",
  OFFLINE: "ClientOffline"
}
const SEND_API = "send-api"

const queryToGetFleets = {
  "query": "FOR t IN things FILTER 'cluster' IN t.`@type` FOR p_name IN ATTRIBUTES(t.status) FILTER p_name IN ['fleet', 'fleet_edge_apps'] RETURN DISTINCT t.status[p_name]",
  "count": true
}

export async function getClustersRequest(params) {
  const response = await get("/clusters", params, { beta: true })
  const {
    status,
    data={},
  } = response
  if(status === 200) {
    return data
  }
  else {
    return {}
  }
}

export async function postCluster(cluster) {
  let response
  try {
    response = await post("/clusters", cluster, { beta: true })
  }
  catch(error) {
    if (error.status === 403) throw Object.assign(new Error(error.message), { statusCode: 403 })
    throw new Error("API request failed", response)
  }
  const { status, data } = response || {}
  if (status === 200) return data
  throw new Error("API request failed", response)
}

// delete cluster requests return null when successful, and an error object on failure
export async function deleteCluster(id) {
  const response = await del(`/clusters/${id}`, null, { beta: true })

  //TODO: This is temporarily commented out until api response changes
  // if (response.status !== 204) throw response // delete thing requests return null when successful, and an error object on failure
  //TODO: replace this line with one above when api response changes
  if (response) throw response
  return response
}

export async function getFleetsRequest() {
  const response = await customQueryRequest(queryToGetFleets, false)
  if (response?.result.length > 0){
    return response.result
  }
}

export async function getClustersByFleetRequest(fleet) {
  const queryToGetClustersByFleet = {
    "query": "FOR t IN things FILTER 'cluster' IN t.`@type` FOR p_name IN ATTRIBUTES(t.status) FILTER p_name IN ['fleet', 'fleet_edge_apps'] FILTER t.status[p_name] == @fleet_identifier RETURN DISTINCT t",
    "bindVars": {
      "fleet_identifier": fleet
    }
  }
  const response = await customQueryRequest(queryToGetClustersByFleet, false)
  if (response?.result.length > 0){
    return response.result
  }
}

export async function reinstallRequest(id, client_id, client_secret, fleet) {
  let response
  const body = {
    ota_edge_apps: {}
  }

  let hasBody = false
  if (fleet) {
    body.ota_edge_apps.fleet = fleet
    hasBody = true
  }
  if(client_id && client_secret){
    body.ota_edge_apps.client_id = client_id,
    body.ota_edge_apps.client_secret = client_secret
    hasBody = true
  }

  if (hasBody) response = await put(`/clusters/${id}/reinstall`, body, { beta: true })
  else response = await put(`/clusters/${id}/reinstall`, null, { beta: true })

  const { status, data } = response
  if (status === 200) {
    return data
  }
}

export async function resetSecretRequest(id) {
  const response = await post(`/clusters/${id}/reset-secret`, null, { beta: true })
  const {
    status,
    data
  } = response || {}
  if (status === 200) return data
  throw new Error("API request failed", response)
}


//NOTE: assuming actions list is most recent first (how api orders them by default)
export const findLastApplyAction = (actions) => {
  return actions.find(a => {
    // const {
    //   'send-kubectl': {
    //     input: {
    //       arguments: [firstArg]=[]
    //     }={}
    //   }={}} = a || {}
    //   return firstArg === 'apply'
    const {
      [SEND_KUBERNETES_API_EVENT]: {
        input: {
          correlationId,
          requestMethod,
          href,
          requestBody: url
        }={},
      }={}
    } = a || {}

    return requestMethod === "PUT" &&
      href === SEND_KUBERNETES_API_APPLY_URL &&
      !!url &&
      !!correlationId
  })
}

export const getConfigDataFromAction = (applyAction) => {
  const {
    [SEND_KUBERNETES_API_EVENT]: {
      status,
      input: {
        correlationId,
        requestBody: url
      }={},
    }={}
  } = applyAction || {}

  return {
    url,
    correlationId,
    status,
    edgeAppId: getEdgeAppIdFromCorrelationId(correlationId)
  }
}

export const getEdgeAppIdFromCorrelationId = (correlationId="") => {
  //Assume correlationId format: {edgeAppID}::{timestamp}
  //NOTE: if custom build config was applied, 'custom-config' will be returned
  const correlationSplit = correlationId.split(CORRELATION_ID_SPLIT_BY)

  if (correlationSplit.length === 2) {
    return correlationSplit[0]
  }
}

export const generateCorrelationId = edgeAppId =>
  edgeAppId ? `${edgeAppId}::${Date.now()}` : `${Date.now()}`

export const getMasterNode = (nodes=[]) => {
  if (nodes.length === 1) {
    return nodes[0]
  } else {
    //This may not be applicable anymore
    return nodes.find(({ name="" }) => /server-0$/.test(name))
  }
}

//Argument can be single node or array of nodes
//returns object with timestamp as key
export const formatStatusDataFromNode = nodes => {
  //Format input as an Array of nodes
  nodes = Array.isArray(nodes) ? nodes : [nodes]
  const statusData = nodes.map(({
    stats: {
      timestamp,
      cpuPercentage,
      memoryPercentage,
      window: interval
    }={},
    conditions,
    capacities,
    deployments
  }) => {
    const msTimestamp = timestamp * 1000
    return {
      timestamp: msTimestamp,
      stats: {
        cpuPercentage,
        memoryPercentage,
        interval
      },
      capacities,
      conditions,
      deployments
    }
  }).reduce((dataMap, datum) => {
    return {
      ...dataMap,
      [datum.timestamp]: datum
    }
  }, {})

  return statusData
}

//Returns boolean value based on cluster status
export const isClusterConnected = (status="") => {
  return /(Connected)|(Registered)|(Subscribed)|(ClientWakeUp)/i.test(status)
}


export const getClusterUid = (id="") => {
  const [, uid] = id.match(/\/([0-9A-Z]+)$/)

  return uid
}


//TODO: check this function to adapt to new clusters beta and read from proper event metrics.
export async function sendApiRequest({ clusterId, action=SEND_API, attempts=0, ...actionInput }) {
  if (!clusterId) return
  const category = "cluster"
  const correlationId = generateCorrelationId("request")
  const actionBody = {
    [action]: {
      input: {
        ...actionInput,
        correlationId
      }
    }
  }

  return new Promise((resolve, reject) => {
    (async () => {
      const requestTimeout = 10000
      const spaceId = getSpace()
      const topics = [`spaces/${spaceId}/categories/${category}/things/${clusterId}/events/${action}`]

      // Check if mqtt client is connected
      // If not, retry sendApiRequest in a few seconds
      // Try a maximum of 5 times
      if (!mqttClient?.client?.connected && attempts < 5) {
        console.log("mqtt client not connected, waiting to retry sendApiRequest")
        setTimeout(() => {
          console.log("retrying sendApiRequest after timeout")
          return resolve(sendApiRequest({clusterId, action, attempts: attempts + 1, ...actionInput}))
        }, 2000)
        return
      }

      await syncSubscribe(topics, handleSendApiMsg)

      // sometimes response comes as MQTT message. Start timeout and wait for it
      const failureTimeout = setTimeout(async () => {
        await syncUnsubscribe(topics, handleSendApiMsg)
        return reject({description: `${action} timeout error`, clusterId})
      }, requestTimeout)

      const actionResp = await runActionRequest(clusterId, actionBody, category)
      const {
        [action]: {
          data: {
            response
          }={}
        }={}
      } = actionResp

      if (response) { // sometimes response comes back in the initial request
        clearTimeout(failureTimeout)
        return resolve(response)
      }

      //TODO: if both action and event topics need to be subscribed to, create separate messageHandlers
      async function handleSendApiMsg(topics, message) {
        const messageContent = getMessageContent(message)
        //NOTE: Action messages indicate status of request (pending, complete)
        //      Event messages return the response

        const {
          [action]: {
            data: {
              correlationId: msgCorrelationId,
              response,
              statusCode,
              statusMessage
            }={}
          }
        } = messageContent

        const respBody = { response, statusCode, statusMessage } // Send this object to get statusMessage for status codes different from 200, 201 and 204 (errors).

        if (msgCorrelationId === correlationId) {
          clearTimeout(failureTimeout)
          await syncUnsubscribe(topics, handleSendApiMsg)
          return resolve(respBody)
        }
      }
    })()
  })
}

// takes the properties of a thing MQTT message and organizes it for use
// in the tracking of edgeApp application status
export const processClusterEventMessage = ({ topic, message, thingId }) => {
  const topicParams = getStatusTopicParams(topic)
  const messageContent = getMessageContent(message) || {}

  if (!topicParams || !messageContent) {
    console.warn("Malformed mqtt message: ", event)
    return
  }

  const { thingUlid="", propertyId=[] } = topicParams
  const { [SEND_KUBERNETES_API_EVENT]: { data: messageData }={} } = messageContent

  // check that it's the right event and item, just in case
  if (thingUlid !== thingId || propertyId[0] !== SEND_KUBERNETES_API_EVENT || !messageData) return {}

  return messageData
}
