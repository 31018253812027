/* === IMPORTS === */
import { createReducer } from "@reduxjs/toolkit"

import { CLEAN_SPACE } from "actions/auth"
import { makeLib } from "utils/misc"
import {
  createModelRequest,
  createModelVersionRequest,
  deleteModelRequest,
  EMPTY_VERSION_SCHEMA,
  getModelsRequest,
  getModelVersionRequest,
  getModelVersionsRequest,
  updateModelRequest
} from "utils/modelsBeta"
import {
  addMessage,
  GLOBAL_NOTIFICATIONS,
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS
} from "utils/notifications"

import { makeActions } from "./utiliducks"


/* == ACTIONS === */
const actionList = [
  "setCategoryModelAction",
  "setThingModelAction",
  "addModelAction",
  "setModelsAction",
  "updateModelAction",
  "removeModelsAction",
  "setModelVersionsAction",
  "addModelVersionAction",
  "updateNewVersionTemplateAction",
  "clearNewVersionTemplateAction"
]
const {
  setCategoryModelAction,
  setThingModelAction,
  setModelsAction,
  addModelAction,
  updateModelAction,
  removeModelsAction,
  setModelVersionsAction,
  addModelVersionAction,
  updateNewVersionTemplateAction,
  clearNewVersionTemplateAction
} = makeActions("modelsBeta", actionList)

/* === INITIAL STATE === */
const initialState = {
  categoryModel: {},
  thingModel: {},
  models: [],
  modelsLib: {},
  modelVersions: [],
  modelVersionsLib: {},
  newVersionTemplate: EMPTY_VERSION_SCHEMA
}

/* === Reducer === */
export default createReducer(initialState, {
  [setCategoryModelAction]: (state, { payload: { model }}={}) => {
    state.categoryModel = model
  },
  [setThingModelAction]: (state, { payload: { model }}={}) => {
    state.thingModel = model
  },
  [setModelsAction]: (state, { payload: { models }}={}) => ({
    ...state,
    models,
    modelsLib: makeLib({data: models, key: "name"})
  }),
  [addModelAction]: (state, { payload: { model }}) => ({
    ...state,
    models: [
      ...state.models,
      model
    ],
    modelsLib: {
      ...state.modelsLib,
      [model.name]: model
    }
  }),
  [updateModelAction]: (state, { payload: { name, model: updatedModel }}) => {
    const {[name]: deletedModel, ...newModelsLib} = state.modelsLib
    return ({
      ...state,
      models: state.models.map(model => model.name === name? updatedModel : model),
      modelsLib: {
        ...newModelsLib,
        [updatedModel.name]: updatedModel
      }
    })
  },
  [removeModelsAction]: (state, { payload: { names }}) => {
    const models = state.models.filter(model => !names.includes(model.name))
    return (
      {
        ...state,
        models,
        modelsLib: makeLib({data: models, key: "name"})
      })
  },
  [setModelVersionsAction]: (state, { payload: { modelVersions }}={}) => ({
    ...state,
    modelVersions,
    modelVersionsLib: makeLib({data: modelVersions, key: "version"})
  }),
  [addModelVersionAction]: (state, { payload: {version}}) => ({
    ...state,
    modelVersions: [version, ...state.modelVersions],
    modelVersionsLib: {
      ...state.modelVersionsLib,
      [version.version]: version
    }
  }),
  [updateNewVersionTemplateAction]: (state, { payload: newTemplate }) => ({
    ...state,
    newVersionTemplate: newTemplate
  }),
  [clearNewVersionTemplateAction]: (state) => ({
    ...state,
    newVersionTemplate: EMPTY_VERSION_SCHEMA
  }),
  [CLEAN_SPACE]: () => initialState
})

/* === DISPATCHERS === */
export const getModels = () => {
  return async dispatch => {
    try {
      const response = await getModelsRequest()
      const { data: models } = response
      dispatch(setModelsAction({models}))
      return models
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Models could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const createModel = (model) => {
  return async dispatch => {
    try {
      const newModel = await createModelRequest(model)
      dispatch(addModelAction({ model: newModel }))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Model was created successfully",
        type: MESSAGE_TYPE_SUCCESS,
      })
      return true
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Model could not be created",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
      return false
    }
  }
}

export const updateModel = (modelName, model) => {
  return async dispatch => {
    try {
      const updatedModel = await updateModelRequest(modelName, model)
      return dispatch(updateModelAction({ modelName, model: updatedModel }))
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Model could not be updated",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const deleteModels = (names) => {
  return async dispatch => {
    try {
      // TODO: we might need to pace request as we are doing with Things
      const responses = names.map(async name => {
        return deleteModelRequest(name)
      })
      await Promise.all(responses)
      dispatch(removeModelsAction({names}))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Delete Complete",
        subtext: `${names.length === 1 ? "Model was": `${names.length} models were`} deleted successfully`,
        type: MESSAGE_TYPE_SUCCESS
      })
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Model could not be deleted",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}


export const MODEL_TYPE_CATEGORY = "MODEL_TYPE_CATEGORY"
export const MODEL_TYPE_THING = "MODEL_TYPE_THING"

export const getModelVersion = (model, version, type=MODEL_TYPE_THING) => {
  return async dispatch => {
    try {
      const modelVersion = await getModelVersionRequest(model, version)
      switch (type) {
      case MODEL_TYPE_CATEGORY:
        dispatch(setCategoryModelAction({ model: modelVersion }))
        break
      case MODEL_TYPE_THING:
        dispatch(setThingModelAction({ model: modelVersion }))
        break
      default:
        console.warn("Invalid model type in getModelVersion function")
        break
      }
      return modelVersion
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Model versions could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const resetStoredModel = (type=MODEL_TYPE_THING) => {
  return dispatch => {
    switch (type) {
    case MODEL_TYPE_CATEGORY:
      dispatch(setCategoryModelAction({ model: {} }))
      break
    case MODEL_TYPE_THING:
      dispatch(setThingModelAction({ model: {} }))
      break
    default:
      console.warn("Invalid model type in resetStoredModel function")
      break
    }
  }
}

export const getModelVersions = (model) => {
  return async dispatch => {
    try {
      const response = await getModelVersionsRequest(model)
      const { data: modelVersions } = response
      dispatch(setModelVersionsAction({ model, modelVersions }))
      return modelVersions
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Model versions could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const createModelVersion = (model, template, showNotification = true) => {
  return async dispatch => {
    try {
      const newVersion = await createModelVersionRequest(model, template)
      dispatch(addModelVersionAction({ model, version: newVersion }))
      const { version } = newVersion
      showNotification && addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Model version created succesfully",
        subtext: `Created version ${version} for Model "${model}"`,
        type: MESSAGE_TYPE_SUCCESS
      })
      return version
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Model could not be created",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
      return false
    }
  }
}

export const clearModelVersions = () => {
  return dispatch => {
    return dispatch(setModelVersionsAction({modelVersions: []}))
  }
}

export const updateNewVersionTemplate = (newTemplateStructure) => (dispatch) => dispatch(updateNewVersionTemplateAction(newTemplateStructure))

export const clearNewVersionTemplate = () => (dispatch) => dispatch(clearNewVersionTemplateAction())
/* === UTILS === */
