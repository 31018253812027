export const UPDATE_HISTORY_EVENT = "UPDATE_HISTORY_EVENT"

export const boolDropdownOptions = [
  { label: "true", id: "true" },
  { label: "false", id: "false" },
]

export const emptySchema = {
  title: "",
  description: "",
  properties: {},
  actions: {},
  events: {},
  links: []
}

export const ADD_LABEL = "add"
export const REMOVE_LABEL = "remove"

export const ASCENDING = "Ascending"
export const DESCENDING = "Descending"

export const makeSchemaFormName = ({uid}) => `schema-${uid}`

// PANES
export const MAIN_PANE = "main-pane"
export const FIRST_PANE = "first-pane"
export const LAST_PANE = "last-pane"

export const SCHEMA_PANE = "editSchema"
export const ADDING_THINGS_PANE = "addingThings"
export const PROPERTIES_HISTORY_PANE = "propertiesHistory"

// TABS
export const TAB_DETAILS = "Details"
export const TAB_INTERFACES = "Interfaces"

export const EVERYTHING_TABLE_NAME = "everything-table"
export const EVERYTHING_CATEGORY_OPTION = "every-thing"
export const EVERYTHING_CATEGORY_LABEL = "Every Thing"
