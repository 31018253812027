import { ConfigProvider } from "antd"
import { ConnectedRouter } from "connected-react-router"
import PropTypes from "prop-types"
import React from "react"
import { Provider } from "react-redux"
import Routes from "routes"

import { antDMainTheme } from "assets/stylesheets/antDTheme"
import { unityVars } from "assets/stylesheets/common"


const Root = ({ store, history }) => (
  <ConfigProvider theme={antDMainTheme}>
    <Provider store={store} key="provider">
      <ConnectedRouter history={history}>
        <div style={{...unityVars, ...styles.container}}>
          <Routes/>
        </div>
      </ConnectedRouter>
    </Provider>
  </ConfigProvider>
)

Root.propTypes = {
  store: PropTypes.object.isRequired
}

export default Root

const styles = {
  container: {
    display: "flex",
    flex: 1,
    maxWidth: "100vw"
  }
}
