/* === IMPORTS === */
import { createReducer } from "@reduxjs/toolkit"

import { CLEAN_SPACE } from "actions/auth"
import {
  createCredentialsRequest,
  deleteCredentialsRequest,
  getCredentialsRequest,
  updateCredentialsRequest }
  from "utils/mqttCredentials"
import {
  addMessage,
  GLOBAL_NOTIFICATIONS,
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS
} from "utils/notifications"

import { makeActions } from "./utiliducks"

/* == ACTIONS === */
const actionList = [
  "setCredentialsAction",
  "addCredentialsAction",
  "updateCredentialsAction",
  "removeCredentialsAction",
]
const {
  setCredentialsAction,
  addCredentialsAction,
  updateCredentialsAction,
  removeCredentialsAction,
} = makeActions("mqttCredentials", actionList)

/* === INITIAL STATE === */
const initialState = {
  credentials: {}
}

/* === Reducer === */
export default createReducer(initialState, {
  [setCredentialsAction]: (state, { payload: { credentials, entityId, entityType }}={}) => ({
    ...state,
    credentials: {
      ...state.credentials,
      [entityType]: {
        ...state.credentials.entityType,
        [entityId]: credentials
      }
    }
  }),
  [addCredentialsAction]: (state, { payload: { credentials, entityId, entityType }}={}) => ({
    ...state,
    credentials: {
      ...state.credentials,
      [entityType]: {
        ...state.credentials.entityType,
        [entityId]: credentials
      }
    },
  }),
  [updateCredentialsAction]: (state, { payload: { credentials, entityId, entityType }}={}) => ({
    ...state,
    credentials: {
      ...state.credentials,
      [entityType]: {
        ...state.credentials.entityType,
        [entityId]: credentials
      }
    },
  }),
  [removeCredentialsAction]: (state, { payload: { entityId, entityType }}) => {
    const currentCredentials = state.credentials[entityType]
    const { [entityId]: deletedCredentials, ...newCredentials } = currentCredentials
    return {
      ...state,
      credentials: {
        ...state.credentials,
        [entityType]: newCredentials
      }
    }
  },
  [CLEAN_SPACE]: () => initialState
})

/* === DISPATCHERS === */
export const getCredentials = (entityId, entityType, collection) => {
  return async (dispatch) => {
    try {
      const credentials = await getCredentialsRequest(entityId, entityType, collection)
      dispatch(setCredentialsAction({credentials, entityId, entityType }))
      return credentials
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      // Don't display error message to avoid many error toasts navigating through things with no credentials configured.
    }
  }
}


export const createCredentials = (body, entityId, entityType, collection) => {
  return async (dispatch) => {
    try {
      const response = await createCredentialsRequest(body, entityId, entityType, collection)
      dispatch(addCredentialsAction({credentials: response, entityId, entityType}))

      addMessage({
        id: "mqtt-credentials-create-notification-success",
        target: GLOBAL_NOTIFICATIONS,
        text: "MQTT credentials created",
        type: MESSAGE_TYPE_SUCCESS,
        timeout: 4000
      })
      
      return response

    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        id: "mqtt-credentials-create-notification-error",
        target: GLOBAL_NOTIFICATIONS,
        text: "MQTT credentials could not be created",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
      return false
    }
  }
}


export const updateCredentials = (body, entityId, entityType, collection) => {
  return async dispatch => {
    try {

      const response = await updateCredentialsRequest(body, entityId, entityType, collection )
      const { password, ...credentials} = response

      dispatch(updateCredentialsAction({credentials: response, entityId, entityType}))

      addMessage({
        id: "mqtt-credentials-update-notification-success",
        target: GLOBAL_NOTIFICATIONS,
        text: "MQTT credentials updated",
        type: MESSAGE_TYPE_SUCCESS,
        timeout: 4000
      })

      return credentials
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        id: "mqtt-credentials-update-notification-error",
        target: GLOBAL_NOTIFICATIONS,
        text: "MQTT credentials could not be updated",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}


export const deleteCredentials = (entityId, entityType, collection) => {
  return async dispatch => {
    try {
      await deleteCredentialsRequest(entityId, entityType, collection)
      dispatch(removeCredentialsAction({entityId, entityType}))

      addMessage({
        id: "mqtt-credentials-delete-notification-success",
        target: GLOBAL_NOTIFICATIONS,
        text: "MQTT credentials deleted",
        type: MESSAGE_TYPE_SUCCESS,
        timeout: 4000
      })

    }
    catch(error) {
      console.error({[error.name]: { status: error.status, error: error.error, message: error.message }})
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "MQTT credentials could not be deleted",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}
