import { colors } from "./common"

export const antDMainTheme = {
  token: {
    fontFamily: "Noto",
    fontSize: "12px",
    fontSizeLG: "16px",
    lineHeight: "16px",
    lineHeightLG: "24px",
    colorPrimary: colors.skyBlue,
    colorFillAlter: colors.skyBlueTint1,
    colorText: colors.charcoal,
    colorError: colors.redOrange,
    fontSizeHeading1: "20px",
    fontSizeHeading2: "16px",
    fontSizeHeading3: "14px",
    fontSizeHeading4: "12px",
    // links
    colorLink: colors["blue-01-825"],
    colorLinkActive: colors["blue-01-550"],
    colorLinkHover: colors["blue-01-550"],
    linkDecoration: "underline",
    linkHoverDecoration: "underline",
    motionDurationSlow: 0
  },
  components: {
    Table: {
    },
    Layout: {
      bodyBg: "transparent",
    },
    Statistic: {
      contentFontSize: "24px",
      lineHeight: "28px",
      titleFontSize: "24px",
    },
    Breadcrumb: {
      linkColor: colors.charcoal
    },
    Select: {
      controlItemBgHover: "#f0f",
      paddingSM: 5,
      controlOutline: "none",
    }
  }
}