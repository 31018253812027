import { capitalize } from "lodash"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { cleanSpace } from "actions/auth"
import { navigate } from "actions/navigate"
import Spinner from "components/common/Spinner"
import { BOUNDARIES_ENABLED } from "constants/featureFlags"
import {
  AU_MANAGEMENT,
  ROOT,
  SPACE_PICKER
} from "constants/routes"
import { getLabels } from "reduxModules/labels"
import { getSpaces } from "reduxModules/spaces"
import {isAdmin, isSuperadmin} from "utils/admin"
import { authDirect } from "utils/api"
import { getSpaceId } from "utils/auth"
import { restartMqtt } from "utils/mqtt"
import { addMessage, MESSAGE_TYPE_ERROR } from "utils/notifications"
import { routes } from "utils/routes"
import { enterSpace, getOffSpace, keepDeveloperAlive } from "utils/spaces"
import { clearLastRoute, getLastRoute } from "utils/storage"


const KEEP_ALIVE_TIMEOUT = 300000

const stateToProps = ({
  spaces: {
    spacesLib={}
  }={},
  authentication: {
    userInfo: {
      swxrole
    }
  },
  edgeOrchestration: {
    trackingBuilds
  }={},
  login
}) => ({
  login,
  swxrole,
  spacesLib,
  trackingBuilds
})


//Purpose of this component: Redirect to correct route if user is unauthorized or they do not have a valid Space selected.
class AppSpace extends React.Component {

  state = {
    intervalId: 0,
    loading: true,
    spaceId: getSpaceId()
  }

  async componentDidMount() {
    this.appLoad()
  }

  async appLoad() {
    const { navigate, setAuthorized } = this.props
    const { spaceId } = this.state
    this.topicSubscriptions = []
    this.redirectIfUnauthorized()
    const redirect = await this.redirectIfNoSavedSpace()
    if(!redirect) {
      try {
        if (BOUNDARIES_ENABLED) {
          await enterSpace(spaceId)
          window.addEventListener("visibilitychange", this.visibilityChangeCallback)
          window.addEventListener("beforeunload", this.beforeUnloadCallback)
          const intervalId = setInterval(() => keepDeveloperAlive(spaceId), KEEP_ALIVE_TIMEOUT)
          this.setState({ intervalId })
        }
        setAuthorized(true)
        restartMqtt() // this is temporary. We will have to check authorization for mqtt before giving mqtt access to new space

        //Redirect to last route if it exists
        this.initializeApp()

        //Redirect to last route if it exists
        this.redirectToLastRoute()
      }
      catch (error) {
        const { message: { error: errorMessage="" }={} } = error
        addMessage({
          id: "space-unauthorized-notification",
          subtext: capitalize(errorMessage),
          text: `Error entering space "${this.state.spaceId}"`,
          timeout: 0,
          type: MESSAGE_TYPE_ERROR
        })
        clearLastRoute()
        setAuthorized(false)
        navigate({ to: routes[AU_MANAGEMENT] })
      }
    }
    this.setState({ loading: false })
  }

  componentDidUpdate() {
    const currentSpace = getSpaceId()
    const { spaceId } = this.state
    if(spaceId && (spaceId !== currentSpace)) this.setState({spaceId: currentSpace})
  }

  async componentWillUnmount() {
    //Clear all space data from store
    const { cleanSpace, setAuthorized } = this.props
    cleanSpace()
    if (BOUNDARIES_ENABLED){
      clearInterval(this.state.intervalId)
      window.removeEventListener("visibilitychange", this.visibilityChangeCallback)
      window.removeEventListener("beforeunload", this.beforeUnloadCallback)
      if(this.props.authorized) {
        getOffSpace(this.state.spaceId)
      }
    }
    setAuthorized(false)
  }

  visibilityChangeCallback = () => {
    const { authorized } = this.props
    if(authorized && document.hidden) {
      const { intervalId, spaceId } = this.state
      getOffSpace(spaceId)
      clearInterval(intervalId)
    }
    else if(!document.hidden) {
      setTimeout(() => this.appLoad(), 250)
    }
  }

  beforeUnloadCallback = (e) => {
    e.preventDefault()
    if (this.props.authorized) getOffSpace(this.state.spaceId)
  }

  initializeApp = () => {
    this.props.getLabels()
  }

  redirectIfUnauthorized = () => {
    const {
      login: {
        unauthorized=false // should default to true,
      }={},
      location: {
        pathname: path
      }={},
      navigate,
    } = this.props

    // checks if the user is unath, throws them back to root if so
    authDirect({
      path,
      safe: [routes[ROOT]],
      authorized: !unauthorized,
      fail: url => navigate({to: url})
    })

  }

  //redirect to Space Picker page if no spaceId is saved in browser localstorage
  redirectIfNoSavedSpace = async () => {
    const { navigate, getSpaces, swxrole } = this.props
    const { spaceId } = this.state
    let redirect = false
    if (!spaceId) {
      navigate({to: routes[SPACE_PICKER]})
      redirect = true
    } else {
      if (!isSuperadmin(swxrole) && !isAdmin(swxrole)) {
        // Get spaces, check if spaceId exists in list, redirect to Space Picker if not
        await getSpaces()
        const {
          [spaceId]: space
        } = this.props.spacesLib || {}
        if (!space) {
          navigate({ to: routes[SPACE_PICKER] })
          redirect = true
        }
      }
    }
    return redirect
  }

  redirectToLastRoute = () => {
    const lastRoute = getLastRoute()
    if (lastRoute) {
      clearLastRoute()
      this.props.navigate({ to: lastRoute })
    }
  }

  render() {
    const {
      props: {
        children
      }={},
      state: {
        loading,
        spaceId
      }={}
    } = this

    if (spaceId && !loading) {
      return React.Children.map(children, child => {
        return React.cloneElement(child, { key: spaceId })
      })
    } else {
      return <Spinner size="large"/>
    }
  }
}

export default connect(stateToProps, {
  navigate,
  getSpaces,
  getLabels,
  cleanSpace
})(withRouter(AppSpace))
