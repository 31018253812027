import React, { Component } from "react"
import { withRouter } from "react-router-dom"
// import PropTypes from 'prop-types'

class App extends Component {
  render() {
    const {
      children
    } = this.props

    return (
      <div style={{
        height: "100%",
        width: "100%",
        display: "flex"
      }}>
        <div style={{
          display: "flex",
          flex: 1,
          zIndex: 1
        }}>
          { children }
        </div>
        {!children &&
          <div
            style={{
              zIndex: 0,
              position: "absolute",
              width: "100%",
              marginTop: "49vh",
              textAlign: "center"
            }}
          >
            Welcome to Altair® IoT Studio™!
          </div>
        }
      </div>
    )
  }
}

export default withRouter(App)

// App.propTypes = {}
