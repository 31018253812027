import { del, get, post, put } from "utils/api"

// REQUESTS

export async function getRolesRequest(params={}) {
  const { data } = await get("/roles", params)
  return data
}

export async function createRoleRequest(role) {
  const { data } = await post("/roles", role)
  return data
}

export async function updateRoleRequest(id, role) {
  const { data } = await put(`/roles/${id}`, role)
  return data
}

export async function updateRolesRequest(roles) {
  const { data } = await put("/roles", roles)
  return data
}

export async function deleteRoleRequest(id) {
  return await del(`/roles/${id}`)
}

export async function deleteRolesRequest(params) {
  const { data } = await del("/roles", params)
  return data
}


// FORMATTING

export const formatName = value => value.replace(/^role::/, "")

export const normalizeName = (value) => `role::${value}`

export const filterDummySubjects = subjects => subjects.filter(({subject}) => subject !== "DUMMY_SUBJECT")

export const makeRoleFormName = id => `role-${id}`


//FUNCTIONS

export async function deleteMultipleRoles(ids) {
  return await deleteRolesRequest({ "roleID[]": ids })
}
