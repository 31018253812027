import { LazyLog, ScrollFollow } from "controlled-lazylog"
import React, {useEffect, useState} from "react"
import { useSelector } from "react-redux"

import {
  getFunctionLogsRequest
} from "utils/serverlessFunctions"
import {
  NEW_FUNCTION_LOG,
  REFRESH_FUNCTION_LOG,
  TOGGLE_FUNCTION_LOG_SEARCH_FILTER,
  UPDATE_FUNCTION_LOG_SEARCH
} from "utils/utilityBelt"


const FunctionLogs = ({functionName}) => {
  const [logs, setLogs] = useState([])
  const [searchText, setSearchText] = useState("")
  const [activeFilter, setActiveFilter] = useState(false)
  const functionsDateSearch = state => state.serverlessFunctions.functionsDateSearch

  const dateSearch = useSelector(functionsDateSearch)

  useEffect(() => {
    //Do not fetch function logs if opened up empty logs panel
    if (functionName !== NEW_FUNCTION_LOG) {
      getLogs()
    }
  }, [functionName, dateSearch])


  useEffect(() => {
    document.addEventListener(`${REFRESH_FUNCTION_LOG}-${functionName}`, getLogs)
    document.addEventListener(`${UPDATE_FUNCTION_LOG_SEARCH}-${functionName}`, handleSearch)
    document.addEventListener(`${TOGGLE_FUNCTION_LOG_SEARCH_FILTER}-${functionName}`, handleFilterToggle)

    return function cleanup() {
      document.removeEventListener(`${REFRESH_FUNCTION_LOG}-${functionName}`, getLogs)
      document.removeEventListener(`${UPDATE_FUNCTION_LOG_SEARCH}-${functionName}`, handleSearch)
      document.removeEventListener(`${TOGGLE_FUNCTION_LOG_SEARCH_FILTER}-${functionName}`, handleFilterToggle)
    }
  })


  const getLogs = async () => {
    //Fetch last week of logs
    try {
      const logs = await getFunctionLogsRequest(functionName, 0, dateSearch)
      setLogs(logs.collection)
    } catch(e) {
      console.error("Error fetching function logs: ", e)
    }
  }

  const handleSearch = ({detail: {searchText=""}={}}) => {
    setSearchText(searchText)
  }

  const handleFilterToggle = ({detail: {isFiltering=!activeFilter}={}}) => {
    setActiveFilter(isFiltering)
  }

  const logsText = logs.map(({text="\n"}) => text).join("")

  return (
    <div style={{flex: 1, height: "100%"}}>
      <ScrollFollow
        startFollowing={true}
        render={({ follow, onScroll }) => {
          if (logs.length === 0) return null

          return (<LazyLog
            text={logsText}
            searchText={searchText}
            filterActive={activeFilter}
            follow={follow}
            onScroll={onScroll}
            selectableLines={true}
          />)
        }}
      />
    </div>
  )
}

export default FunctionLogs