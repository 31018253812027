/* === IMPORTS === */
import { createReducer } from "@reduxjs/toolkit"
import cloneDeep from "lodash/cloneDeep"

import { getCategoriesRequest } from "utils/categoriesBeta"
import { getModelsRequest, getModelVersionsRequest } from "utils/modelsBeta"

import { makeActions } from "./utiliducks"


/* == ACTIONS === */
const actionList = [
  "resetAction",
  "initializeAction",
  "setSelectedCategoriesAction",
  "setStateAction",
]
const {
  resetAction,
  initializeAction,
  setSelectedCategoriesAction,
  setStateAction,
} = makeActions("editSchema", actionList)

/* === INITIAL STATE === */
export const initialSchema = {
  "categories": [],
  "actions": {},
  "description": "",
  "events": {},
  "links": [],
  "properties": {},
  "title": ""
}

const initialState = {
  schema: initialSchema,
  selectedCategories: [],
  selectedModel: null,
  selectedVersion: null,
  isModelDisabled: false,
  isVersionDisabled: true,
  categories: [],
  models: [],
  versions: [],
  categoriesOptions: [],
  modelOptions: [],
  versionsOptions: [],
}

const getCategoriesOptions = (categories = []) => categories.map(({ name, model }) => {
  const modelName = model?.name ?? "No model"
  const modelVersion = model?.name ? (model?.version ? `| v${model.version}` : "| No version") : ""
    
  return { label: `${name} - ${modelName} ${modelVersion}`, id: name }
})
  
const getModelOptions = (models = []) => {
  const opts = models.map(({name}) => ({
    label: name,
    id: name
  }))
  
  return [{label: "None", id: ""}, ...opts]
}

const getModelVersionOptions = (modelVersions = []) => {
  return modelVersions.map(({
    version,
    title,
  }) => ({
    label: `version ${version}${title ? ` - ${title}` : ""}`,
    id: version
  }))
}

/* === Reducer === */
export default createReducer(initialState, {
  [resetAction]: (state) => {
    return {
      ...initialState,
      categories: state.categories,
      categoriesOptions: getCategoriesOptions(state.categories),
      models: state.models,
      modelOptions: getModelOptions(state.models),
      versions: state.versions,
      versionsOptions: getModelVersionOptions(state.versions),
    }
  },
  [initializeAction]: (state, {payload}) => {
    const { categories, models, selectedCategory } = payload
    state.categories = categories
    state.models = models
    state.categoriesOptions = getCategoriesOptions(categories)
    state.modelOptions = getModelOptions(models)

    if (selectedCategory) {
      state.selectedCategories = [selectedCategory]
      state.schema.categories = [selectedCategory]
    }
  },
  [setStateAction]: (state, {payload}) => ({
    ...state,
    ...payload
  }),
  [setSelectedCategoriesAction]: (state, {payload}) => state.selectedCategories = payload,
})

/* === DISPATCHERS === */
export const reset = () => dispatch => dispatch(resetAction())

export const initialize = () => async (dispatch, getState) => {
  const [{data: categories}, {data: models}] = await Promise.all([
    getCategoriesRequest(),
    getModelsRequest()
  ])
  const { selectedCategory } = getState().router.location.query || {}

  dispatch(initializeAction({categories, models, selectedCategory}))
  
  if (selectedCategory) {
    dispatch(selectCategories([selectedCategory]))
  }
}

export const selectCategories = (selectedCategories) => async (dispatch, getState) => {
  const { editSchema: { categories, schema } } = getState()
  const schemaClone = cloneDeep(schema)
  const payload = { selectedCategories, schema: schemaClone }
  payload.schema.categories = selectedCategories

  // // Check if any of the selected categories have a model
  // // If so, disable the model and modelVersion dropdown,
  // // and set the model and modelVersion to the first selected category's model and modelVersion

  const selectedCategoryWithModel = categories.find(({name, model}) => selectedCategories.includes(name) && model)
  const { model={} } = selectedCategoryWithModel || {}
  const { name: modelName, version } = model

  if (selectedCategoryWithModel) {
    const categoriesWithoutModel = categories.filter(({model}) => !model)
    const newCategoriesOptions = getCategoriesOptions([selectedCategoryWithModel, ...categoriesWithoutModel])
    
    payload.categoriesOptions = newCategoriesOptions
  } else {
    payload.categoriesOptions = getCategoriesOptions(categories)
  }
    
  if (modelName) {
    const { data: versions } = await getModelVersionsRequest(modelName)

    Object.assign(payload, {
      versions,
      versionsOptions: getModelVersionOptions(versions),
      selectedVersion: version || versions[0]?.version || null,
      isVersionDisabled:!!version,
      selectedModel: modelName,
      isModelDisabled: true,
    })
  } else {
    payload.selectedModel = null
    payload.selectedVersion = null
    payload.isModelDisabled = false
    payload.isVersionDisabled = false
  }


  if (modelName) {
    const versionSchema = version
      ? payload.versions.find(({version: v}) => v === version)?.template || {}
      : payload.versions[0]?.template

    payload.schema = {
      ...schemaClone,
      ...versionSchema,
      model: {
        name: modelName,
        ...(version ? {version} : {})
      }
    }
  } else {
    delete payload.schema.model
  }

  dispatch(setStateAction(payload))
}

export const selectModel = (model = "", version = 0) => async (dispatch, getState) => {
  const { editSchema: { schema } } = getState()
  const payload = {}
  
  if (model) {
    const {data: modelVersions} = await getModelVersionsRequest(model)
    const latestModelVersion = modelVersions[version]

    payload.selectedModel = model
    payload.versions = modelVersions
    payload.versionsOptions = getModelVersionOptions(modelVersions)
    payload.selectedVersion = latestModelVersion?.version || undefined
    payload.isVersionDisabled = false
    payload.schema = {
      ...schema,
      ...(latestModelVersion?.template || {}),
      model: {
        name: model,
        version: payload.selectedVersion
      }
    }
  } else {
    if (schema.model) {
      payload.schema = {...schema}
      delete payload.schema.model
    }

    payload.selectedModel = null
    payload.selectedVersion = null
    payload.isModelDisabled = false
    payload.isVersionDisabled = true
  }
  
  dispatch(setStateAction(payload))
}

export const selectVersion = (selectedVersion = "") => async (dispatch, getState) => {
  const { editSchema: { versions, schema } } = getState()
  const schemaClone = cloneDeep(schema)
  const payload = { selectedVersion }
  const { template } = versions.find(({version}) => version === selectedVersion) || {}

  payload.schema = {
    ...schemaClone,
    ...(template || {}),
  }

  payload.schema.model.version = selectedVersion

  dispatch(setStateAction(payload))
}

export const saveSchema = (schema) => async (dispatch) => {
  dispatch(setStateAction({ schema }))
}

