
import React, { useState } from "react"

import { CircleCheckIcon } from "assets/icons"
import {colors} from "assets/stylesheets/common"
import Spinner from "components/common/Spinner"
import UnityIcon from "components/unity/UnityIcon"


import UnityProgress from "./UnityProgress"

const UnityProgressModal = ({ progress, title, fetching }) => {
  const [minimized, setMinimized] = useState(false)

  const toggleMinimize = () => {
    setMinimized(!minimized)
  }

  const modalStyle = {
    position: "fixed",
    bottom: "25px",
    right: "1px",
    zIndex: "1000",
    width: minimized ? "200px" : "400px",
    border: "1px solid var(--gray-color)",
    display: "flex",
    flexDirection: "column",
  }

  const headerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px",
    cursor: "pointer",
    marginBottom: minimized ? null : "-15px",
    marginRight: minimized ? "30px" : null,

  }

  const symbolStyle = {
    fontSize: "20px",
    position: "absolute",
    top: "-10px",
    right: "0",
    padding: "8px"
  }

  const titleStyle= {
    marginBottom: "10px",
    color: colors.text1,
  }

  return (
    <div id="modal-progress-bar" style={modalStyle}>
      <div
        style={headerStyle}
      >
        <label style={titleStyle}>
          {fetching ? (
            progress >= 100 ? (
              <>
                {title}
                <Spinner style={{ width: "14px", height: "14px", top: "15px", left: "140px" }} />
              </>
            ) : (
              <>
                {title}
                <strong>{progress}%</strong>
              </>
            )
          ) : (
            progress >= 100 &&
              <>
                {"Upload Completed"}
                <UnityIcon Component={CircleCheckIcon} color={colors.success} style={{position: "absolute", marginLeft:"5px"}}/>
              </>
          )}
        </label>
        <div style={symbolStyle} onClick={toggleMinimize}>{minimized ? "+" : "-"}</div>
      </div>
      {!minimized && <UnityProgress progress={progress}/>}
    </div>
  )
}

export default UnityProgressModal
