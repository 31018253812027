import { createAction } from "@reduxjs/toolkit"

// creates actions from a type string and array of action name strings
//  input:  string of action type (device, thing, api, etc)
//          array of action names (will be names to ref actions by)
// output:  obj of {key[actionName]: val[action] }
export function makeActions(type="", actions=[]) {
  return actions.reduce((actions, actionName) => {
    return {
      ...actions,
      [actionName]: createAction(`${type.toUpperCase()}_${actionName.toUpperCase()}`)
    }
  }, {})
}
