/* === IMPORTS === */
import { createReducer } from "@reduxjs/toolkit"

import { CLEAN_SPACE } from "actions/auth"

import { makeActions } from "./utiliducks"


/* == ACTIONS === */
const actionList = [
  "setMqttSubscriptionsAction",
]
const {
  setMqttSubscriptionsAction,
} = makeActions("mqttInspector", actionList)

/* === INITIAL STATE === */
const initialState = {
  subscriptions: []
}

/* === Reducer === */
export default createReducer(initialState, {
  [setMqttSubscriptionsAction]: (state, { payload: { subscriptions=[] }}={}) => ({
    ...state,
    subscriptions
  }),
  [CLEAN_SPACE]: () => initialState
})

/* === DISPATCHERS === */
export const setMqttSubscriptions = (subscriptions=[]) => {
  return (dispatch) => {
    dispatch(setMqttSubscriptionsAction({subscriptions}))
  }
}