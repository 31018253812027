import React from "react"
import styled from "styled-components"

import { DoubleLeftChevronIcon, DoubleRightChevronIcon } from "assets/icons"

const StyledSiderTrigger = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding: 4px 0px;
border: 1px solid #ABB3B5;
border-radius: 4px!important;
background: #FFF;
width: 100%;
height: 24px;

& .icon {
  fill: #4D4D4D;
  width: 16px;
  height: 16px;
}
`


const UnitySidebarTrigger = ({ collapsed }) => {
  return (
    <StyledSiderTrigger>
      {collapsed
        ? <DoubleRightChevronIcon className="icon" />
        : <DoubleLeftChevronIcon className="icon" />}
    </StyledSiderTrigger>
  )
}

export default UnitySidebarTrigger