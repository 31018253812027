import { del, get, getThingBaseUrl, post, put } from "utils/api"


export async function getCredentialsRequest(id, entityType, collection) {
  const { data } = await get(`${getThingBaseUrl(collection)}/${entityType}/${id}/mqtt-credentials`)
  return data
}

export async function createCredentialsRequest(body, entityId, entityType, collection) {
  const response = await post(`${getThingBaseUrl(collection)}/${entityType}/${entityId}/mqtt-credentials`, body)
  const {
    status,
    data
  } = response
  if(status === 200) {
    return data
  }
  else{
    return {}
  }
}

export async function updateCredentialsRequest(body, entityId, entityType, collection) {
  const response = await put(`${getThingBaseUrl(collection)}/${entityType}/${entityId}/mqtt-credentials`, body)
  const {
    status,
    data
  } = response
  if(status === 200) {
    return data
  }
  else{
    return {}
  }
}

export async function deleteCredentialsRequest(entityId, entityType, collection ) {
  const response = await del(`${getThingBaseUrl(collection)}/${entityType}/${entityId}/mqtt-credentials`)
  if(response) throw response
}

export function validateMQTTCredentials({username, autogenerateUsername, password, autogeneratePassword}) {
  const error = {}
  if(!autogenerateUsername) {
    if (!username) error.username = "Username has to be declared or autogenerated"
    else if (!/^[A-z0-9-]+$/.test(username)) error.username = "Username can only contain alphanumeric characters and \"-\"."
    else if (username.length < 3 || username.length > 26) error.username = "Username must be between 3 and 26 characters"
  }
  if(!autogeneratePassword && password) {
    if (!/^[A-z0-9@-]+$/.test(password)) error.password = "Password can only contain alphanumeric characters, \"-\" and \"@\"."
    else if (password.length < 3 || password.length > 26) error.password = "Password must be between 3 and 26 characters"
  }
  return error
}
