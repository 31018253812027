import { API_GATEWAY_URL } from "constants/config"
import { del, get, post, put } from "utils/api"

import { getSpace } from "./storage"


// REQUESTS

export async function getCategoriesRequest() {
  const response = await get("/categories", null, {beta: true})
  const {
    status,
    data={}
  } = response
  if(status === 200) {
    return data
  }
  else {
    return {}
  }
}

export async function createCategoryRequest(category) {
  const response = await post("/categories", category, {beta: true})
  const {
    status,
    data
  } = response
  if (status === 200) return data
  throw response
}

export async function updateCategoryRequest(categoryName, category) {
  const response = await put(`/categories/${categoryName}`, category, {beta: true})
  const {
    status,
    data
  } = response
  if (status === 200) return data
  throw response
}

export async function deleteCategoryRequest(id) {
  const response = await del(`/categories/${id}`, null, {beta: true})
  if (response) throw response
  return response
}


// FUNCTIONS

export function buildCategoryLink(target) {
  return `${API_GATEWAY_URL}/beta/spaces/${getSpace()}/categories/${target}/things/+`
}
