/* === IMPORTS === */
import { createReducer } from "@reduxjs/toolkit"

import { CLEAN_SPACE } from "actions/auth"
import { parseApiErrorMessage } from "utils/api"
import {
  createDistributionRequest,
  deleteDistributionRequest,
  deleteDistributionsRequest,
  getDistributionRequest,
  getDistributionsRequest,
  getDistributionVersionsRequest,
  // updateDistributionRequest
} from "utils/distributions"
import { makeLib } from "utils/misc"
import {
  addMessage,
  GLOBAL_NOTIFICATIONS,
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS,
} from "utils/notifications"
  
import { makeActions } from "./utiliducks"

// import { getFunctionTopicParams, getMessageContent } from 'utils/mqtt'

/* == ACTIONS === */
const actionList = [
  "setDistributionAction",
  "setDistributionVersionsAction",
  "setDistributionsAction",
  "addDistributionAction",
  "deleteDistributionAction",
  "setPagingAction"
]
const {
  setDistributionAction,
  setDistributionVersionsAction,
  setDistributionsAction,
  addDistributionAction,
  deleteDistributionAction,
  setPagingAction
} = makeActions("distributions", actionList)

/* === INITIAL STATE === */
const initialState = {
  distributions: [],
  distributionsLib: {},
  paging: { previous_cursor: "", next_cursor: "" },
  currentDistribution: undefined
}

/* === Reducer === */
export default createReducer(initialState, {
  [setDistributionsAction]: (state, { payload: { distributions }}={}) => ({
    ...state,
    distributions,
    distributionsLib: makeLib({data: distributions})
  }),
  [setDistributionAction]: (state, { payload: { distribution }}={}) => ({
    ...state,
    currentDistribution: distribution
  }),
  [setDistributionVersionsAction]: (state, { payload: { distributionVersions }}={}) => ({
    ...state,
    currentDistribution: {
      ...state.currentDistribution,
      distributionVersions
    }
  }),
  [addDistributionAction]: (state, { payload: { distribution }}) => ({
    ...state,
    distributions: [
      ...state.distributions,
      distribution
    ],
    distributionsLib: {
      ...state.distributionsLib,
      [distribution.id]: distribution
    }
  }),
  [deleteDistributionAction]: (state, { payload: { ids }}) => {
    const distributions = state.distributions.filter(({ id }) => !ids.includes(id))
    return (
      {
        ...state,
        distributions,
        distributionsLib: makeLib({data: distributions}),
        currentDistribution: undefined
      })
  },
  [CLEAN_SPACE]: () => initialState,
  [setPagingAction]: (state, { payload: { paging } }) => ({
    ...state,
    paging
  })
})

/* === DISPATCHERS === */
export const getDistributions = () => {
  return async (dispatch) => {
    try {
      const response = await getDistributionsRequest()
      const { data=[], paging } = response
      if (!Array.isArray(data)) throw new Error(`Invalid data format: ${JSON.stringify(data)}`)

      const distributions = data.map(distribution => {
        return {
          ...distribution,
          id: `${distribution.name}-${distribution.latest_version}`
        }
      })
  
      dispatch(setDistributionsAction({ distributions }))
      if (paging) dispatch(setPaging({ paging }))
      return data
    }
    catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Distributions could not be retrieved",
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
      return []
    }
  }
}

export const getDistribution = (distName, distVersion, distType) => {
  return async dispatch => {
    try {
      if (!distName) {
        dispatch(setDistributionAction({distName, distribution: undefined}))
        return
      }
      const distribution = await getDistributionRequest(distName, distVersion, distType)
      dispatch(setDistributionAction({distribution}))
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Distribution could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const getDistributionVersions = (distName) => {
  return async dispatch => {
    try {
      const { data } = await getDistributionVersionsRequest(distName)
      const distributionVersions = data.map(distribution => {
        return {
          ...distribution,
          id: `${distribution.name}-${distribution.version}`
        }
      })
      dispatch(setDistributionVersionsAction({distributionVersions}))
      return data
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Distribution versions could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const getNextDistributions = () => {
  return async (dispatch, getState) => {
    const {
      distributions: {
        distributions,
        paging: {
          next_cursor
        }={}
      }
    } = getState()
    const { data, paging } = await getDistributionsRequest({ next_cursor })

    const nextDistributions = data.map(distribution => {
      return {
        ...distribution,
        id: `${distribution.name}-${distribution.version}`
      }
    })

    dispatch(setPaging({ paging }))
    dispatch(setDistributionsAction({ distributions: [ ...distributions, ...nextDistributions ]}))
  }
}

export const createDistribution = (distributionData) => {
  return async (dispatch) => {
    try {
      const response = await createDistributionRequest(distributionData)
      const nextDistributionData = {
        ...response,
        id: `${response.name}-${response.version}`
      }
      dispatch(addDistributionAction({ distribution: nextDistributionData }))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Distribution created successfully.",
        subtext: "Please add the necessary policies to this distribution.",
        type: MESSAGE_TYPE_SUCCESS,
        timeout: 4000,
      })
      return nextDistributionData
      
    } catch (error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Distribution could not be created",
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const deleteDistribution = (dist) => { //this function will be used in the details panel just for one specific distribution version
  return async (dispatch) => {
    try {
      await deleteDistributionRequest(dist)
      const distWithId = [{...dist, id: `${dist.name}-${dist.version}`}]
      dispatch(deleteDistributionAction({ ids: distWithId }))

      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Delete Successful",
        subtext: "Distribution successfully deleted",
        type: MESSAGE_TYPE_SUCCESS,
        timeout: 4000
      })
    } catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Distribution could not be deleted",
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const deleteDistributions = (distributions=[]) => { //this function will be used in the main list
  distributions = Array.isArray(distributions) ? distributions : [ distributions ]
  return async (dispatch) => {
    try {
      const responses = distributions.map(async d => {
        return deleteDistributionsRequest(d)
      })
      await Promise.all(responses)
      const distWithId = distributions.map(async d => {
        return {...d, id: `${d.name}-${d.version}`}
      })
      dispatch(deleteDistributionAction({ ids: distWithId }))

      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Delete Successful",
        subtext: `Successfully deleted ${distributions.length} distribution${distributions.length !== 1 ? "s" : ""}`,
        type: MESSAGE_TYPE_SUCCESS,
        timeout: 4000
      })
    } catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: `${distributions.length === 1 ? "Distribution" : "Some distributions"} could not be deleted`,
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const setPaging = ({ paging }) => {
  return dispatch => dispatch(setPagingAction({ paging }))
}