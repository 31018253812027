import { UnitySpinner } from "@bit/smartworks.unity-react.unity-core-react"
import { Typography } from "antd"
import React from "react"
import { connect } from "react-redux"

import { CheckIcon } from "assets/icons"
import { colors } from "assets/stylesheets/common"
import UserPopoverContentAltairOneStatusLinkButton from "components/Layout/UserPopoverContentAltairOneStatus/UserPopoverContentAltairOneStatusLinkButton"
import UserPopoverContentAltairOneStatusUnlinkButton from "components/Layout/UserPopoverContentAltairOneStatus/UserPopoverContentAltairOneStatusUnlinkButton"
import UnityIcon from "components/unity/UnityIcon"




const UserPopoverContentAltairOneStatusText = ({error = false, text = ""}) =>
  <div style={styles.altairOneStatusLinked}>
    { !error &&
      <UnityIcon Component={CheckIcon} color={colors.green}/>
    }
    <span>{text}</span>
  </div>

const UserPopoverContentAltairOneStatusLinked = () =>
  <>
    <UserPopoverContentAltairOneStatusText text="AltairOne Licenses linked."/>
    <UserPopoverContentAltairOneStatusUnlinkButton />
  </>

const UserPopoverContentAltairOneStatusLinkedAndExpired = () =>
  <>
    <UserPopoverContentAltairOneStatusText error text="AltairOne Licenses are linked but have expired."/>
    <div style={{display: "flex", gap: "8px"}}>
      <UserPopoverContentAltairOneStatusLinkButton label="Renew AltairOne Licenses"/>
      <UserPopoverContentAltairOneStatusUnlinkButton />
    </div>
  </>

const UserPopoverContentAltairOneStatusNotLinked = () =>
  <>
    <UserPopoverContentAltairOneStatusText error text="AltairOne Licenses are not linked."/>
    <UserPopoverContentAltairOneStatusLinkButton label="Link AltairOne Licenses"/>
  </>

const mapStateToProps = (state) => ({
  linked: state.authentication.altairOneStatus.linked,
  expired: state.authentication.altairOneStatus.expired
})

const UserPopoverContentAltairOneStatus = ({ linked=false, expired=false, isLoading=false, unlinkAltairOne}) =>
  <div style={styles.wrapper}>
    <Typography.Title level={2}>
      AltairOne Licenses
    </Typography.Title>
    {isLoading ?
      <div style={styles.spinnerWrapper}>
        <UnitySpinner active style={styles.spinner}/>
      </div> :
      <div style={styles.altairOneStatus}>
        {linked && !expired && <UserPopoverContentAltairOneStatusLinked unlinkAltairOne={unlinkAltairOne}/>}
        {linked && expired && <UserPopoverContentAltairOneStatusLinkedAndExpired />}
        {!linked && <UserPopoverContentAltairOneStatusNotLinked />}
      </div>}
  </div>


const styles = {
  spinnerWrapper: {
    position: "relative",
    width: "100%",
    height: "45px",
    display: "flex",
    alignItems:"center",
    justifyContent:"center"
  },
  spinner: {
    "--default-spinner-color": "var(--default-primary-brand-color)",
    "--default-spinner-size": "25px",
    "--default-spinner-stroke-width": "2px",
  },
  wrapper:{
    border: `1px solid ${colors.lightGray1}`,
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    gap: 5,
    alignSelf: "stretch",
    position: "relative",
  },
  altairOneStatusLinked: {
    display: "flex",
    gap: 5,
    alignItems: "center",
    marginBottom: "6px"
  },
  altairOneStatus:{
    marginLeft: "10px",
    display: "flex",
    flexDirection: "column",
    gap: 5
  },
}

export default connect(mapStateToProps)(UserPopoverContentAltairOneStatus)