export const CONTENT_TYPES = {
  BODY_JSON: "body-json",
  BODY_TEXT: "body-text",
  BODY_FORM: "body-form"
}

export const METHODS = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
  PATCH: "patch"
}

export const REQUEST_DETAILS = {
  QUERY: "query",
  HEADERS: "headers",
  AUTH: "auth",
  BODY: "body"
}
