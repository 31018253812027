import { del, get, post, put } from "utils/api"

export const MQTT = "mqtt"
export const CRON = "cron"
export const RABBITMQ = "rabbitmq"

export async function getTriggersRequest(params) {
  const response = await get("/triggers", params)
  const { status, data } = response
  if (status === 200) return data
  throw new Error(`Request failed with status ${response.status} - ${response.data}`)
}

export async function createTriggerRequest(triggerData) {
  const response = await post("/triggers", triggerData)
  const { status, data } = response
  if (status !== 200) throw new Error(`Create Trigger failed with status ${response.status} - ${response.data}`)
  return data
}

export async function updateTriggerRequest(id, triggerData) {
  const response = await put(`/triggers/${id}`, triggerData)
  const { status, data } = response
  if (status === 200) return data
  throw new Error(`Request failed with status ${response.status} - ${response.data}`)
}

export async function deleteTriggerRequest(id) {
  const response = await del(`/triggers/${id}`)
  // if (response.status !== 204) throw response // delete user requests return null when successful, and an error object on failure
  if (response) throw response //TODO: replace this line with the one above when api response changes
  return response
}

//Stringify numbers in config to make it easier to work with in redux form
export const formatTriggerConfig = (config, type) => {
  if (!config) return

  const nextConfig = {...config}
  const {port, qos=0, topics} = config

  //Convert numerical values to strings for easier handling in redux form
  if (port) nextConfig.port = String(port)
  if (type === MQTT) nextConfig.qos = String(qos)

  //Replace topics csv string with array
  if (topics) nextConfig.topics = splitTopics(topics)

  return nextConfig
}

// Splits csv topics string into an array of topics
export const splitTopics = (topics) => topics ? topics.split(/,\s*/) : []