import { UnityTypography } from "@bit/smartworks.unity-react.unity-core-react"
import moment from "moment"
import React from "react"

import { colors } from "assets/stylesheets/common"
import { del, get, post, put } from "utils/api"

import "@bit/smartworks.unity.unity-core/unity-icon"
import { EQUAL_OPERATOR } from "./tableColumns"


export async function getRolloutsRequest(params) {
  const response = await get("/edge-rollouts", params)
  const { status, data } = response
  if (status === 200) return data
  throw new Error(`Request failed with status ${response.status} - ${response.data}`)
}

export async function getRolloutRequest(rolloutName) {
  const response = await get(`/edge-rollouts/${rolloutName}`)
  const { status, data } = response
  if (status === 200) return data
  throw new Error(`Request failed with status ${response.status} - ${response.data}`)
}

export async function createRolloutRequest(rolloutData) {
  const response = await post("/edge-rollouts", rolloutData)
  const { status, data } = response
  if (status !== 200) throw new Error(`Request failed with status ${response.status} - ${response.data}`)
  return data
}

export async function deleteRolloutRequest(id) {
  const response = await del(`/edge-rollouts/${id}`)
  if (response) throw response
  return response
}

export async function playRolloutRequest(rolloutName) {
  const response = await put(`/edge-rollouts/${rolloutName}/play`)
  if (response) throw response
  return response
}

export async function pauseRolloutRequest(rolloutName) {
  const response = await put(`/edge-rollouts/${rolloutName}/pause`)
  if (response) throw response
  return response
}

export async function resumeRolloutRequest(rolloutName) {
  const response = await put(`/edge-rollouts/${rolloutName}/resume`)
  if (response) throw response
  return response
}

export const initialColumns = [
  {
    key: "name",
    label: "Name",
    showFilter: true,
    singleFilter: true,
    operators: [EQUAL_OPERATOR]
  },
  {
    key: "description",
    label: "Description"
  },
  {
    key: "total_clusters",
    label: "Total Assets"
  },
  {
    key: "status",
    label: "Status",
    renderCustomContent: (value) => {
      switch(value){
      case "creating":
        return (
          <div style={styles.iconContainer}>
            <unity-icon style={{ ...styles.statusIcon, color: colors.yellow }} icon={"unity:clock"}></unity-icon>
            <UnityTypography>{value}</UnityTypography>
          </div>
        )

      case "ready":
        return (
          <div style={styles.iconContainer}>
            <unity-icon style={{ ...styles.statusIcon, color: colors.secondary }} icon={"unity:job_run"}></unity-icon>
            <UnityTypography>{value}</UnityTypography>
          </div>
        )

      case "running":
        return (
          <div style={styles.iconContainer}>
            <unity-icon style={{ ...styles.statusIcon, color: colors.yellow }} icon={"unity:history"}></unity-icon>
            <UnityTypography>{value}</UnityTypography>
          </div>
        )

      case "paused":
        return (
          <div style={styles.iconContainer}>
            <unity-icon style={{ ...styles.statusIcon, color: colors.gray }} icon={"unity:job_suspend"}></unity-icon>
            <UnityTypography>{value}</UnityTypography>
          </div>
        )

      case "finished":
        return (
          <div style={styles.iconContainer}>
            <unity-icon style={{ ...styles.statusIcon, color: colors.success }} icon={"unity:check"}></unity-icon>
            <UnityTypography>{value}</UnityTypography>
          </div>
        )
        
      }

      return value
    }
  },
  {
    key: "created",
    label: "Created",
    formatLabel: (colValue) => colValue ? moment.utc(colValue).fromNow() : ""
  }
]

const styles = {
  iconContainer: {
    display: "flex",
    "justifyContent": "space-between"
  },
  statusIcon: {
    paddingRight: "5px"
  }
}