/* === IMPORTS === */
import { createReducer } from "@reduxjs/toolkit"

import { CLEAN_SPACE } from "actions/auth"
import { createCategoryRequest, deleteCategoryRequest, getCategoriesRequest, updateCategoryRequest } from "utils/categoriesBeta"
import { makeLib } from "utils/misc"
import {
  addMessage,
  GLOBAL_NOTIFICATIONS,
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS,
  MESSAGE_TYPE_WARNING
} from "utils/notifications"

import { makeActions } from "./utiliducks"

/* == ACTIONS === */
const actionList = [
  "setCategoriesAction",
  "addCategoryAction",
  "updateCategoryAction",
  "removeCategoryAction"
]
const {
  setCategoriesAction,
  addCategoryAction,
  updateCategoryAction,
  removeCategoryAction
} = makeActions("categoriesBeta", actionList)

/* === INITIAL STATE === */
const initialState = {
  categories: [],
  categoriesLib: {},
}

/* === Reducer === */
export default createReducer(initialState, {
  [setCategoriesAction]: (state, { payload: { categories }}={}) => ({
    ...state,
    categories,
    categoriesLib: makeLib({data: categories, key: "name"})
  }),
  [addCategoryAction]: (state, { payload: { category }}) => {
    let newCategories = [...state.categories, category].sort(({name: a=""}, {name: b=""}) => a.toLowerCase() > b.toLowerCase() ? 1 : -1)
    return {
      ...state,
      categories: newCategories,
      categoriesLib: {
        ...state.categoriesLib,
        [category.name]: category
      }
    }
  },
  [updateCategoryAction]: (state, { payload: { name, updatedCategory }}) => {
    const newCategoriesLib = {...state.categoriesLib}
    delete newCategoriesLib[name]
    return ({
      ...state,
      categories: state.categories.map(category => category.name === name? updatedCategory : category),
      categoriesLib: {
        ...newCategoriesLib,
        [updatedCategory.name]: updatedCategory
      }
    })
  },
  [removeCategoryAction]: (state, { payload: { name }}) => {
    const categories = state.categories.filter(category => name !== category.name)
    return (
      {
        ...state,
        categories,
        categoriesLib: makeLib({data: categories, key: "name"})
      })
  },
  [CLEAN_SPACE]: () => initialState
})

/* === DISPATCHERS === */
export const getCategories = () => {
  return async dispatch => {
    try {
      const response = await getCategoriesRequest()
      const { data: categories } = response
      const sortedCategories = [...categories].sort(({name: a=""}, {name: b=""}) => a.toLowerCase() > b.toLowerCase() ? 1 : -1)
      dispatch(setCategoriesAction({categories: sortedCategories}))
      return categories
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Categories could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const createCategory = (category) => {
  return async dispatch => {
    try {
      const newCategory = await createCategoryRequest(category)
      dispatch(addCategoryAction({category: newCategory}))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: `Category "${newCategory.name}" has been created`,
        type: MESSAGE_TYPE_SUCCESS
      })
      return newCategory.name
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Category could not be created",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const updateCategory = (name, category) => {
  return async dispatch => {
    try {
      const updatedCategory = await updateCategoryRequest(name, category)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: `Category ${name} was updated successfully`,
        type: MESSAGE_TYPE_SUCCESS
      })
      return dispatch(updateCategoryAction({name, updatedCategory}))
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Category could not be updated",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const updateCategories = (categories) => {
  return async dispatch => {
    try {
      const promises = await Promise.allSettled(categories.map(async category => {
        return await updateCategoryRequest(category.name, category)
      }))

      const updatedCategories = promises.filter(({status}) => status === "fulfilled")
      const failedCategories = promises.filter(({status}) => status === "rejected")

      if (!updateCategories.length) {
        addMessage({
          target: GLOBAL_NOTIFICATIONS,
          text: "No categories were updated",
          subtext: `${failedCategories.length} categories could not be updated`,
          type: MESSAGE_TYPE_ERROR
        })
      }

      if (!failedCategories.length) {
        addMessage({
          target: GLOBAL_NOTIFICATIONS,
          text: "All categories were updated successfully",
          subtext: `${updatedCategories.length} categories were updated successfully`,
          type: MESSAGE_TYPE_SUCCESS
        })
      }

      if (updatedCategories.length && failedCategories.length) {
        addMessage({
          target: GLOBAL_NOTIFICATIONS,
          text: "Some categories were updated successfully",
          subtext: `${updatedCategories.length} categories were updated successfully, ${failedCategories.length} categories could not be updated`,
          type: MESSAGE_TYPE_WARNING
        })
      }

      if (updatedCategories.length) {
        updatedCategories.forEach(({value: category}) => {
          dispatch(updateCategoryAction({name: category.name, updatedCategory: category}))
        })
      }

    } catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Something went wrong while updating categories",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const deleteCategory = (name) => {
  return async dispatch => {
    try {
      await deleteCategoryRequest(name)
      dispatch(removeCategoryAction({name}))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: `Category "${name}" has been deleted`,
        type: MESSAGE_TYPE_SUCCESS
      })
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Category could not be deleted",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

/* === UTILS === */
