import React, { Component } from "react"
import { connect } from "react-redux"

import NewTabLink from "components/common/NewTabLink"
import { DOCS, DOCS_ACCESS, DOCS_ANYTHING_DB, DOCS_EDGE_OPS, DOCS_FUNCTIONS, DOCS_LABELS, DOCS_POLICY_RESOURCES, DOCS_ROOT, DOCS_SPACES, DOCS_STREAMS,
  DOCS_VIZ } from "constants/routes"


const docList = {
  [DOCS]:           "index.htm",
  [DOCS_ANYTHING_DB]:  "topics/database/database_intro.htm",
  [DOCS_FUNCTIONS]: "topics/functions/functions_intro.htm",
  [DOCS_EDGE_OPS]:  "topics/edge_ops/edge_ops.htm",
  [DOCS_ACCESS]:    "topics/access_control/access_intro.htm",
  [DOCS_SPACES]:    "topics/spaces/your_space.htm",
  [DOCS_POLICY_RESOURCES]:    "topics/access_control/roles.htm#ariaid-title3",
  [DOCS_STREAMS]:   "topics/stream/streams_intro.htm",
  [DOCS_VIZ]:       "topics/realtime_vis/real_time_vis_intro.htm",
  [DOCS_LABELS]:    "topics/spaces/labels_intro.htm#reference_r5r_w1q_2nb"
}

class Documentation extends Component {
  render () {
    return <NewTabLink page={`${DOCS_ROOT}/${docList[this?.props?.doc || DOCS]}`}/>
  }
}

export default connect()(Documentation)
