import { requestEndpoints } from "constants/routes"
import { del, get, patch, post, put } from "utils/api"

const checkStatus = ({status, data}={}) => status === 200 ? data : {}

const getEndpoint = ({type, id, ids}) => {
  const endpoint = requestEndpoints[type]
  const urlIds = ids || [id]
  if (endpoint instanceof Function) {
    if (Array.isArray(urlIds)) return endpoint(...urlIds)
    return endpoint(urlIds)
  }
  return `${endpoint}${id ? `/${id}` : ""}`
}

export async function getList(type="", givenOptions={}) {
  const {
    id, ids, params,
    ...options
  } = givenOptions
  const response = await get(getEndpoint({type, id, ids}), params, options)
  return checkStatus(response)
}

export async function getItem({type, id, ids, params}, options) {
  const response = await get(getEndpoint({type, id, ids}), params, options)
  return checkStatus(response)
}

export async function postItem({type, id, ids, body}, options) {
  const response = await post(getEndpoint({type, id, ids}), body, options)
  return checkStatus(response)
}

export async function putItem({type, id, ids, body}, options) {
  const response = await put(getEndpoint({type, id, ids}), body, options)
  return checkStatus(response)
}

export async function patchItem({type, id, ids, body}, options) {
  const response = await patch(getEndpoint({type, id, ids}), body, options)
  return checkStatus(response)
}

export async function deleteItem({type, id, ids}, options) {
  const response = await del(getEndpoint({type, id, ids}), undefined, options)
  if (response) throw response
  return response === undefined
}
