/* === IMPORTS === */
import { createReducer } from "@reduxjs/toolkit"

import { CLEAN_SPACE } from "actions/auth"
import {
  getInfoRequest,
  getMaxRequest,
  getSpacesStatusRequest,
  getStatusRequest,
  getUnitsRequest,
  getUsageRequest,
  postCalculatorRequest
} from "utils/auManagement"
import {getSpaceId} from "utils/auth"
import {
  addMessage,
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS
} from "utils/notifications"


import { makeActions } from "./utiliducks"


/* == ACTIONS === */
const actionList = [
  "setInfoAction",
  "setMaxAction",
  "setStatusAction",
  "setSpacesStatusAction",
  "setUnitsAction",
  "setUsageAction"
]

const {
  setInfoAction,
  setMaxAction,
  setStatusAction,
  setSpacesStatusAction,
  setUnitsAction,
  setUsageAction,
} = makeActions("auManagement", actionList)

/* === INITIAL STATE === */
const initialState = {
  info: {},
  max: {},
  status: {},
  spacesStatus:[],
  units: {},
  usage: {}
}

/* === Reducer === */
export default createReducer(initialState, {
  [setInfoAction]: (state, { payload: { info }}={}) => {
    state.info = info
  },
  [setMaxAction]: (state, { payload: { max }}={}) => {
    state.max = max
  },
  [setStatusAction]: (state, { payload: { status }}) => {
    state.status = status
  },
  [setSpacesStatusAction]: (state, { payload: { spacesStatus }}) => {
    state.spacesStatus = spacesStatus
  },
  [setUnitsAction]: (state, { payload: { units }}={}) => {
    state.units = units
  },
  [setUsageAction]: (state, { payload: { usage }}={}) => {
    state.usage = usage
  },
  [CLEAN_SPACE]: () => initialState
})

/* === DISPATCHERS === */

export const getCalculatorData = () => {
  return async (dispatch) => {
    const result = await Promise.allSettled([
      dispatch(getUsage()),
      dispatch(getMax()),
      dispatch(getInfo()),
      dispatch(getStatus(getSpaceId())),
      dispatch(getUnits())
    ])
    if(result.find(({value}) => !value)) {
      addMessage({
        text: "Error loading calculator data",
        subtext: "Please, try again later or contact support.",
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}
export const getInfo = (notify=false) => {
  return async (dispatch) => {
    try {
      const info = await getInfoRequest()
      dispatch(setInfoAction({ info }))
      return info
    }
    catch(error) {
      // TODO: we don't want to notify about this exactly. See how we manage notifications here.
      console.error(error.message)
      if (notify) addMessage({
        text: "Info data could not be retrieved",
        subtext: error.message.error,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const getMax = (notify=false) => {
  return async (dispatch) => {
    try {
      const max = await getMaxRequest()
      dispatch(setMaxAction({ max }))
      return max
    }
    catch(error) {
      console.error(error.message)
      if (notify) addMessage({
        text: "Usage data could not be retrieved",
        subtext: error.message.error,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const getStatus = (space, notify=false) => {
  return async (dispatch) => {
    try {
      const status = await getStatusRequest(space)
      dispatch(setStatusAction({ status }))
      return status
    }
    catch(error) {
      console.error(error.message)
      if (notify) addMessage({
        text: "Status data could not be retrieved",
        subtext: error.message.error,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const getUnits = (notify=false) => {
  return async (dispatch) => {
    try {
      const units = await getUnitsRequest()
      dispatch(setUnitsAction({ units }))
      return units
    }
    catch(error) {
      console.error(error.message)
      if (notify) addMessage({
        text: "Units data could not be retrieved",
        subtext: error.message.error,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const getUsage = (notify=false) => {
  return async (dispatch) => {
    try {
      const usage = await getUsageRequest()
      dispatch(setUsageAction({ usage }))
      return usage
    }
    catch(error) {
      console.error(error.message)
      if (notify) addMessage({
        text: "Usage data could not be retrieved",
        subtext: error.message.error,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const postCalculator = (data) => {
  return async () => {
    try {
      const { message } = await postCalculatorRequest(data)
      addMessage({
        text: message.charAt(0).toUpperCase() + message.slice(1),
        type: MESSAGE_TYPE_SUCCESS
      })
    }
    catch(error) {
      console.error(error.message)
      addMessage({
        text: "Error locking units",
        subtext: error.message.error,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const getSpacesStatus = (spaces) => {
  return async (dispatch) => {
    if (spaces.length) {
      const spaceIds = spaces.map(space => space.id)
      const resolvedRequest = await getSpacesStatusRequest(spaceIds)

      //Create map of status with space.id as key
      const spacesStatus = resolvedRequest.data.reduce((statusMap, resp) => {
        return {
          ...statusMap,
          [resp.space]: resp
        }
      }, {})

      dispatch(setSpacesStatusAction({ spacesStatus }))

    }
  }
}

export const updateSpacesStatus = (spacesStatus) => {
  return async dispatch => {
    dispatch(setSpacesStatusAction({ spacesStatus }))
  }
}