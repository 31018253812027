import {
  UnityButton,
  UnityModal
} from "@bit/smartworks.unity-react.unity-core-react"
import arrayMutators from "final-form-arrays"
import React, {useEffect, useState} from "react"
import { Form } from "react-final-form"
import { FieldArray } from "react-final-form-arrays"
import { connect } from "react-redux"

import { setMqttSubscriptions } from "reduxModules/mqttInspector"
import {
  OPEN_MQTT_SUBSCRIPTIONS_MODAL,
} from "utils/utilityBelt"

import SubscriptionFieldArray from "./SubscriptionFieldArray"


const stateToProps = ({
  mqttInspector: {
    subscriptions=[]
  }={}
}) => ({
  subscriptions
})

const SubscriptionModal = ({
  subscriptions,
  setMqttSubscriptions
}) => {
  const [showModal, setShowModal] = useState(false)
  const initialValues = {
    subscriptions
  }

  useEffect(() => {
    document.addEventListener(OPEN_MQTT_SUBSCRIPTIONS_MODAL, openSubscriptionsModal)

    return function cleanup() {
      document.removeEventListener(OPEN_MQTT_SUBSCRIPTIONS_MODAL, openSubscriptionsModal)
    }
  })

  const openSubscriptionsModal = () => {
    setShowModal(true)
  }

  const closeSubscriptionModal = (restart=()=>{}) => {
    restart()
    setShowModal(false)
  }

  const toggleSubscriptionsModal = () => {
    setShowModal(!showModal)
  }

  const onSubmit = ({subscriptions=[]}={}) => {
    setShowModal(false)
    setMqttSubscriptions(subscriptions)
  }

  const validate = () => {
    let error = {}

    //TODO: check if subscriptions are valid
    return error
  }

  return (
    <Form
      name={"mqtt-inspector-subscriptions"}
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      mutators={{...arrayMutators}}
      render={({
        handleSubmit,
        form
      }) => (
        <UnityModal
          id="mqtt-inspector-subscriptions-modal"
          show={showModal}
          title="MQTT Subscriptions"
          toggle={toggleSubscriptionsModal}
          bottom={(<div style={styles.buttonBox}>
            <UnityButton
              id="mqtt-subscriptions-modal-cancel-button"
              label="Cancel"
              type="secondary"
              style={{...styles.modalButton, ...styles.modalCancelButton}}
              onClick={() => closeSubscriptionModal(form.restart)}
            />
            <UnityButton
              id="mqtt-subscriptions-modal-save-button"
              label="Save"
              // important
              style={styles.modalButton}
              onClick={() => handleSubmit()}
            />
          </div>)}
          body={(<FieldArray
            name={"subscriptions"}
            component={SubscriptionFieldArray}
            label={"Topics"}
            trackDirty
          />)}
          style={styles.subscriptionModal}
        />
      )}
    />
    
  )
}

const styles = {
  buttonBox: {
    display: "flex"
  },
  subscriptionModal: {
    "--background-color": "var(--white-color)",

  },
  modalText: {
    margin: "1em"
  },
  modalButton: {
    marginLeft: 4
  },
  modalCancelButton: {
    "--button-color": "var(--black-text-color)"
  },
}

// export default SubscriptionModal
export default connect(stateToProps, {setMqttSubscriptions})(SubscriptionModal)