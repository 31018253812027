import * as React from "react"
import { connect } from "react-redux"
import { Route } from "react-router-dom"

import { checkAuthentication } from "actions/auth"


const stateToProps = (
  {
    authentication: {
      isAuthenticated,
      role
    }
  } = {},
  { admin=false }
) => {
  return { admin, isAuthenticated, role }
}

const PrivateRoute = (
  {
    admin,
    component: Component,
    children,
    checkAuthentication,
    isAuthenticated,
    role
  }) => {

  checkAuthentication() // if it fails, it will redirect to login

  if (isAuthenticated) {
    if( (admin && role === "root") || !admin) {
      return (
        <Route
          render={otherProps => (
            <>
              { children || <Component {...otherProps} /> }
            </>
          )}
        />
      )
    }
    else return <div>Unauthorized</div>
  }
  else return null // will show a blank page while trying to authenticate
}

export default connect(stateToProps, {checkAuthentication})(PrivateRoute)