/* === IMPORTS === */
import { createReducer } from "@reduxjs/toolkit"

import { CLEAN_SPACE } from "actions/auth"
import {
  createHfdRequest,
  deleteHfdRequest,
  getHfdDetailsRequest,
  getHfdRequest,
  updateHfdRequest
} from "utils/highFreqData"
import { makeLib } from "utils/misc"
import {
  addMessage,
  GLOBAL_NOTIFICATIONS,
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS
} from "utils/notifications"

import { makeActions } from "./utiliducks"

/* == ACTIONS === */
const actionList = [
  "setHfdsAction",
  "setHfdAction",
  "addHfdAction",
  "updateHfdAction",
  "removeHfdsAction",
  "setPagingAction"
]
const {
  setHfdsAction,
  setHfdAction,
  addHfdAction,
  updateHfdAction,
  removeHfdsAction,
  setPagingAction
} = makeActions("hfds", actionList)

/* === INITIAL STATE === */
const initialState = {
  hfds: [],
  hfdsLib: {},
  paging: { previous_cursor: "", next_cursor: "" },
  currentHfd: undefined
}

/* === Reducer === */
export default createReducer(initialState, {
  [setHfdsAction]: (state, { payload: { hfds }}={}) => ({
    ...state,
    hfds,
    hfdsLib: makeLib({data: hfds})
  }),
  [setHfdAction]: (state, { payload: { hfd }}={}) => ({
    ...state,
    currentHfd: hfd
  }),
  [addHfdAction]: (state, { payload: { hfd }}) => ({
    ...state,
    hfds: [
      ...state.hfds,
      hfd
    ],
    hfdsLib: {
      ...state.hfdsLib,
      [hfd.id]: hfd
    }
  }),
  [updateHfdAction]: (state, { payload: { id, hfd: updatedHfd }}) => {
    const currentHfd = id === state.currentHfd?.id
      ? {...state.currentHfd, ...updatedHfd}
      : state.currentHfd
    return {
      ...state,
      hfds: state.hfds.map(hfd => hfd.id === id? updatedHfd : hfd),
      hfdsLib: {
        ...state.hfdsLib,
        [id]: {
          ...state.hfdsLib[id], ...updatedHfd
        }
      },
      currentHfd
    }
  },
  [removeHfdsAction]: (state, { payload: { ids }}) => {
    const hfds = state.hfds.filter(hfd => !ids.includes(hfd.id))
    return (
      {
        ...state,
        hfds,
        hfdsLib: makeLib({data: hfds})
      })
  },
  [CLEAN_SPACE]: () => initialState,
  [setPagingAction]: (state, { payload: { paging } }) => ({
    ...state,
    paging
  })
})

/* === DISPATCHERS === */
export const getUserHfds = () => {
  return async dispatch => {
    try {
      const response = await getHfdRequest()
      const { data: hfds, paging } = response
      dispatch(setHfdsAction({hfds}))
      if (paging) dispatch(setPaging({paging}))
      return hfds
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "High Frequency Data credentials could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const getNextHfds = () => {
  return async (dispatch, getState) => {
    const {
      hfds: {
        hfds,
        paging: oldPaging
      }
    } = getState()
    const { data, paging } = await getHfdRequest({next_cursor: oldPaging.next_cursor})
    dispatch(setPaging({paging}))
    dispatch(setHfdsAction({ hfds: [...hfds, ...data]}))
  }
}

export const getHfd = (id) => {
  return async dispatch => {
    try {
      if (!id) {
        dispatch(setHfdAction({id, hfd: undefined}))
        return
      }
      const response = await getHfdDetailsRequest(id)
      const { data: hfd } = response
      dispatch(setHfdAction({hfd}))
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "High Frequency Data credentials could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const clearCurrentHfd = () => {
  return dispatch => {
    dispatch(setHfdAction({hfd: undefined}))
  }
}

export const createHfd = (hfd) => {
  return async dispatch => {
    try {
      const newHfd = await createHfdRequest(hfd)
      dispatch(addHfdAction({hfd: newHfd}))
      return newHfd.id
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "High Frequency Data credentials could not be created",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const updateHfd = (id, hfd) => {
  return async dispatch => {
    try {
      const updatedHfd = await updateHfdRequest(id, hfd)
      return dispatch(updateHfdAction({id, hfd: updatedHfd}))
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "High Frequency Data credentials could not be updated",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const deleteHfds = (ids) => {
  return async dispatch => {
    try {
      // TODO: we might need to pace request as we are doing with Things
      const responses = ids.map(async name => {
        return deleteHfdRequest(name)
      })
      await Promise.all(responses)
      dispatch(removeHfdsAction({ids}))
  
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Delete Successful",
        subtext: `Successfully deleted ${ids.length} High Frequency Data credential${ids.length !== 1 ? "s" : ""}`,
        type: MESSAGE_TYPE_SUCCESS,
        timeout: 4000
      })
  
      return true
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: `${ids.length===1? "High Frequency Data credentials": "Some High Frequency Data credentials"} could not be deleted`,
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
      return false
    }
  }
}

/* === UTILS === */

export const setPaging = ( {paging} ) => {
  return dispatch => dispatch(setPagingAction({paging}))
}