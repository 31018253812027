/* === IMPORTS === */
import { createReducer } from "@reduxjs/toolkit"

import { CLEAN_SPACE } from "actions/auth"
import { runActions } from "reduxModules/things"
import { generateCorrelationId } from "utils/clustersBeta"
import {
  addMessage,
  GLOBAL_NOTIFICATIONS,
  MESSAGE_TYPE_ERROR,
} from "utils/notifications"
// import {} from 'utils/apiClient'

import { makeActions } from "./utiliducks"

/* === ACTIONS === */
const actionList = [
  "setClientRequestAction",
  "setClientResponseAction"
]

const {
  setClientRequestAction,
  setClientResponseAction
} = makeActions("apiClient", actionList)

/* === INITIAL STATE === */
const initialState = {}

/* === REDUCER ===*/
export default createReducer(initialState, {
  [setClientRequestAction]: (state, { payload: { thingId, requestMethod, href, requestBody, correlationId } }) => ({
    ...state,
    [thingId]: {
      ...state[thingId],
      requestMethod,
      href,
      requestBody,
      correlationId,
      status: "",
      response: ""
    }
  }),
  [setClientResponseAction]: (state, { payload: { thingId, status, response } }) => ({
    ...state,
    [thingId]: {
      ...state[thingId],
      status,
      response
    }
  }),
  [CLEAN_SPACE]: () => initialState
})

/* === DISPATCHERS === */
export const sendAction = ({thingId, requestMethod, href, requestBody}) => {
  return async (dispatch, getState) => {
    const {
      things: {
        currentThing: clusterThing
      }={}
    } = getState()
    // confirm given thingId and currentThing uid match
    if (clusterThing.uid !== thingId) {
      // throw some kind of error
      // maybe even re-get and re-send the command?
      return
    }

    // create correlationId
    const correlationId = generateCorrelationId("request")

    let apiAction = {
      correlationId,
      requestMethod,
      href,
    }
    // don't send requestBody if empty
    if (requestBody) apiAction.requestBody = requestBody

    // make action
    const action = {
      "send-api": {
        input: apiAction
      }
    }

    try {
      // send the action
      await dispatch(runActions(clusterThing, action))
      // check resp
      dispatch(setClientRequestAction({ thingId, requestMethod, href, requestBody, correlationId }))
    }
    catch (e) {
      // alert an error with the command
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Error sending request.",
        subtext: e.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const setResponse = ({ thingId, correlationId, status, response }) => {
  return (dispatch, getState) => {
    const {
      apiClient: {
        [thingId]: {
          correlationId: verifyId
        }={}
      }={}
    } = getState()

    // exit if correlationId doesn't match
    if (verifyId !== correlationId) return
    dispatch(setClientResponseAction({ thingId, status, response }))
  }
}
