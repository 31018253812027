import React from "react"
import {connect} from "react-redux"

import { checkAuthentication, navigateToLastRoute } from "actions/auth"
import Spinner from "components/common/Spinner"

/**
 * Component intended for checking authentication state after login and redirected to requested url
 */
class Authenticate extends React.Component {

  async componentDidMount() {
    const { checkAuthentication, navigateToLastRoute } = this.props
    const isAuthenticated = await checkAuthentication()
    if (isAuthenticated) navigateToLastRoute()
  }

  render() {
    return (
      <Spinner size="large"/>
    )
  }
}

export default connect(null, { checkAuthentication, navigateToLastRoute })(Authenticate)
