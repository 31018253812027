import React from "react"
import styled from "styled-components"

const StyledLogoContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 32px;
width: 32px;
min-height: 32px;
min-width: 32px;
padding: 8px;

${({ collapsed }) => collapsed ? "" : "border-right: 1px solid #ABB3B5;"}

.logo {
  display: flex;
  height: 12px;
  width: 12px;

  & img {
    min-width: 0px;
    flex: 1 1 0%;
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
}
`
const StyledHeaderContainer = styled.div`
display: flex;
align-items: center;
overflow: hidden;

.header {
  font-size: 14px;
  font-weight: 700;
  padding-inline: 12px;
}
`

const UnitySidebarHeader = ({
  customHeader = null,
  logo = "",
  collapsed = false,
  header = "",
  subHeader = null,
}) => {

  if (customHeader) {
    return (
      <>
        {customHeader}
        {subHeader(collapsed)}
      </>
    )
  }

  return (
    <>
      <StyledHeaderContainer>
        <StyledLogoContainer collapsed={collapsed}>
          {logo ?
            <div className="logo">
              <img src={logo} alt="Logo" />
            </div> : null}
        </StyledLogoContainer>
        {collapsed ? null :
          <div className="header">
            {header}
          </div>}
      </StyledHeaderContainer>
      {subHeader(collapsed)}
    </>
  )
}

export default UnitySidebarHeader