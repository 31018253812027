import { del, get, post, put } from "./api"

export async function getVariablesRequest() {
  const { data } = await get("/variables")
  return data
}

export async function getVariableRequest(id) {
  const { data } = await get(`/variables/${id}`)
  return data
}

export async function createVariableRequest(body) {
  const { data } = await post("/variables", body)
  return data
}

export async function updateVariableRequest(id, body) {
  const { data } = await put(`/variables/${id}`, body)
  return data
}

export async function deleteVariablesRequest(params) {
  return await del("/variables", params)
}
