import {
  UnityButton,
  UnityPageHeader,
  UnityTextInput,
  UnityTypography
} from "@bit/smartworks.unity-react.unity-core-react"
import { LazyLog } from "controlled-lazylog"
import moment from "moment"
import React, {useMemo, useState} from "react"

import {copyToClipboard, prettyJSONCompact} from "utils/misc"
import {
  addMessage,
  GLOBAL_NOTIFICATIONS,
  MESSAGE_TYPE_SUCCESS,
  MESSAGE_TYPE_WARNING
} from "utils/notifications"
import {updateMqttMessageLimit} from "utils/utilityBelt"
import "./topic-details.css"


const LAST_MESSAGE = "LAST_MESSAGE"
const MESSAGE_HISTORY = "MESSAGE_HISTORY"
const MESSAGE_LIMIT_DEFAULT = 10

//TODO: move to util
//Stringifies json messages, returns raw message if string
const formatMessage = (message) => {
  if (typeof(message) === "object") {
    return prettyJSONCompact(message)
  } else {
    return message
  }
}

const formatTimestamp = (timestamp) => {
  return moment(timestamp).format()
}

const generateLogs = (messages) => {
  return messages.map(({
    message,
    meta: {timestamp}={}
  }) => `\u001b[30m${formatTimestamp(timestamp)}\u001b[0m\n${formatMessage(message)}`).join("\n\n")
}

//Renders tree menu of topics
const TopicDetails = ({
  topic="",
  messages=[]
}) => {
  const [openPanel, setOpenPanel] = useState(LAST_MESSAGE)
  const [messageLimit, setMessageLimit] = useState(MESSAGE_LIMIT_DEFAULT)
  const [tempMessageLimit, setTempMessageLimit] = useState(MESSAGE_LIMIT_DEFAULT)
  const [follow, setFollow] = useState(true)
  const [messageLog, setMessageLog] = useState("")
  const [needsUpdate, setNeedsUpdate] = useState(false)

  //As new messages come in, only update messageLog if follow is enabled (scrolled to bottom of logs), or if topic or messageLimit has changed
  useMemo(() => {
    if (follow || needsUpdate) {
      const nextLog = generateLogs(messages)
      setMessageLog(nextLog)
      setNeedsUpdate(false)
      return nextLog
    } else {
      return messageLog
    }
  }, [messages, follow])

  //Update messageLog any time topic or messageLimit changes
  useMemo(() => {
    setNeedsUpdate(true)
    return true
  }, [messageLimit])

  useMemo(() => {
    const nextLog = generateLogs(messages)
    setMessageLog(nextLog)
    setNeedsUpdate(false)
    return nextLog
  }, [topic])

  const {
    message: lastMessage,
    meta: lastMeta={}
  } = messages[messages.length - 1] || {}

  const getFormattedTopic = () => {
    //TODO: format correctly
    return topic
  }

  const handleOpenPanel = (panel) => {
    if(openPanel === panel) setOpenPanel(null)
    else setOpenPanel(panel)
  }
  const handleOpenLastMessage = (e) => {
    handleOpenPanel(LAST_MESSAGE)
    e.preventDefault()
  }

  const handleOpenHistory = (e) => {
    handleOpenPanel(MESSAGE_HISTORY)
    e.preventDefault()
  }

  const handleLastMessageCopy = () => {
    try {
      const msgString = formatMessage(lastMessage)
      copyToClipboard(msgString)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        type: MESSAGE_TYPE_SUCCESS,
        text: "Copied payload to clipboard",
        timeout: 2000
      })
    } catch(err) {
      console.error(err)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        type: MESSAGE_TYPE_WARNING,
        text: "Failed to copy payload",
        subtext: err,
        timeout: 4000
      })
    }
  }

  const handleCopySelectedTopic = () => {
    const formattedTopic = getFormattedTopic()
    try {
      copyToClipboard(formattedTopic)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        type: MESSAGE_TYPE_SUCCESS,
        text: "Copied text to clipboard",
        timeout: 2000
      })
    } catch(err) {
      console.error(err)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        type: MESSAGE_TYPE_WARNING,
        text: "Failed to copy topic",
        subtext: err,
        timeout: 4000
      })
    }
  }

  const updateMessageLimit = () => {
    let limit = parseInt(tempMessageLimit)

    //limit can be set to a minimum of 0
    if (limit < 0) {
      limit = 0
    } else if (Number.isNaN(limit)) {
      //if entered text is not a number, reset limit to default
      limit = MESSAGE_LIMIT_DEFAULT
    }
    setMessageLimit(limit)
    updateMqttMessageLimit(limit)
  }

  const getPanelStyle = (panel) => {
    let panelStyle = {...styles.panel}

    if (panel === openPanel) {
      panelStyle = {
        ...panelStyle,
        ...styles.openPanel
      }
    }
    return panelStyle
  }

  const getHeaderStyles = (isOpen) => {
    let headerStyle = {...styles.header}

    if (isOpen) {
      headerStyle = {
        ...headerStyle,
        ...styles.noBorderHeader
      }
    }

    return headerStyle
  }

  return (
    <div style={styles.container} id="mqtt-inspector-topic-details">
      <UnityPageHeader
        style={{...styles.header, ...styles.topicHeader}}
        rightAction={<>
          <UnityButton
            id="selected-topic-copy-button"
            type="borderless"
            centerIcon={"unity:copy"}
            style={styles.headerRightButton}
            onClick={handleCopySelectedTopic}
          />
        </>}
        centerContent={<div style={styles.topicHeaderContent}>
          <div style={styles.topicHeaderLeft}>
            <UnityTypography style={styles.topicHeaderTitle}>Topic</UnityTypography>
            <UnityTypography style={styles.topicHeaderTopic} id={"mqtt-inspector-topic"}>
              {getFormattedTopic()}
            </UnityTypography>
          </div>
        </div>}
        onClick={handleOpenHistory}
      />
      <div style={getPanelStyle(LAST_MESSAGE)}>
        <div style={styles.headerContainer} onClick={handleOpenLastMessage}>
          <UnityPageHeader
            style={getHeaderStyles(openPanel === LAST_MESSAGE)}
            rightAction={<>
              <UnityButton
                id="last-value-copy-button"
                type="borderless"
                centerIcon={"unity:copy"}
                style={styles.headerRightButton}
                onClick={handleLastMessageCopy}
              />
              <UnityButton
                id="last-value-collapse-toggle-button"
                type="borderless"
                centerIcon={openPanel === LAST_MESSAGE ? "unity:down_chevron" : "unity:up_chevron"}
                style={styles.headerRightButton}
                onClick={handleOpenLastMessage}
              />
            </>}
            centerContent={<div style={styles.headerContent}>
              <UnityTypography style={styles.headerTitle}>Last Value</UnityTypography>
              {lastMeta.timestamp &&
                <UnityTypography style={styles.headerDetails}>Time: {formatTimestamp(lastMeta.timestamp)}</UnityTypography>
              }
              {Object.prototype.hasOwnProperty.call(lastMeta, "qos") &&
                <UnityTypography style={styles.headerDetails}>QoS: {lastMeta.qos}</UnityTypography>
              }
            </div>}
          />
        </div>
        {openPanel === LAST_MESSAGE && <div style={styles.panelBody} className="mqtt-log">
          {lastMessage &&
            <LazyLog
              text={formatMessage(lastMessage)}
              follow={false}
              selectableLines={true}
              rowHeight={16}
            />
          }
        </div>}
      </div>

      <div style={getPanelStyle(MESSAGE_HISTORY)}>
        <div style={styles.headerContainer} onClick={handleOpenHistory}>
          <UnityPageHeader
            style={getHeaderStyles(openPanel === MESSAGE_HISTORY)}
            rightAction={<>
              <UnityTextInput
                value={tempMessageLimit}
                prefixedText="limit:"
                onChange={(e, val) => {
                  setTempMessageLimit(val)
                }}
                onBlur={updateMessageLimit}
                style={styles.messageLimit}
                hideErrors={true}
              />
              <UnityButton
                id="message-history-collapse-toggle-button"
                type="borderless"
                centerIcon={openPanel === MESSAGE_HISTORY ? "unity:down_chevron" : "unity:up_chevron"}
                style={styles.headerRightButton}
                onClick={handleOpenHistory}
              />
            </>}
            centerContent={<div style={styles.headerContent}>
              <UnityTypography style={styles.headerTitle}>History</UnityTypography>
              <UnityTypography style={styles.headerDetails}>({messages.length} {messages.length === 1 ? "message" : "messages"})</UnityTypography>
            </div>}
            onClick={handleOpenHistory}
          />
        </div>
        {openPanel === MESSAGE_HISTORY && <div style={styles.panelBody}>
          <div style={{flex: 1, height: "100%"}} className="mqtt-log">
            {messageLog && <LazyLog
              text={messageLog}
              // searchText={searchText}
              // filterActive={activeFilter}
              follow={follow}
              selectableLines={true}
              onScroll={(e={}) => {
                const {clientHeight, scrollHeight, scrollTop} = e

                if (!follow && !clientHeight && !scrollHeight && !scrollTop) {
                  setFollow(true)
                  return
                }

                if (scrollHeight === 0 && scrollTop === 0 && follow) {
                  //Do not turn off follow when new data is added
                  setFollow(true)
                  return
                }
                if ((clientHeight + scrollTop) === scrollHeight) {
                  setFollow(true)
                } else {
                  setFollow(false)
                }
              }}
              rowHeight={16}
            />}
          </div>
        </div>}
      </div>
    </div>
  )
}

export default TopicDetails

const commonTextStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    width: "100%",
    minWidth: 0,
    overflow: "hidden"
  },
  selectedTopic: {
    borderBottom: "1px solid var(--gray-color)",
    paddingLeft: 12,
    display: "flex",
    alignItems: "center",
    ...commonTextStyle
  },
  panel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch"
  },
  openPanel: {
    flex: 1
  },
  header: {
    cursor: "pointer",
    "--left-content-flex": 1
  },
  noBorderHeader: {
    "--page-header-border": "none"
  },
  headerRightButton: {
    flexShrink: 0,
    "--background-color": "rgba(0,0,0,0)"
  },
  headerContent: {
    flex: "1 1 auto",
    minWidth: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },

  headerTitle: {
    ...commonTextStyle,
    "--font-weight": "600"
  },
  headerDetails: {
    ...commonTextStyle,
    marginLeft: 8,
    "--font-size": "10px"
  },
  topicHeader: {
    cursor: "inherit",
    "--page-header-background-color": "var(--light-gray-2-color)",
    "--left-content-min-width": 0
  },
  topicHeaderContent: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minWidth: 0
  },
  topicHeaderLeft: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    minWidth: 0
  },
  topicHeaderTitle: {
    "--font-weight": "600",
    flexShrink: 0,
    marginRight: 12
  },
  topicHeaderTopic: {
    ...commonTextStyle,
    "--font-size": "10px",
    minWidth: 0
  },
  panelBody: {
    flex: 1,
    "--active-color": "var(--light-grey-text-color)",
    "--gutter-color": "var(--medium-grey-background-color)",
    "--fold-color": "var(--medium-grey-text-color)",
    borderBottom: "1px solid var(--gray-color)"
  },
  finalPaneBody: {
    borderBottom: "none"
  },
  publishTopicInput: {
    "--unity-text-input-height": "20px",
    "--input-prefixed-text-padding": "4px",
    flex: 1,
    marginLeft: 12
  },
  messageLimit: {
    "--unity-text-input-height": "20px",
    "--input-prefixed-text-padding": "4px",
    width: 75
  }
}