import { del, get, patch, post, put } from "utils/api"

export const CLIENT_CREDENTIALS = "client_credentials"
export const AUTH_CODE = "authorization_code"

export async function getAppsRequest(params={}) {
  const response = await get("/apps", params)
  const {
    status,
    data,
    data: {
      collection
    }={}
  } = response
  if(status === 200) {
    return collection ? collection : data // for backwards compatibility
  }
  else {
    return []
  }
}

export async function getAppRequest(id) {
  const response = await get(`/apps/${id}`)
  const {
    status,
    data
  } = response
  if(status === 200) {
    return data
  }
  else {
    return {}
  }
}

export async function createAppRequest(app) {
  const response = await post("/apps", app)
  const {
    status,
    data
  } = response
  if (status === 200) return data
  throw response
}

export async function updateAppRequest(id, app) {
  const response = await put(`/apps/${id}`, app)
  const {
    status,
    data
  } = response
  if (status === 200) return data
  throw response
}

export async function updateAppPropertiesRequest(id, newProperties) {
  const response = await patch(`/apps/${id}`, newProperties)
  const {
    status,
    data
  } = response
  if (status === 200) return data
  throw response
}

export async function deleteAppRequest(id) {
  const response = await del(`/apps/${id}`)
  
  //TODO: This is temporarily commented out until api response changes
  // if (response.status !== 204) throw response // delete app requests return null when successful, and an error object on failure
  if (response) throw response //TODO: replace this line with the one above when api response changes
  return response
}

export async function resetSecretRequest(id) {
  const response = await post(`/apps/${id}/reset-secret`)
  const {
    status,
    data
  } = response || {}
  if (status === 200) return data
  throw new Error("API request failed", response)
}

//Format auth_code_config returned from api for form
export const formatAuthCodeConfig = (auth_code_config={}) => {
  const {
    allowed_cors_origins=[],
    post_logout_redirect_uris=[],
    redirect_uris=[],
    token_endpoint_auth_method="client_secret_post"
  } = auth_code_config
  const joinStr = ", "

  return {
    ...auth_code_config,
    allowed_cors_origins: allowed_cors_origins?.join(joinStr) || "",
    post_logout_redirect_uris: post_logout_redirect_uris?.join(joinStr) || "",
    redirect_uris: redirect_uris?.join(joinStr) || "",
    token_endpoint_auth_method
  }
}

//Format auth_code_config returned from form for API
// convert comma/space separated strings into arrays
export const formatAuthCodeConfigForApi = (auth_code_config={}) => {
  const {
    allowed_cors_origins="",
    post_logout_redirect_uris="",
    redirect_uris=""
  } = auth_code_config
  //split uris on commas and/or spaces
  const uriListRegex = /,+\s*|\s+/
  const nextConfig = {...auth_code_config}

  nextConfig.allowed_cors_origins = allowed_cors_origins
    ? allowed_cors_origins.split(uriListRegex)
    : []
  nextConfig.post_logout_redirect_uris = post_logout_redirect_uris
    ? post_logout_redirect_uris.split(uriListRegex)
    : []
  nextConfig.redirect_uris = redirect_uris
    ? redirect_uris.split(uriListRegex)
    : []

  return nextConfig
}
