/* === IMPORTS === */
import { createReducer } from "@reduxjs/toolkit"
import { isEqual } from "lodash"

import { CLEAN_SPACE } from "actions/auth"
import { makeLib } from "utils/misc"
import {
  addMessage,
  GLOBAL_NOTIFICATIONS,
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS
} from "utils/notifications"
import { createPolicyRequest, deletePoliciesRequest, getPoliciesRequest, updatePoliciesRequest, updatePolicyRequest } from "utils/policies"


import { makeActions } from "./utiliducks"

/* == ACTIONS === */
const actionList = [
  "setPoliciesAction",
  "addPolicyAction",
  "addPoliciesAction",
  "updatePolicyAction",
  "updatePoliciesAction",
  "removePoliciesAction"
]
const {
  setPoliciesAction,
  addPolicyAction,
  addPoliciesAction,
  updatePolicyAction,
  updatePoliciesAction,
  removePoliciesAction
} = makeActions("policies", actionList)

/* === INITIAL STATE === */
const initialState = {
  policies: [],
  policiesLib: {}
}

/* === Reducer === */
export default createReducer(initialState, {
  [setPoliciesAction]: (state, { payload: { policies }}={}) => ({
    ...state,
    policies,
    policiesLib: makeLib({data: policies})
  }),
  [addPolicyAction]: (state, { payload: { policy }}) => ({
    ...state,
    policies: [
      ...state.policies,
      policy
    ],
    policiesLib: {
      ...state.policiesLib,
      [policy.id]: policy
    }
  }),
  [addPoliciesAction]: (state, { payload: { policies }}) => ({
    ...state,
    policies: [
      ...state.policies,
      ...policies
    ],
    policiesLib: {
      ...state.policiesLib,
      ...makeLib({data: policies})
    }
  }),
  [updatePolicyAction]: (state, { payload: { id, policy: updatedPolicy }}) => ({
    ...state,
    policies: state.policies.map(policy => policy.id === id? updatedPolicy : policy),
    policiesLib: {
      ...state.policiesLib,
      [id]: {
        ...state.policiesLib[id], ...updatedPolicy
      }
    }
  }),
  [updatePoliciesAction]: (state, { payload: { policies }}) => ({
    ...state,
    policies: state.policies.map((policy) => policies.find(p => p.id === policy.id) ?? policy),
    policiesLib: {
      ...state.policiesLib,
      ...makeLib({ data: policies })
    }
  }),
  [removePoliciesAction]: (state, { payload: { ids }}) => {
    const policies = state.policies.filter(policy => !ids.includes(policy.id))
    return (
      {
        ...state,
        policies,
        policiesLib: makeLib({data: policies})
      })
  },
  [CLEAN_SPACE]: () => initialState
})

/* === DISPATCHERS === */
export const getUserPolicies = () => {
  return async dispatch => {
    try {
      const response = await getPoliciesRequest({limit: 1000})
      const { data } = response
      const policies = data? data : response // preserve backwards compatibility
      return dispatch(setPoliciesAction({policies}))
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Policies could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const createPolicy = (policy, {silenceNotifications=false}={}) => {
  return async dispatch => {
    try {
      const newPolicy = await createPolicyRequest(policy)
      dispatch(addPolicyAction({policy: newPolicy}))
      return newPolicy
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      if (!silenceNotifications) {
        addMessage({
          target: GLOBAL_NOTIFICATIONS,
          text: "Policy could not be created",
          subtext: error.status === 403 ? "Wrong resource" : error.message, // changed message according to resp.status for Apps policies
          type: MESSAGE_TYPE_ERROR
        })
      }
    }
  }
}

export const createPolicies = (policies, { silenceNotifications=false }={}) => {
  return async dispatch => {
    try {
      const { has_errors, results } = await createPolicyRequest(policies)
      const successful = results.filter(r => r.status === 201).map(r => r.response)
      if(!silenceNotifications) {
        if (has_errors.length) {
          addMessage({
            text: "Some policies could not be created",
            type: MESSAGE_TYPE_ERROR
          })
        }
        else {
          addMessage({
            text: "Policies created successfully",
            type: MESSAGE_TYPE_SUCCESS
          })
        }
      }
      dispatch(addPoliciesAction({policies: successful}))
      return !has_errors.length
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      if (!silenceNotifications) {
        addMessage({
          text: "Policies could not be created",
          type: MESSAGE_TYPE_ERROR
        })
      }
    }
  }
}

export const updatePolicy = (policy, newPolicy) => {
  return async dispatch => {
    try {
      const updatedPolicy = await updatePolicyRequest(policy.id, {...policy, ...newPolicy})
      dispatch(updatePolicyAction({id: policy.id, policy: updatedPolicy}))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Policy updated successfully",
        type: MESSAGE_TYPE_SUCCESS,
        timeout: 4000
      })
      return
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Policy could not be updated",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const updatePolicies = (policies, { silenceNotifications=false }={}) => {
  return async dispatch => {
    try {
      const { results, has_errors} = await updatePoliciesRequest(policies)
      const successful = results.filter(r => r.status === 200).map(r => r.response)

      if(!silenceNotifications) {
        if (has_errors.length) {
          addMessage({
            text: "Some policies could not be updated",
            type: MESSAGE_TYPE_ERROR
          })
        }
        else {
          addMessage({
            text: "Policies updated successfully",
            type: MESSAGE_TYPE_SUCCESS
          })
        }
      }

      dispatch(updatePolicyAction({ policies: successful }))
      return !has_errors.length
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      if (!silenceNotifications) addMessage({
        text: "Policies could not be updated",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}
export const updatePoliciesList = (creatingResource, policies, subject, updatedPolicies, showNotification=true) => {
  return async dispatch => {
    if (creatingResource) {
      dispatch(createPolicies(updatedPolicies.map(p => ({...p, subject})), { silenceNotifications: true }))
    }
    else {
      // get arrays of created, updated and deleted policies
      const initialPoliciesObject = makeLib({ data: policies })
      const policiesObject = makeLib({ data: updatedPolicies })
      const toDelete = policies.filter(({ id, subject: policySubject }) => !policiesObject[id] && policySubject === subject)

      const toUpdate = updatedPolicies.filter(({ id }) => initialPoliciesObject[id] && !isEqual(initialPoliciesObject[id], policiesObject[id]))
      const toCreate = updatedPolicies.filter(p => p.id.includes("custom"))

      // make API requests
      let deleteSuccessful =true, updateSuccessful=true, createSuccessful = true
      if (toDelete.length) deleteSuccessful = await dispatch(deletePolicies(toDelete.map(({id}) => id), { silenceNotifications: true}))
      if (toUpdate.length) updateSuccessful = await dispatch(updatePolicies(toUpdate, { silenceNotifications: true}))
      if (toCreate.length) createSuccessful = await dispatch(createPolicies(toCreate.map(p => {
        const { id, ...newPolicy } = p
        return ({ ...newPolicy, subject })}
      ), { silenceNotifications: true}))
      
      if (showNotification){
        if(deleteSuccessful && updateSuccessful && createSuccessful) {
          addMessage({
            text: "Policies updated successfully",
            type: MESSAGE_TYPE_SUCCESS
          })
        }
        else {
          addMessage({
            text: "Some policies could not be updated",
            type: MESSAGE_TYPE_ERROR
          })
        }
      }
    }
  }
}

export const deletePolicies = (ids, { silenceNotifications=false }={}) => {
  return async dispatch => {
    try {
      const { has_errors } = await deletePoliciesRequest({ "policyID[]": ids })
      if (has_errors.length) {
        if (!silenceNotifications) addMessage({
          text: `${ids.length===1? "Policy": "Some policies"} could not be deleted`,
          type: MESSAGE_TYPE_ERROR
        })
      }
      else {
        dispatch(removePoliciesAction({ids}))
        if(!silenceNotifications) addMessage({
          text: `${ids.length===1? "Policy": "Policies"} deleted successfully`,
          type: MESSAGE_TYPE_SUCCESS,
        })
      }
      return !has_errors.length
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      if (!silenceNotifications) addMessage({
        text: `${ids.length===1? "Policy": "Some policies"} could not be deleted`,
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

/* === UTILS === */
