import { UnityTypography } from "@bit/smartworks.unity-react.unity-core-react"
import moment from "moment"
import React, { Component } from "react"

class DateInput extends Component {
  render() {
    const {
      input,
      value,
      label,
      id,
      disabled,
      meta: {
        pristine=true,
        error=""
      }={},
      style,
      onChange
    } = this.props
    return (
      <div style={style}>
        <UnityTypography><p style={styles.label}>{label}</p></UnityTypography>
        <input
          id={id}
          disabled={disabled}
          {...input}
          type='date'
          value={value || moment().format("YYYY-MM-DD")}
          onChange={(e) => onChange(e.target.value)}
        >
        </input>
        {error && !pristine && <UnityTypography style={styles.error}>{error}</UnityTypography>}
      </div>
    )
  }
}

const styles = {
  error: {
    "--font-color": "var(--tertiary-1-color)"
  },
  label: {
    margin: 0,
    marginBottom: "3px"
  }
}
export default DateInput
