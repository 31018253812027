import { BOUNDARIES_ENABLED } from "constants/featureFlags"
import { del, get, post, put } from "utils/api"

export async function getFunctionsRequest(params) {
  const response = await get("/functions", params)
  const { status, data } = response
  if (status === 200) return data
  throw new Error(`Request failed with status ${response.status} - ${response.data}`)
}

export async function getFunctionCodeRequest(name) {
  const response = await get(`/functions/${name}/code`)
  const { status, data } = response
  if (status === 200) return data
  throw new Error(`Request failed with status ${response.status} - ${response.data}`)
}

export async function getTemplateLibrariesRequest(template) {
  const response = await get(`/function-templates/${template}`)
  const { status, data } = response
  if (status === 200) return data
  throw new Error(`Request failed with status ${response.status} - ${response.data}`)
}

export async function createFunctionRequest(functionData) {
  const response = await post("/functions", functionData)
  const { status, data } = response
  if (status !== 200) throw new Error(`Create Function failed with status ${response.status} - ${response.data}`)
  return data
}

export async function invokeFunctionRequest(name, body, async=false) {
  const endpoint = `/functions/${name}/${async ? "async-" : ""}invoke`
  const response = await post(endpoint, body)
  const { status, data } = response
  if (status === 200) return data
  throw new Error(`Request failed with status ${response.status} - ${response.data}`)
}

export async function setFunctionCodeRequest(name, code) {
  const response = await post(`/functions/${name}/code`, { code })
  const { status, data } = response
  if(status === 200) return data
  throw new Error(`Request failed with status ${response.status} - ${response.data}`)
}

export async function updateFunctionRequest(name, functionData) {
  const response = await put(`/functions/${name}`, functionData)
  const { status, data } = response
  if (status === 200) return data
  throw new Error(`Request failed with status ${response.status} - ${response.data}`)
}

export async function deleteFunctionRequest(name) {
  const response = await del(`/functions/${name}`)
  // if (response.status !== 204) throw response // delete user requests return null when successful, and an error object on failure
  if (response) throw response //TODO: replace this line with the one above when api response changes
  return response
}

export async function getFunctionLogsRequest(name, tail, since) {
  const response = await get(`/functions/${name}/logs`, {tail, since})
  const { status, data } = response
  if (status === 200) return data
  throw new Error(`Request failed with status ${response.status} - ${response.data}`)
}

export const initialColumns = [
  {
    key: "name",
    label: "Name"
  },
  {
    key: "description",
    label: "Description"
  },
  {
    key: "status",
    label: "Status"
  },
  {
    key: "Replicas",
    label: "Executions"
  },
  {
    key: "labels",
    label: "Labels"
  },
  {
    key: "language",
    label: "Language"
  },
  {
    key: "updated_at",
    label: "Modified"
  }
]

export const templateOptions = [
  {
    id: "fmu-http",
    label: "FMU"
  },
  {
    id: "golang-http",
    label: "Go"
  },
  // {
  // 	id: 'node14-http',
  //   label: 'JavaScript (NodeJS 14)'
  // },
  {
    id: "python3-http",
    label: "Python 3"
  }
]

export const editorModesByTemplateString = { // Use this to change template received from API to a valide editor mode
  "fmu-http": "fmu",
  "golang-http": "golang",
  "node14-http": "javascript",
  "python3-http": "python",
}


export const validateResources = (resources={}, max={}) => {
  const { cpu, memory, min_pods, max_pods } = resources
  const error = {}

  if(cpu === "") error.cpu = "Required"
  else if(isNaN(cpu)) error.cpu = "Must be a positive number"
  else if(cpu < 0.25) error.cpu = "Min CPU value is 0.25"
  else if(BOUNDARIES_ENABLED && max.function_cpus && cpu > max.function_cpus) error.cpu = `Max CPU value is ${max.function_cpus}`
  else if(!BOUNDARIES_ENABLED && cpu > 1) error.cpu = "Max CPU value is 1"

  if(memory === "") error.memory = "Required"
  else if(!Number.isInteger(memory) || memory <= 0 ) error.memory = "Must be a positive integer"

  if(min_pods === "") error.min_pods = "Required"
  else if(!Number.isInteger(min_pods) || min_pods < 1 || min_pods > 5) error.min_pods = "Must be a positive integer between 1 and 5"

  if(max_pods === "") error.max_pods = "Required"
  else if(!Number.isInteger(max_pods) || max_pods < 1 || max_pods > 5 ) error.max_pods = "Must be a positive integer between 1 and 5"
  else if(!isNaN(min_pods) && !isNaN(max_pods) && min_pods > max_pods) {
    error.min_pods = "Cannot be greater than maxPods"
    error.max_pods = "Cannot be smaller than minPods"
  }
  return error
}

export const validateScaleToZeroMinutes = (scaleZeroDurationField={}) => {
  let error = ""

  if(scaleZeroDurationField === "") error = "Required"
  else if(!Number.isInteger(scaleZeroDurationField) || scaleZeroDurationField <= 0 ) error = "Must be a positive integer"

  return error
}

export const parseNumber = value => {
  const parsedValue = Number(value)
  if(!value || isNaN(parsedValue)) return value
  else return parsedValue
}
