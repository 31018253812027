import { push } from "connected-react-router"

import { urlParse } from "utils/api"
import { routes } from "utils/routes"

export function navigate({ to, ...urlVars }) {
  return dispatch => {
    let url = urlParse({ url: routes[to] || to, ...urlVars })
    dispatch(push(url))
  }
}
