export const overrideDirtyColor = ({trackDirty, pristine, error, styles}) => {
  let style = {...styles}
  // change dirty color
  if (trackDirty && !pristine && !error) {
    style = {
      ...style,
      "--dirty-color": "var(--secondary-brand-color, var(--default-secondary-brand-color))"
    }
  }

  return style
}

export const inputStyle = {
  flex: 1
}

export const dropdownInputStyle = {
  ...inputStyle
}

export const areaInputStyle = {
  ...inputStyle,
  wordBreak: "break-word"
}

export const errorLabelStyles = {
  margin: "4px 0 0",
  color: "var(--tertiary-1-color)"
}
