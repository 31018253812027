import { Layout, Menu } from "antd"
import React, { useMemo, useState } from "react"
import styled from "styled-components"

import { DownChevronIcon, RightChevronIcon } from "assets/icons"
import { colors } from "assets/stylesheets/common"
import UnitySidebarHeader from "components/unity/UnitySidebar/UnitySidebarHeader"
import UnitySidebarTrigger from "components/unity/UnitySidebar/UnitySidebarTrigger"
const { Sider } = Layout

const StyledSider = styled(Sider)`
display: flex;
flex-direction: column;
padding-bottom: 0px!important;
background-color: white!important;
border-right: 1px solid #ABB3B5!important;
overflow: hidden;

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  height: 0;
  
  & .ant-menu-root {
    border: none!important;
    
    & * {
      border-radius: 0px!important;
      color: #4D4D4D!important;
      background-color: unset!important;
      line-height: 16px!important;
      transition: background-color 0s;

      &:hover {
        background-color: unset!important;
      }
    }

    &.ant-menu-vertical {
      & .ant-menu-item, 
      & .ant-menu-submenu-title {
        padding-left: 8px!important;
      }
    }

    &>li {
      border-top: 1px solid #ABB3B5!important;

      &:last-child {
        border-bottom: 1px solid #ABB3B5!important;
      }
    }

    & .ant-menu-submenu>ul {
      margin-top: 2px;
      margin-bottom: 6px;
    }

    & .ant-menu-item, 
    & .ant-menu-submenu-title {
      position: relative;
      background-color: white!important;
      margin: 0px!important;
      height: 40px!important;
      padding-block: 8px!important;
      padding-right: 12px!important;
      padding-left: 12px!important;
      width: 100%;
      display: flex;
      gap: 8px;
      align-items: center;

      &:hover {
        background-color: ${colors.lightGray2}!important;
      }
    }

    & .ant-menu-submenu-title {
      height: 40px!important;
    }
    /* 
      Selector for the selected blue bar,
      and the blue bar to show on root menu when closed
    */
    & .ant-menu-item-selected, 
    &>.ant-menu-submenu-selected:not(.ant-menu-submenu-open)>.ant-menu-submenu-title,
    &>.ant-menu-submenu-selected>.ant-menu>.ant-menu-submenu-selected:not(.ant-menu-submenu-open)>.ant-menu-submenu-title,
    &>.ant-menu-submenu-selected.ant-menu-submenu-vertical>.ant-menu-submenu-title {
      & * {
        color: #005776!important;
      }

      &:before {
        content: "";
        background-color: #005776;
        width: 4px;
        height: 100%;
        position: absolute;
        left: 0px;
        z-index: 1;
      }
    }

    & .nested-submenu-1 {
      height: 32px!important;
      &>div {
        height: 32px!important;
      }
      &.ant-menu-submenu-open {
        height: 100%!important;
      }
    }

    & .nested-submenu-2 {
      height: 32px!important;
      padding-left: 24px!important;
    }
  }

  & .icon {
    min-width: 16px;
    height: 16px;
    width: 16px;
    fill: #4D4D4D;
  } 
}

.ant-layout-sider-trigger {
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  padding: 4px;
  line-height: unset;
  background-color: white;
}
`

const StyledMenuContainer = styled.div`
  overflow: auto;
  scrollbar-width: none;
  display: flex; 
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`

const MenuWrapper = ({ collapsed, className, items, onSelect, selected}) => {
  const parsedItems = useMemo(() => {
    const parseItem = (nestedLevel) =>(item) => {
      const { children, ...rest } = item || {}

      let newItem = {
        ...rest,
        className: nestedLevel ? `nested-submenu-${nestedLevel}` : ""
      }

      if (children?.length) {
        newItem = {
          ...newItem,
          popupClassName: className,
          children: collapsed ? [{
            type: "group",
            label: rest.label,
            children: children.map(parseItem(nestedLevel + 1)),
          }] : children.map(parseItem(nestedLevel + 1)),
        }
      } else if (nestedLevel === 0) {
        newItem = {
          ...newItem,
          popupClassName: className,
        }

        if (collapsed) {
          newItem.children = [{
            type: "item",
            ...rest,
          }]
        }
      }

      return newItem
    }

    return items.map(parseItem(0))
  }, [items, collapsed])

  return (
    <Menu
      selectedKeys={[selected]}
      defaultSelectedKeys={[selected]}
      onSelect={({key}) => onSelect(key)}
      mode={collapsed ? "vertical" : "inline"}
      expandIcon={({ isOpen }) => isOpen ? <DownChevronIcon className="icon" /> : <RightChevronIcon className="icon" />}
      inlineIndent={0}
      items={parsedItems}
    />
  )
}

const StyledMenu = styled(MenuWrapper)`
box-shadow: 0px 1px 3px 1px #0000001F, 0px 1px 2px 0px #00000040;
padding: 0px!important;
border-radius: 0px!important;

& * {
  border-radius: 0px!important;
  line-height: 16px!important;
  background-color: white!important;
  color: #4D4D4D !important;
  transition: background-color 0s!important;
}

& .ant-menu-vertical {
  box-shadow: none!important;

  & .ant-menu-item-group-title {
    padding: 12px 8px!important;
    font-size: 12px;
    font-weight: 700;
  }


    & .ant-menu-submenu-title, 
    & .ant-menu-item {
      margin: 0px;
      padding: 12px 8px!important;
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      
      &.ant-menu-item-active, &.ant-menu-submenu-title-active {
        background-color: ${colors.lightGray2}!important;
        
        & * {
          background-color: ${colors.lightGray2}!important;
        }          
      }
    }
  
    & .ant-menu-item-selected,
    & .ant-menu-submenu-selected .ant-menu-submenu-title{
      & * {
        color: #005776!important;
      }
      
      &:before {
        content: "";
        background-color: #005776;
        width: 4px;
        height: 100%;
        position: absolute;
        left: 0px;
        z-index: 1;
      }
    }
   
    & .icon {
      min-width: 16px;
      height: 16px;
      width: 16px;
      fill: #4D4D4D;
    }
}
`

const UnitySidebar = ({
  items = {},
  logo = "",
  header = "",
  customHeader = null,
  collapsible = true,
  subHeader = null,
  collapsedWidth = 32,
  width = 230,
  onSelect,
  selected,
  bottomContent = () => null,
}) => {
  const [collapsed, setCollapsed] = useState(false)

  return (
    <StyledSider
      collapsible={collapsible}
      collapsed={collapsed}
      collapsedWidth={collapsedWidth}
      width={width}
      onCollapse={() => setCollapsed(!collapsed)}
      trigger={<UnitySidebarTrigger collapsed={collapsed} />}
      selected={selected}
      data-selected={selected}
    >
      {subHeader && <UnitySidebarHeader
        logo={logo}
        header={header}
        customHeader={customHeader}
        collapsed={collapsed}
        subHeader={subHeader}
      />
      }

      <StyledMenuContainer>
        <StyledMenu collapsed={collapsed} items={items?.top} onSelect={onSelect} selected={selected} />
        <StyledMenu collapsed={collapsed} items={items?.bottom} onSelect={onSelect} selected={selected} />
        {bottomContent(collapsed)}
      </StyledMenuContainer>
    </StyledSider>
  )
}

export default UnitySidebar