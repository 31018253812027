import { UnityButton } from "@bit/smartworks.unity-react.unity-core-react"
import React, { useState } from "react"
import NewWindow from "react-new-window"

import { PROXY_URL } from "constants/config"

const UserPopoverContentAltairOneStatusLinkButton = ({ label = ""}) => {
  const [isWindowOpen, setIsWindowOpen] = useState(false)
  
  return (
    <>
      <UnityButton
        id="link-altairone-account-button"
        onClick={() => setIsWindowOpen(!isWindowOpen)}
        label={label}
        style={styles.linkAltairOneAccountButton}
        type="secondary"
      />
      {isWindowOpen ?
        <NewWindow
          url={`${PROXY_URL}/boundaries/altairone/link`}
          onUnload={() => setIsWindowOpen(false)}
        />
        : null}
    </>
  )
}

const styles = {
  linkAltairOneAccountButton: {
    width: "fit-content",
    "--button-padding": "8px",
    "--unity-button-height": "24px",
  }
}

export default UserPopoverContentAltairOneStatusLinkButton