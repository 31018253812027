import MQTTPattern from "mqtt-pattern"

/**
 * @class
 * @name MessageVault
 * @classdesc Saves mqtt messages in a tree structure by topic path.
 *   Example of message topic:
 *     spaces/myAccountId/categories/myCategory/things/myThingId/actions/myActionName
 *   Exposes these methods: add, remove, setMessageLimit, updateSubscriptions, getTopicTableData, and getTopicMessages
 * @example of topicsTree:
    {
      children: {
        spaces: {
          messages: [],
          children: {
            myAccountId: {
              messages: [],
              children: {
                categories: {
                  messages: [],
                  children: {
                    '#': {
                      messages: [{
                        message: 'message payload',
                        meta: {qos: 0, retain: false, dup: false, timestamp: 1623095684358}
                      }]
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
 */
export default class MessageVault {
  /**
   * Create a messageVault instance.
   * @param {object} topicsTree - Initial topicsTree. optional
   * @param {number} messageLimit - Initial limit of message to be stored per topic. As new messages come in, old messages are removed. defaults to 10.
   */
  constructor({
    topicsTree={},
    messageLimit=10
  }={}) {
    this.topicsTree = topicsTree
    this.messageLimit = messageLimit
    this.rootNodeName = topicsTree?.name
    this.initialTopicsTree = topicsTree //Used as default after filtering tree
  }

  /**
   * Add message to message vault.
   * @param {string} topic - mqtt topic path the message was published over.
   * @param {any} message - message payload.
   * @param {Packet} meta - mqtt message meta data such as QoS, retain, duplicate, and timestamp
   */
  add(topic="", message, meta) {
    const topicPath = this.topicToPath(topic)

    //traverse tree down topic path, adding missing nodes, add message to leaf node
    this.traverse({
      topicPath,
      callback: (node, pathElement, index) => {
        const {
          children={},
          children: {
            [pathElement]: child={parent: node},
            [pathElement]: {
              messages: childMessages=[]
            }={},
          }={}
        } = node
        const isLastPathElement = index === topicPath.length - 1
        const topicPathSoFar = topicPath.slice(0, index + 1)
        //Update node with new child
        node.children = {
          ...children,
          [pathElement]: {
            ...child,
            topicPath: topicPathSoFar,
            topic: topicPathSoFar.join("/"),
            name: pathElement
          }
        }
    
        if (isLastPathElement) {
          //limit messages length to messageLimit
          const nextMessages = this.messageLimit <= 0 ? [] : [
            ...childMessages,
            {message, meta}
          ].slice(-this.messageLimit)
          node.children[pathElement].messages = nextMessages
        }
      }
    })
  }

  /**
   * Remove message to message vault. NOTE: WIP
   * @param {string} topic - mqtt topic path the message was published over.
   * @param {object} message - message payload.
   */
  remove(topic="", message) {
    const topicPath = this.topicToPath(topic)
    let nextNode //cache current node in traverse down topicPath for starting place to clean up empty leaf nodes
    //traverse tree down topic path, remove message from leaf node
    //then traverse back up the tree, removing empty nodes
    this.traverse({
      topicPath,
      callback: (node, pathElement, index) => {
        const {
          children: {
            [pathElement]: child={},
            [pathElement]: {
              messages: childMessages=[],
            }={},
          }={}
        } = node
        const isLastPathElement = index === topicPath.length - 1

        if (isLastPathElement) {
          // Remove message from child
          child.messages = childMessages.filter(sub => sub !== message)

          // Call onTopicRemoved callback if there are no more subscribers to the topic
          if (child.messages.length === 0) {
            // Unsubscribe from topic here!
            this.onTopicRemoved(topic)
          }
        }

        nextNode = child
      }
    })

    this.traverse({
      topicPath: topicPath.reverse(),
      initialNode: nextNode,
      reverseDirection: true,
      callback: (node, pathElement) => {
        const {
          parent={children: {}},
          children={},
          messages=[]
        } = node
        const hasChildren = Object.keys(children).length > 0
        const hasMessages = messages.length > 0

        if (!hasChildren && !hasMessages) {
          delete parent.children[pathElement]
        }
      }
    })
  }

  /**
   * Tally number of topics and number of messages for under a given node in topicsTree
   * @param {object} node - Node of topicsTree.
   * @returns {object} message - returns object with topic and message counts: {topics: 2, messages: 20}
   */
  countTopicsAndMessages(node) {
    let { children: childrenMap={}, messages=[] } = node || {}
    let children = Object.values(childrenMap)
    let messageCount = messages.length || 0
    let topicCount = 0

    //BFS traverse down node and tally up children (topics) and their messages
    for (let i = 0; i < children.length; i++) {
      const {
        children: subChildrenMap={},
        messages=[]
      } = children[i]
      const subChildren = Object.values(subChildrenMap)

      messageCount += messages.length
      if (subChildren.length === 0) {
        topicCount += 1
      }

      children = [
        ...children,
        ...subChildren
      ]
    }
    return {
      topics: topicCount,
      messages: messageCount
    }
  }

  /**
   * Given a topic string, return all messages matching that topic.
   * @param {string} topic - topic string.
   * @returns {object[]} messages - returns array of messages in chronological order.
   */
  getTopicMessages(topic) {
    const topicPath = this.topicToPath(topic)
    let currentNode

    this.traverse({
      topicPath: topicPath,
      callback: (node, pathElement) => {
        const {
          children: {
            [pathElement]: nextNode={}
          }={}
        } = node || {}
        currentNode = nextNode
      }
    })

    return this.getNodeMessages(currentNode)
  }

  /**
   * Given a topicsTree node, return all messages under that node.
   * @param {object} node - topicsTree node.
   * @returns {object[]} messages - returns array of messages in chronological order.
   */
  getNodeMessages(node) {
    let { children: childrenMap={}, messages=[] } = node || {}
    let children = Object.values(childrenMap)
    let allMessages = [...messages]

    //BFS traverse down node and tally up children messages
    for (let i = 0; i < children.length; i++) {
      const {
        children: subChildrenMap={},
        messages=[]
      } = children[i]
      const subChildren = Object.values(subChildrenMap)

      allMessages = [
        ...allMessages,
        ...messages
      ]

      children = [
        ...children,
        ...subChildren
      ]
    }

    //sort messages by timestamp and limit to messageLimit
    return this.sortMessages(allMessages).slice(-this.messageLimit)
  }

  /**
   * Sort array of messages in chronological by timestamp.
   * @param {object[]} messages - unordered array of messages.
   * @returns {object[]} sorted messages - returns array of messages in chronological order.
   */
  sortMessages = (messages) => {
    return messages.sort((a, b) => {
      return a?.meta?.timestamp - b?.meta?.timestamp
    })
  }

  /**
   * filters topicsTree to remove topics that are no longer subscribed to.
   * @param {string[]} subscriptions - Used to filter tree.
   * @returns {object} formatted topics tree
   */
  updateSubscriptions(subscriptions=[]) {
    this.topicsTree = subscriptions.length > 0
      ? this.filterSubscribedNodes(subscriptions)
      : {...this.initialTopicsTree}
  }

  /**
   * Format topicsTree into format for display. Children dictionary is converted to array, and some nodes are collapsed.
   * @param {string[]} subscriptions - If defined, used to filter tree so only matching nodes are returned.
   * @returns {object} formatted topics tree
   */
  getTopicTableData() {
    const {children={}} = this.topicsTree
    let rootChildren = {}

    for(let topic in children) {
      const child = children[topic]

      if (child.topic === "set" || child.topic === "status") {
        const modifiedChildren = {}
        const childChildren = child?.children || {}
        for(let childTopic in childChildren) {
          const childChild = childChildren[childTopic]
          modifiedChildren[`${child.name}/${childTopic}`] = {
            ...childChild,
            name: `${child.name}/${childTopic}`
          }
        }
        rootChildren = {
          ...rootChildren,
          ...modifiedChildren
        }
      } else {
        rootChildren = {
          ...rootChildren,
          [topic]: child
        }
      }
    }

    const collapsedTree = {
      ...this.topicsTree,
      children: rootChildren
    }

    return this.formatNode(collapsedTree)
  }

  //TODO: move out of class
  isRootNode = (node={}) => {
    if (node.name === this.rootNodeName) {
      return true
    }

    return false
  }

  topicMatchesSubscription = (topic="", subscriptions=[]) => {
    return subscriptions.some(subscription => {
      //Need to match incomplete topics to keep ancestor nodes of matches, ex: node topic `/spaces/main` should match subscription: `/spaces/main/clusters/#`
      //Truncate subscription topic to number of elements in topic
      const topicPath = this.topicToPath(topic)
      const subscriptionPath = this.topicToPath(subscription)
      const truncatedSubscription = topicPath.length < subscriptionPath.length
        ? subscriptionPath.slice(0, topicPath.length).join("/")
        : subscription

      return MQTTPattern.matches(truncatedSubscription, topic)
    })
  }

  /**
   * Filter topicsTree to only nodes which match subscriptions array. Used by getTopicTableData
   * @param {string[]} subscriptions - topics which have been subscribed to.
   * @returns {object} filtered topicsTree
   */
  filterSubscribedNodes = (subscriptions=[], node=this.topicsTree) => {
    const {children={}} = node || {}
    const isRoot = this.isRootNode(node)
    const nextChildren = {}

    //recurse down to reach leaf node
    for (let childTopic in children) {
      const child = children[childTopic]
      const filteredChild = this.filterSubscribedNodes(subscriptions, child)

      if (filteredChild) {
        nextChildren[child.name] = filteredChild
      }
    }

    //If leaf node, check if topic matches any of the subscription topics
    // OR if it all of its children have been filtered out, remove it
    const matchesSubscription = this.topicMatchesSubscription(node.topic, subscriptions)
    if (
      !isRoot &&
      (Object.keys(children).length === 0 || Object.keys(nextChildren).length === 0) &&
      !matchesSubscription
    ) {
      return undefined
    }

    return {
      ...node,
      children: nextChildren
    }
  }

  /**
   * Format a given node in topicsTree for display. Recursively called to format node children
   * @param {object} node - topicsTree node.
   * @returns {object} formatted node
   */
  formatNode = (node={}) => {
    const {
      topic,
      topicPath,
      name,
      children: childrenMap={},
      messages
    } = node

    const formattedNode = {
      topic,
      topicPath,
      name,
    }
    const children = Object.values(childrenMap)

    //if this is NOT a leaf node, add topic and message count
    if (children.length > 0) {
      const {
        topics: topicCount,
        messages: messageCount
      } = this.countTopicsAndMessages(node)

      formattedNode.topicCount = topicCount
      formattedNode.messageCount = messageCount
    }

    if (children.length > 0) {
      formattedNode.children = children.map(this.formatNode)
    } else if (messages?.length > 0) {
      //leaf node - show last message
      formattedNode.lastMessage = messages[messages.length - 1]
    }

    return formattedNode
  }

  /**
   * Set message limit.
   * @param {number} limit - new message limit.
   */
  setMessageLimit(limit) {
    this.messageLimit = limit
    this.topicsTree = this.truncateNode()
  }

  /**
   * Truncates number of messages in a given node. Called recursively to truncate child messages.
   * @param {object} node - topicsTree node, default to root of topicsTree.
   */
  truncateNode(node=this.topicsTree) {
    const {
      children: childrenMap={},
      messages=[],
    } = node

    const nextChildren = {}
    Object.keys(childrenMap).forEach(name => {
      const child = childrenMap[name]
      nextChildren[name] = this.truncateNode(child)
    })
    const nextMessages = this.messageLimit <= 0 ? [] : messages.slice(-this.messageLimit) //keep last messageLimit messages
    return {
      ...node,
      children: nextChildren,
      messages: nextMessages
    }
  }

  /**
   * Given topic string, find node and return formatted topic string with wild cards appended as needed.
   * @param {string} topic - Topic string. For example: spaces/myAccountId/categories/myCategory/things/myThingId/actions/myActionName
   * @returns {string} formatted topic - Topic string with wild cards appended as needed.
   */
  getFormattedTopic(topic="") {
    const topicPath = this.topicToPath(topic)
    let currentNode

    this.traverse({
      topicPath: topicPath,
      callback: (node, pathElement) => {
        const {
          children: {
            [pathElement]: nextNode={}
          }={}
        } = node || {}
        currentNode = nextNode
      }
    })
    const {children={}, messages=[]} = currentNode || {}
    const hasChildren = Object.keys(children).length > 0
    const hasMessages = messages.length > 0

    if ((!hasChildren && !hasMessages) || hasChildren) {
      //add wildcard to default nodes and parent nodes
      return `${topic}/#`
    } else if (hasMessages) {
      //this is a leaf node so no wildcards are added
      return topic
    }

    //return unformatted topic by default
    return topic
  }

  /**
   * Util to convert topic string to array path. Split by '/'
   * @param {string} topic - Topic string. For example: spaces/myAccountId/categories/myCategory/things/myThingId/actions/myActionName
   * @returns {string[]} topic path - array of topic paths.
   */
  topicToPath(topic="") {
    return topic.split("/")
  }

  /**
   * Util to traverse tree down topicPath array, call callback on each node
   * @param {string[]} topicPath - topic path array.
   * @param {function} callback - Callback called on each node as traverse down topicPath.
   * @param {object} initialNode - Initial node to begin traverse. Defaults to topicsTree root node.
   * @param {boolean} reverseDirection - Flag to traverse down children (default) or up parents.
   */
  traverse({
    topicPath=[],
    callback=()=>{},
    initialNode=this.topicsTree,
    reverseDirection=false
  }) {
    let node = initialNode
    topicPath.forEach((pathElement, index) => {
      //stop traverse if node is undefined
      if (!node) return
      callback(node, pathElement, index)
      // Step down to child node to continue DFS traverse down tree to remove subscription
      //Unless reverseDirection is true, then traverse up parents
      node = reverseDirection
        ? node.parent
        : node.children[pathElement]
    })
  }
}