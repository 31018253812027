/* === IMPORTS === */
import { createReducer } from "@reduxjs/toolkit"

import { CLEAN_SPACE } from "actions/auth"
import { parseApiErrorMessage } from "utils/api"
import {
  createEcpImageRequest,
  deleteEcpImageRequest,
  getEcpImagesRequest,
  updateEcpImageRequest
} from "utils/ecpImages"
import { makeLib } from "utils/misc"
import {
  addMessage,
  GLOBAL_NOTIFICATIONS,
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS
} from "utils/notifications"

import { makeActions } from "./utiliducks"


/* == ACTIONS === */
const actionList = [
  "setEcpImagesAction",
  "addEcpImageAction",
  "updateEcpImageAction",
  "deleteEcpImagesAction",
  "setPagingAction",
]
const {
  setEcpImagesAction,
  addEcpImageAction,
  updateEcpImageAction,
  deleteEcpImagesAction,
  setPagingAction,
} = makeActions("ecpImages", actionList)

/* === INITIAL STATE === */
const initialState = {
  ecpImages: [],
  ecpImagesLib: {},
  paging: {
    previous_cursor: "",
    next_cursor: ""
  },
}

/* === Reducer === */
export default createReducer(initialState, {
  [setEcpImagesAction]: (state, { payload: { ecpImages } }) => ({
    ...state,
    ecpImages,
    ecpImagesLib: makeLib({ data: ecpImages, key: "id" }),
  }),
  [addEcpImageAction]: (state, { payload: { ecpImageData } }) => ({
    ...state,
    ecpImages: [
      ...state.ecpImages,
      ecpImageData
    ],
    ecpImagesLib: {
      ...state.ecpImagesLib,
      [ecpImageData.id]: ecpImageData
    }
  }),
  [updateEcpImageAction]: (state, { payload: { id, ecpImageData } }) => ({
    ...state,
    ecpImages: state.ecpImages.map(ecpImage => ecpImage.id === id ? ecpImageData : ecpImage),
    ecpImagesLib: {
      ...state.ecpImagesLib,
      [id]: {
        ...state.ecpImagesLib[id],
        ...ecpImageData
      }
    }
  }),
  [deleteEcpImagesAction]: (state, { payload: { ids=[] } }) => {
    const ecpImages = state.ecpImages.filter(({ id }) => !ids.includes(id))
    return {
      ...state,
      ecpImages,
      ecpImagesLib: makeLib({ data: ecpImages })
    }
  },
  [setPagingAction]: (state, { payload: { paging } }) => ({
    ...state,
    paging
  }),
  [CLEAN_SPACE]: () => initialState
})

/* === DISPATCHERS === */
export const getEcpImages = () => {
  return async (dispatch) => {
    try {
      const response = await getEcpImagesRequest()
      const { data=[], paging } = response
      if (!Array.isArray(data)) throw new Error(`Invalid data format: ${JSON.stringify(data)}`)
      dispatch(setEcpImagesAction({ ecpImages: data }))
      if (paging) dispatch(setPaging({ paging }))
      return data
    }
    catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Installer Image could not be retrieved",
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
      return []
    }
  }
}

export const getNextEcpImages = () => {
  return async (dispatch, getState) => {
    const {
      ecpImages: {
        ecpImages,
        paging: {
          next_cursor
        }={}
      }
    } = getState()
    const { data, paging } = await getEcpImagesRequest({ next_cursor })
    dispatch(setPaging({ paging }))
    dispatch(setEcpImagesAction({ ecpImages: [ ...ecpImages, ...data ]}))
  }
}

export const createEcpImage = (ecpImageData) => {
  return async (dispatch) => {
    try {
      const response = await createEcpImageRequest(ecpImageData)
      //modify response data format as needed
      const nextEcpImageData = {
        ...response
      }
      dispatch(addEcpImageAction({ ecpImageData: nextEcpImageData }))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Installer Image was created successfully",
        subtext: "Please wait for the image build to complete before downloading it.",
        type: MESSAGE_TYPE_SUCCESS,
        timeout: 4000
      })
      return nextEcpImageData
      
    } catch (error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Installer Image could not be created",
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
      throw error
    }
  }
}

// NOTE: may not be able to update images, just create, read, destroy
// But may need to update status via mqtt
export const updateEcpImage = (id, ecpImageData) => {
  return async (dispatch) => {
    try {
      const response = await updateEcpImageRequest(id, ecpImageData) || {}
      //Update response format as needed
      const nextEcpImageData = {
        ...response
      }
      dispatch(updateEcpImageAction({
        id,
        ecpImageData: nextEcpImageData
      }))
      return nextEcpImageData
    } catch(error) {
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Installer Image could not be updated",
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
      throw error
    }
  }
}

//Can pass in single ecpImage ID to delete, or an array of ecpImage IDs
export const deleteEcpImages = (ecpImageIds=[]) => {
  ecpImageIds = Array.isArray(ecpImageIds) ? ecpImageIds : [ ecpImageIds ]
  return async dispatch => {
    try {
      // TODO: we might need to pace request as we are doing with Things
      const responses = ecpImageIds.map(async id => {
        return deleteEcpImageRequest(id)
      })
      await Promise.all(responses)
      dispatch(deleteEcpImagesAction({ ids: ecpImageIds }))

      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Delete Successful",
        subtext: `Successfully deleted ${ecpImageIds.length} Installer Image${ecpImageIds.length !== 1 ? "s" : ""}`,
        type: MESSAGE_TYPE_SUCCESS,
        timeout: 4000
      })
    } catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: `${ecpImageIds.length === 1 ? "Installer Image" : "Some Installer Images"} could not be deleted`,
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const setPaging = ({ paging }) => {
  return dispatch => dispatch(setPagingAction({ paging }))
}
