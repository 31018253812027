import {
  UnityButton,
  UnityTypography
} from "@bit/smartworks.unity-react.unity-core-react"
import React from "react"
import { connect, useDispatch } from "react-redux"

import { navigate } from "actions/navigate"
import "./topic-tree.css"
import { EVERY_THING } from "constants/routes"
import { getThingRequest } from "utils/things"


//Renders tree menu of topics
const TopicTreeNode = ({
  topic,
  topicPath,
  name,
  isOpen,
  topicCount,
  messageCount,
  selectedTopic,
  lastMessage,
  children=[],
  onToggleNode,
  onNodeClick
}) => {

  const dispatch = useDispatch()

  const thingIDNodeRegex = new RegExp("/things/([A-Z0-9]{26})$")
  const parsedTopicPath = topicPath.join("/")
  const isThingIDNode = parsedTopicPath.match(thingIDNodeRegex)

  const handleToggleButtonClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    onToggleNode({topic, name, isOpen, children})
  }

  const handleNodeClick = () => {
    onNodeClick({topic, topicPath, name})
  }

  const handleThingLinkButtonClick = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    const thing = await getThingRequest(name, null)

    if (thing.uid){
      return dispatch(navigate({
        to: EVERY_THING,
        query: {
          details: thing.uid
        }
      }))
    }
  }


  const getNodeStyle = () => {
    const isSelected = selectedTopic === topic
    let nodeStyle = {...styles.node}

    if (isSelected) {
      nodeStyle = {
        ...nodeStyle,
        ...styles.selectedTopic
      }
    }

    return nodeStyle
  }

  return (
    <div style={styles.container}>
      <div style={getNodeStyle()} onClick={handleNodeClick} className="topic-tree-node-item">
        {children?.length > 0 ?
          <UnityButton
            className="topic-tree-open-button"
            type="borderless"
            centerIcon={isOpen ? "unity:down_chevron" : "unity:right_chevron"}
            style={styles.openButton}
            onClick={handleToggleButtonClick}
          /> :
          <div style={styles.buttonPlaceholder}/>
        }
        <UnityTypography style={styles.name}>{name}</UnityTypography>
        {!isOpen && (topicCount || messageCount) &&
          <UnityTypography style={styles.topicDetails}>
            ({topicCount} {topicCount === 1 ? "topic" : "topics"}, {messageCount} {messageCount === 1 ? "message" : "messages"})
          </UnityTypography>
        }
        {isThingIDNode &&
          <UnityButton
            type="borderless"
            centerIcon={"unity:step_in"}
            style={styles.thingLinkButton}
            onClick={handleThingLinkButtonClick}
          />
        }
        {lastMessage &&
          <UnityTypography style={styles.topicDetails}>= {JSON.stringify(lastMessage?.message)}</UnityTypography>
        }

      </div>
      {isOpen && <div style={styles.list}>
        {children.map(node =>
          <TopicTreeNode
            {...node}
            key={node?.key}
            selectedTopic={selectedTopic}
            onToggleNode={onToggleNode}
            onNodeClick={onNodeClick}
          />
        )}
      </div>}
    </div>
  )
}

export default connect(null, { navigate, getThingRequest })(TopicTreeNode)

const styles = {
  container: {
  },
  node: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    height: 18,
    cursor: "pointer"
  },
  selectedTopic: {
    backgroundColor: "var(--primary-tint-1-color)"
  },
  openButton: {
    "--icon-button-size": "20px",
    "--background-color": "rgba(0,0,0,0)"
  },
  thingLinkButton: {
    "--icon-button-size": "20px",
    "--background-color": "rgba(0,0,0,0)",
    marginLeft: 6
  },
  buttonPlaceholder: {
    width: 20
  },
  name: {
    "--font-weight": "600",
    whiteSpace: "nowrap",
    "--font-size": "11px",
    alignSelf: "stretch"
  },
  topicDetails: {
    marginLeft: 6,
    "--font-size": "10px",
    // flex: "1 1 auto",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    alignSelf: "stretch"
  },
  list: {
    marginLeft: 8
  },
}