export const APP_DETAILS = "Details"
export const APP_AUTHORIZATION = "Authorization"
export const makePolicyFormName = id => `policies-app-${id}`
export const makeAppRolesFormName = id => `roles-app-${id}`

export const POLICIES_SAVED = "POLICIES_SAVED"
export const DELETE_SUBJECTS = "DELETE_SUBJECTS"
export const UPDATE_SUBJECT_SELECTION = "UPDATE_SUBJECT_SELECTION"

export const LABEL_ROLE = "Role"
export const LABEL_ROLES = "Roles"
export const LABEL_SUBJECT = "Role Subject"
export const LABEL_SUBJECTS = "Role Subjects"
export const ROLES_SUBJECTS = "subjects"

export const TITLE = LABEL_ROLES
export const TABLE = "roles-table"
export const NEW_DETAILS = "New Role"
export const DETAILS = "roles-details"
export const EMPTY_TEXT = `No ${TITLE.toLowerCase()} found`
export const OWNER = "owner"
export const ROLE_ROOT = "role::root"


// Edit roles actions
export const ADD_ROLES_ACTION = "add"
export const REMOVE_ROLES_ACTION = "remove"
