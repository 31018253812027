/* === IMPORTS === */
import { createReducer } from "@reduxjs/toolkit"
import cloneDeep from "lodash/cloneDeep"
import merge from "lodash/merge"
import omit from "lodash/omit"
import setWith from "lodash/setWith"

import { CLEAN_SPACE } from "actions/auth"
import { EVERYTHING_CATEGORY_OPTION } from "constants/things"
import { filterSuccessfulRequests, paceRequests } from "utils/api"
import { createBatches, getIdFromHref, makeLib } from "utils/misc"
import { getMessageContent, getStatusTopicParams } from "utils/mqtt"
import {
  addMessage,
  GLOBAL_NOTIFICATIONS,
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS,
  MESSAGE_TYPE_WARNING,
} from "utils/notifications"
import { getPoliciesRequest } from "utils/policies"
import { getSpace, getTableSort } from "utils/storage"
import {
  createThingRequest,
  deleteActionInstanceRequest,
  deleteEventEntryRequest,
  deleteThingRequest,
  getFilterQueryParams,
  getSortQuery,
  getTableNameByCategory,
  getThingActionsRequest,
  getThingColumns,
  getThingEventMetricsRequest,
  getThingEventsRequest,
  getThingPropertiesHistoryRequest,
  getThingRequest,
  getThingsRequest,
  initialColumns,
  resetSecretRequest,
  runActionRequest,
  updateThingPropertiesRequest,
  updateThingRequest
} from "utils/things"


import { setColumns } from "./tableColumns"
import { makeActions } from "./utiliducks"


/* == ACTIONS === */
const actionList = [
  "actionInstanceDeleteAction",
  "actionInstanceUpdateAction",
  "addThingAction",
  "addThingsAction",
  "deleteThingPropertyAction",
  "eventEntryDeleteAction",
  "eventEntryUpdateAction",
  "notifyProcessedRequestAction",
  "processRequestsAction",
  "removeThingsAction",
  "resetAddingThingsAction",
  "saveRequestResultsAction",
  "setCurrentThingAction",
  "setCurrentThingLocalStatusAction",
  "setModalTypeToShowAction",
  "setPagingAction",
  "setThingPoliciesAction",
  "setThingsAction",
  "setSearchBarTextAction",
  "updateThingAction",
  "setPropertiesHistoryCursorAction",
  "setPropertiesHistoryAction",
  "setIsFetchingPropertiesHistoryAction",
  "cleanUpPropertiesHistoryAction",
  "cleanUpAnythingDbAction"
]

const {
  actionInstanceDeleteAction,
  actionInstanceUpdateAction,
  addThingAction,
  addThingsAction,
  deleteThingPropertyAction,
  eventEntryDeleteAction,
  eventEntryUpdateAction,
  notifyProcessedRequestAction,
  processRequestsAction,
  removeThingsAction,
  resetAddingThingsAction,
  saveRequestResultsAction,
  setCurrentThingAction,
  setCurrentThingLocalStatusAction,
  setModalTypeToShowAction,
  setSearchBarTextAction,
  setThingPoliciesAction,
  setThingsAction,
  setPagingAction,
  updateThingAction,
  setPropertiesHistoryCursorAction,
  setPropertiesHistoryAction,
  setIsFetchingPropertiesHistoryAction,
  cleanUpPropertiesHistoryAction,
  cleanUpAnythingDbAction
} = makeActions("things", actionList)

/* === INITIAL STATE === */
const initialState = {
  actions: {
    instances: {}
  },
  currentThing: {},
  currentThingLocalStatus: {},
  events: {},
  modalTypeToShow: "",
  processedRequests: 0,
  requestResults: null,
  requestsToProcess: 0,
  selectedThings: [],
  searchBarText: "",
  thingPolicies: {},
  things: [],
  thingsLib: {},
  propertiesHistory: [],
  propertiesHistoryCursor: "",
  isFetchingPropertiesHistory: false,
  paging: { previous_cursor: "", next_cursor: "" },
}

/* === Reducer === */
export default createReducer(initialState, {
  [setSearchBarTextAction]: (state, action) => {
    state.searchBarText = action.payload
  },
  [setModalTypeToShowAction]: (state, {payload: nextModalType}) => {
    const oldType = state.modalTypeToShow
    const newModalType = !!nextModalType && nextModalType !== oldType ? nextModalType : ""

    state.modalTypeToShow = newModalType
  },
  [actionInstanceDeleteAction]: (state, { payload: { thingId, actionName, instanceId } }) => {
    delete state.actions.instances[thingId][actionName][instanceId]
  },
  [actionInstanceUpdateAction]: (state, { payload: { thingId, actionName, instanceId, actionInstanceDetails } }) => {
    return {
      ...state,
      actions: {
        ...state.actions,
        instances: {
          [thingId]: {
            ...state.actions.instances[thingId] ?? {},
            [actionName]: {
              ...state.actions.instances[thingId]?.[actionName] ?? {},
              [instanceId]: {
                ...state.actions.instances[thingId]?.[actionName]?.[instanceId] ?? {},
                ...actionInstanceDetails
              }
            }
          }
        }
      }
    }
  },
  [addThingAction]: (state, { payload: { thing } } = {}) => ({
    ...state,
    things: [...state.things, thing],
    thingsLib: { ...state.thingsLib, [thing.uid]: thing },
  }),
  [addThingsAction]: (state, { payload: { things }}={}) => ({
    ...state,
    things: [ ...state.things, ...things ],
    thingsLib: things.reduce((acc, thing) => {
      acc[thing.uid] = thing
      return acc
    }, {})
  }),
  [deleteThingPropertyAction]:  (state, { payload: { thingId, property }}={}) => {
    const updatedThings = state.things.map(t => {
      if (t.uid !== thingId) return t
      const properties = { ...t.properties }
      delete properties[property]
      return { ...t, properties }
    })
    const updatedThingsLib = makeLib({ data: updatedThings, key: "uid" })
    return {
      ...state,
      things: updatedThings,
      thingsLib: updatedThingsLib
    }
  },
  [eventEntryDeleteAction]: (state, { payload: { thingId, eventName, eventEntryId } }) => {
    delete state.events[thingId][eventName][eventEntryId]
  },
  [eventEntryUpdateAction]: (state, { payload: { thingId, eventName, eventEntryId, eventEntryDetails } }) => {
    return {
      ...state,
      events: {
        ...state.events,
        [thingId]: {
          ...state.events[thingId] ?? {},
          [eventName]: {
            ...state.events[thingId]?.[eventName] ?? {},
            [eventEntryId]: {
              ...state.events[thingId]?.[eventName]?.[eventEntryId] ?? {},
              ...eventEntryDetails
            }
          }
        }
      }
    }
  },
  [notifyProcessedRequestAction]: (state) => {
    state.processedRequests += 1
  },
  [processRequestsAction]: (state, { payload: { requestsToProcess } }) => {
    state.requestsToProcess = requestsToProcess
  },
  [removeThingsAction]: (state, { payload: { ids }}={}) => {
    const things = state.things.filter(thing => !ids.includes(thing.uid))

    return {
      ...state,
      things,
      thingsLib: makeLib({ data: things, key: "uid" }),
    }
  },
  [resetAddingThingsAction]: (state) => {
    state.processedRequests = initialState.processedRequests
    state.requestResults = initialState.requestResults
    state.requestsToProcess = initialState.requestsToProcess
  },
  [saveRequestResultsAction]: (state, { payload: { requestResults } }) => {
    state.requestResults = requestResults
  },
  [setCurrentThingAction]: (state, { payload: { thing } }) => ({
    ...state,
    currentThing: thing
  }),
  [setCurrentThingLocalStatusAction]: (state, { payload: { status } }) => ({
    ...state,
    currentThingLocalStatus: status
  }),
  [setThingPoliciesAction]:  (state, { payload: { id, policies }}={}) => {
    state.thingPolicies[id] = policies
  },
  [setThingsAction]: (state, { payload: { things }}={}) => ({
    ...state,
    things,
    thingsLib: makeLib({ data: things, key: "uid" }),
  }),
  [setPagingAction]: (state, { payload: { paging } }) => ({
    ...state,
    paging,
  }),
  [updateThingAction]: (state, { payload: { thing } }) => {
    if (state.currentThing?.uid === thing.uid) {
      state.currentThing = {...thing}
    }
    state.things = state.things.map(t => (t.uid === thing.uid) ? {...thing} : t)
    state.thingsLib[thing.uid] = {...thing}
  },
  [setPropertiesHistoryAction]: (state, { payload: { propertiesHistory } }) => {
    state.propertiesHistory = propertiesHistory
  },
  [setPropertiesHistoryCursorAction]: (state, { payload: { propertiesHistoryCursor } }) => {
    state.propertiesHistoryCursor = propertiesHistoryCursor
  },
  [setIsFetchingPropertiesHistoryAction]: (state, { payload: { isFetchingPropertiesHistory } }) => {
    state.isFetchingPropertiesHistory = isFetchingPropertiesHistory
  },
  [cleanUpPropertiesHistoryAction]: (state) => {
    state.propertiesHistory = initialState.propertiesHistory
    state.propertiesHistoryCursor = initialState.propertiesHistoryCursor
    state.isFetchingPropertiesHistory = initialState.isFetchingPropertiesHistory
  },
  [cleanUpAnythingDbAction]: () => initialState,
  [CLEAN_SPACE]: () => initialState
})

/* === DISPATCHERS === */
export const getThings = (params, categoryName) => {
  return async (dispatch) => {
    try {
      const response = await getThingsRequest(params, categoryName)
      const { data: things, paging } = response
      dispatch(setThingsAction({ things }))
      dispatch(updateThingTableColums(categoryName))
      if (paging) dispatch(setPaging({ paging }))
      return things
    } catch (error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Things could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR,
      })
    }
  }
}

export function getThingsByIds(ids=[], params, category) {
  return async (dispatch) => {
    try {
      const idBatches = createBatches(ids)
      let data = []
      for (let i = 0; i < idBatches.length; i++) {
        const thingIds = idBatches[i]
        const {data: batchData=[]} = await getThingsRequest({"thingID[]": thingIds, ...params}, category)
        data = [
          ...data,
          ...batchData
        ]
      }
      dispatch(setThingsAction({ things: data }))
      dispatch(updateThingTableColums(category))
      return data
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Error getting Things",
        type: MESSAGE_TYPE_ERROR
      })
      return []
    }
  }
}

export const getQueryParams = (tableName, tableColumns) => {
  return (dispatch, getState) => {

    const {
      authentication: {
        userInfo: {
          username
        }
      }
    } = getState()

    const filterQueryParams = getFilterQueryParams(tableName, tableColumns, username)
    const sort = getSortQuery(getTableSort(`${tableName}-${getSpace()}`, username) || {})
    return {...filterQueryParams, ...sort}
  }
}

export const getNextThings = (params, category) => {
  return async (dispatch, getState) => {
    const {
      things: {
        things,
        paging: oldPaging
      }
    } = getState()

    try {
      const { data, paging } = await getThingsRequest({
        ...params,
        next_cursor: oldPaging.next_cursor,
      }, category)
      dispatch(setThingsAction({ things: [...things, ...data] }))
      dispatch(updateThingTableColums(category))
      if (paging) dispatch(setPaging({ paging }))
      return data
    } catch (error) {
      console.error(`${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Things could not be retrieved",
        type: MESSAGE_TYPE_ERROR,
      })
    }

  }
}

export function getThing(id, category) {
  return async (dispatch) => {
    try {
      const thing = await getThingRequest(id, category)
      dispatch(setCurrentThingLocalStatusAction({status: {}}))
      dispatch(setCurrentThingAction({ thing }))
      return thing
    }
    catch (error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Error getting Thing",
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export function clearCurrentThing() {
  return dispatch => dispatch(setCurrentThingAction({thing: initialState.currentThing}))
}

export const createThing = (thing, category = null) => {
  return async (dispatch) => {
    try {
      const newThing = await createThingRequest(thing, category)
      dispatch(addThingAction({ thing: newThing }))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Thing was created successfully",
        type: MESSAGE_TYPE_SUCCESS,
      })
      return newThing.uid
    } catch (error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Thing could not be created",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR,
      })
      return false
    }
  }
}

export function createMultipleThings(things=[], category) {
  return async (dispatch) => {
    try {
      dispatch(processRequestsAction({ requestsToProcess: things.length }))

      //Pass in array of requests wrapped in functions (to prevent immediate execution)
      const requestGenerators = things.map(thing => () => createThingRequest(thing, category))
      const resolvedRequests = await paceRequests({
        requestGenerators,
        batchSize: 3,
        onProgress: () => dispatch(notifyProcessedRequestAction())
      })
      const succesfulRequests = resolvedRequests.filter(x => !!x?.uid)
      dispatch(saveRequestResultsAction({ requestResults: succesfulRequests }))
      if(succesfulRequests.length) {
        //Fetch table data incase new things are included
        dispatch(addThingsAction({ things: succesfulRequests}))
      }
      else {
        console.error("Clone Request error. Things could not be cloned.")
        addMessage({
          target: GLOBAL_NOTIFICATIONS,
          text: "Things could not be updated",
          subtext: "Request error",
          type: MESSAGE_TYPE_ERROR,
        })
      }
      return succesfulRequests
    }
    catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Things could not be updated",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR,
      })
    }
  }
}


export function deleteThings(ids, category = null) {
  return async dispatch => {
    try {
      const responses = ids.map(async id => {
        return deleteThingRequest(id, category)
      })
      await Promise.all(responses)
      dispatch(removeThingsAction({ ids }))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Delete Complete",
        subtext: `${ids.length === 1 ? "Thing was": `${ids.length} things were`} deleted successfully`,
        type: MESSAGE_TYPE_SUCCESS
      })
      return true
    }
    catch (error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Delete Failed",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR,
      })
      return false
    }
  }
}

export function resetAddingThings() {
  return dispatch => {
    dispatch(resetAddingThingsAction())
  }
}

export function updateCurrentThingLocalStatusAtPath({path, value}) {
  return (dispatch, getState) => {
    const localStatus = cloneDeep(getState()?.things?.currentThingLocalStatus ?? {})
    setWith(localStatus, path, value, Object)

    dispatch(setCurrentThingLocalStatusAction({status: localStatus}))
  }
}

const getUpdateMessage = ( properties, success ) => {
  const propertyNames = Object.keys(properties)
  const numberOfProperties = propertyNames.length

  const baseText = numberOfProperties === 1 ? "Property" : "Properties"

  const specificText = success
    ? numberOfProperties === 1
      ? ` ${propertyNames[0]} updated successfully`
      : " updated successfully"
    : numberOfProperties === 1
      ? ` ${propertyNames[0]} could not be updated`
      : " could not be updated"

  return baseText + specificText
}

export function updateThingProperties(thingId, newProperties={}, category, isNewProperty = false) {
  return async () => {
    try {
      // We don't need to dispatch "setThingAction",
      // because "onPropertiesMessage" will do it for us

      await updateThingPropertiesRequest(thingId, newProperties, category)

      const text = getUpdateMessage(newProperties, true)

      !isNewProperty
        ? addMessage({
          text,
          type: MESSAGE_TYPE_SUCCESS,
          timeout: 4000
        })
        : null
    }

    catch(error) {
      const text = getUpdateMessage(newProperties, false)
      console.error({[error.name]: { status: error.status, error: error.error, message: error.message }})

      addMessage({
        text: text,
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR,
        timeout: 4000
      })
    }
  }
}

export function updateThingTableColums(category) {
  return (dispatch, getState) => {
    const state = getState()
    const tableCategory = category || state.router.location.query.selectedCategory || EVERYTHING_CATEGORY_OPTION
    const tableName = getTableNameByCategory(tableCategory)
    const originalCols = state.tableColumns[tableName]?.columns || initialColumns
    const { things } = state.things
    const columns = getThingColumns(things, originalCols, false)

    dispatch(setColumns({
      table: tableName,
      columns
    }))
  }
}

export function deleteThingProperty(thingId, property) {
  return dispatch => dispatch(deleteThingPropertyAction({ thingId, property }))
}

export function updateThingSchema(thing={}, updatedSchema={}, notify=true, category="") {
  return async dispatch => {
    try {
      const { uid } = thing
      const { status, ...formattedSchema } = updatedSchema
      const updatedThing = await updateThingRequest(uid, formattedSchema, category)
      await dispatch(updateThingAction({thing: updatedThing}))
      dispatch(updateThingTableColums(category))

      if (notify) {
        const { title } = updatedThing
        addMessage({
          target: GLOBAL_NOTIFICATIONS,
          text: "Update Complete",
          subtext: `${title ? `"${title}" ` : "" }Schema was updated successfully.`,
          type: MESSAGE_TYPE_SUCCESS,
        })
      }
    } catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Thing could not be updated",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR,
      })
      return false
    }
    return true
  }
}

export function updateThingsModel(model, idList, category) {
  return async (dispatch, getState) => {
    const {
      modelsBeta: {
        modelVersionsLib
      },
      things: {
        thingsLib
      }
    } = getState()
    const requestGenerators = idList.map(id => () => {
      const thingWithoutStatus = omit(thingsLib[id], "status")
      // keep fields not present in model template, add/replace fields from template, and set model
      const newSchema = { ...thingWithoutStatus, ...modelVersionsLib[model?.version]?.template, model }

      if (newSchema.categories && category) {
        delete newSchema.categories
      }

      return updateThingRequest(id, newSchema, category)
    })
    const responses = await paceRequests({ requestGenerators })

    const successful = filterSuccessfulRequests(responses)
    const successfulLength = successful.length
    const errorLength = responses.length - successfulLength
    successful.forEach(thing => dispatch(updateThingAction({thing})))

    if(errorLength === 0) {
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Update Complete",
        subtext: `${idList.length === 1 ? "Thing was": `${idList.length} things were`} updated successfully`,
        type: MESSAGE_TYPE_SUCCESS,
      })
      return true
    }
    else if(successfulLength === 0) {
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Update Failed",
        subtext: idList.length === 1 ? responses[0].message : `${idList.length} things could not be updated`,
        type: MESSAGE_TYPE_ERROR,
      })
      return false
    }
    else {
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Update partially Complete",
        subtext: `${successfulLength} ${successfulLength === 1 ? "Thing": "Things"} updated successfully, ${errorLength} ${errorLength === 1 ? "Thing": "Things"} could not be updated`,
        type: MESSAGE_TYPE_WARNING,
      })
      return false
    }
  }
}


// THING ACTIONS

export function deleteActionInstance(thingId, actionName, instanceId, category) {
  return async dispatch => {
    try {
      await deleteActionInstanceRequest(thingId, actionName, instanceId, category)
      dispatch(actionInstanceDeleteAction({ thingId, actionName, instanceId }))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        type: MESSAGE_TYPE_SUCCESS,
        text: "Action instance successfully deleted"
      })
    }
    catch (error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        type: MESSAGE_TYPE_ERROR,
        text: "Failed to delete Action instance"
      })
    }
  }
}

export function getActions(thingId, category) {
  return async dispatch => {
    try {
      const { data: actions } = await getThingActionsRequest(thingId, category)
      actions.forEach(inst => { // expect many of these
        Object.keys(inst).forEach(actionName => { // expect exactly one of these (but allow for multiple, just in case)
          const instanceId = getIdFromHref(inst[actionName].href)
          if (!instanceId) {
            console.warn("No instance id for action instance: ", inst)
            return
          }
          dispatch(actionInstanceUpdateAction({ thingId, actionName, instanceId, actionInstanceDetails: inst[actionName] }))
        })
      })
    }
    catch (error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        type: MESSAGE_TYPE_ERROR,
        text: "Thing Actions could not be retrieved"
      })
    }
  }
}

export function runAction(thingId, actionName, actionInput={}, category) {
  return async (dispatch) => {
    try {
      const action = await runActionRequest(thingId, { [actionName]: actionInput }, category)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        type: MESSAGE_TYPE_SUCCESS,
        text: "Action successfully queued"
      })
      const instanceId = getIdFromHref(action[actionName].href)
      if (!instanceId) {
        console.warn("No instance id for action instance: ", action)
        return
      }
      dispatch(actionInstanceUpdateAction({ thingId, actionName, instanceId, actionInstanceDetails: action[actionName] }))
    }
    catch (error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        type: MESSAGE_TYPE_ERROR,
        text: "Failed to run action",
        subtext: error.message
      })
    }
  }
}

export function runActions(thingId, actionsObject) {
  return async () => {
    Object.keys(actionsObject).forEach(async (actionName) => {
      try {
        const success = await runActionRequest(thingId, { [actionName]: actionsObject[actionName] })
        if (!success) {
          throw new Error("There was an unknown error")
        }
        addMessage({
          target: GLOBAL_NOTIFICATIONS,
          type: MESSAGE_TYPE_SUCCESS,
          timeout: 4000,
          text: "Action successfully queued"
        })
      } catch(err) {
        console.warn(`Failed to run action "${actionName}" for thing: `, thingId)
        console.warn(err)
        addMessage({
          target: GLOBAL_NOTIFICATIONS,
          type: MESSAGE_TYPE_ERROR,
          text: "Failed to run action",
          subtext: err.message,
          timeout: 8000
        })
      }
    })
  }
}


// THING EVENTS

export function deleteEventEntry(thingId, eventName, eventEntryId, category) {

  return async dispatch => {
    try {
      await deleteEventEntryRequest(thingId, eventName, eventEntryId, category)
      dispatch(eventEntryDeleteAction({ thingId, eventName, eventEntryId }))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        type: MESSAGE_TYPE_SUCCESS,
        text: "Event entry successfully deleted"
      })
    }
    catch (error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        type: MESSAGE_TYPE_ERROR,
        text: "Failed to delete Event entry"
      })
    }
  }
}

export function getEvents(thingId, category) {
  return async dispatch => {
    try {
      const { data: eventEntries } = await getThingEventsRequest(thingId, category)
      eventEntries.forEach(entry => { // expect many of these
        Object.keys(entry).forEach(eventName => { // expect exactly one of these (but allow for multiple, just in case)
          const eventEntryId = getIdFromHref(entry[eventName].href)
          if (!eventEntryId) {
            console.warn("No entry id for event entry: ", entry)
            return
          }
          dispatch(eventEntryUpdateAction({ thingId, eventName, eventEntryId, eventEntryDetails: entry[eventName] }))
        })
      })
    }
    catch (error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        type: MESSAGE_TYPE_ERROR,
        text: "Thing Events could not be retrieved"
      })
    }
  }
}

export async function getEventMetrics(thingId, category) {
  try {
    const { data=[] } = await getThingEventMetricsRequest(thingId, category)
    return data
  }
  catch (error) {
    console.error(error)
    addMessage({
      target: GLOBAL_NOTIFICATIONS,
      type: MESSAGE_TYPE_ERROR,
      text: "Thing Event Metrics could not be retrieved"
    })
  }
}

// MQTT

export const onPropertiesMessage = (topic, message) => {
  return async (dispatch, getState) => {

    const topicParams = getStatusTopicParams(topic)
    const { thingUlid: thingId = "" } = topicParams
    const messageContent = getMessageContent(message)

    const thing = getState().things.thingsLib[thingId]

    if (thing) {
      // update property values
      const updatedThing = { ...thing, status: merge({}, thing.status, messageContent) }
      await dispatch(updateThingAction({ thing: updatedThing }))
    }
  }
}

export const onActionsMessage = (topic, message) => {
  return async dispatch => {
    const topicParams = getStatusTopicParams(topic)
    const messageContent = getMessageContent(message)

    const { thingUlid: thingId="", propertyId=[] } = topicParams

    // update entries for action call history & run log
    Object.keys(messageContent).forEach((actionName) => {
      const instanceDetails = messageContent[actionName]
      const { href } = instanceDetails
      const instanceId = getIdFromHref(href) ?? propertyId[1]

      // Update action instance in store
      if (!instanceId) {
        console.debug(`No instance id for action "${actionName}" in message: `, messageContent)
        return
      }
      dispatch(actionInstanceUpdateAction({ thingId, actionName, instanceId, actionInstanceDetails: instanceDetails }))
    })
  }
}

export const onEventsMessage = (topic, message) => {
  return async dispatch => {
    const topicParams = getStatusTopicParams(topic)
    const messageContent = getMessageContent(message)

    const { thingUlid: thingId="" } = topicParams

    // Add new events as they come in
    Object.keys(messageContent).forEach((eventName) => {
      const eventDetails = messageContent[eventName]
      const eventEntryId = getIdFromHref(eventDetails.href)
      dispatch(eventEntryUpdateAction({ thingId, eventName, eventEntryId, eventEntryDetails: eventDetails }))
    })
  }
}

// PROPERTIES HISTORY

export const getPropertiesHistory = (withCursor = true, date = null) =>
  async (dispatch, getState) => {
    const state = getState()
    const queryString = state?.router?.location?.search
    const urlParams = new URLSearchParams(queryString)
    const currentProperty = urlParams.get("property") || null
    const propertiesHistory = withCursor ? state?.things?.propertiesHistory : []
    const thingId= state?.router?.location?.query?.details || null
    const cursor = state?.things?.propertiesHistoryCursor || ""

    const property = currentProperty === "all" ? null : currentProperty

    const params = {}

    if (withCursor && cursor) params.next_cursor = cursor

    if (date) params.at = date

    if (!withCursor) dispatch(setIsFetchingPropertiesHistoryAction({ isFetchingPropertiesHistory: true }))
    try {
      const propertiesHistoryData = await getThingPropertiesHistoryRequest(thingId, property, params)
      dispatch(setPropertiesHistoryCursorAction({ propertiesHistoryCursor: propertiesHistoryData.paging.next_cursor }))
      dispatch(setPropertiesHistoryAction({ propertiesHistory: [...propertiesHistory, ...propertiesHistoryData.data] }))
    } catch (e) {
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Could not fetch properties history",
        type: MESSAGE_TYPE_ERROR
      })
    }
    if(!withCursor) dispatch(setIsFetchingPropertiesHistoryAction({ isFetchingPropertiesHistory: false }))
  }

export const addPropertiesHistoryFromMqtt = (propertyHistory) => {
  return (dispatch, getState) => {
    const state = getState()
    const propertiesHistory = state?.things?.propertiesHistory || []
    const preparedPropertyHistory = Array.isArray(propertyHistory) ? propertyHistory : [propertyHistory]

    dispatch(setPropertiesHistoryAction({ propertiesHistory: [...preparedPropertyHistory, ...propertiesHistory] }))
  }
}

export const cleanUpPropertiesHistory = () => {
  return (dispatch) => dispatch(cleanUpPropertiesHistoryAction())
}

// POLICIES

export const getThingPolicies = ({id}) => {
  return async dispatch => {
    try {
      if (!id) {
        dispatch(setThingPoliciesAction({ id, policies:{} }))
        return
      }
      const { data : policies} = await getPoliciesRequest({subject: id, limit: 1000 })
      dispatch(setThingPoliciesAction({id, policies}))
      return policies
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        text: "Policies could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

// MISC

export const resetSecret = (thingId, category) => {
  return async (dispatch, getState) => {
    try {
      const {
        things: {
          thingsLib: {
            [thingId]: thing={}
          }={}
        }={}
      } = getState()
      const credentials = await resetSecretRequest(category, thingId, true)
      if(!thing.clientId) dispatch(updateThingAction({ thing: {...thing, client_id: credentials.client_id }}))

      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Thing Secret reset successfully",
        type: MESSAGE_TYPE_SUCCESS,
      })

      return credentials
    }
    catch (error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Could not reset Thing Secret",
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const setPaging = ({ paging }) => {
  return (dispatch) => dispatch(setPagingAction({ paging }))
}

export const setSearchBarText = (text) => {
  return dispatch => dispatch(setSearchBarTextAction(text))
}

export const setModalView = (modalType) => {
  return dispatch => dispatch(setModalTypeToShowAction(modalType))
}

export const cleanAnythingDB = () => {
  return dispatch => dispatch(cleanUpAnythingDbAction())
}
