import { createReducer } from "@reduxjs/toolkit"
import { uniq } from "lodash"

import { CLEAN_SPACE } from "actions/auth"
import { createCustomQueryRequest, deleteCustomQueriesRequest, getCustomQueriesRequest, getCustomQueryRequest, updateCustomQueryRequest } from "utils/customQueries"
import { makeLib } from "utils/misc"
import { addMessage, GLOBAL_NOTIFICATIONS, MESSAGE_TYPE_ERROR, MESSAGE_TYPE_SUCCESS } from "utils/notifications"

import { makeActions } from "./utiliducks"

const actionList = [
  "addCustomQueryAction",
  "removeCustomQueryAction",
  "setPagingAction",
  "setCustomQueriesAction",
  "updateCustomQueryAction"
]

const {
  addCustomQueryAction,
  removeCustomQueryAction,
  setPagingAction,
  setCustomQueriesAction,
  updateCustomQueryAction
} = makeActions("customQueries", actionList)

const initialState = {
  customQueries: [],
  customQueriesLib: {},
  paging: { previous_cursor: "", next_cursor: "" }
}

export default createReducer(initialState, {
  [addCustomQueryAction]: (state, { payload: { customQuery } }) => {
    if (state.customQueriesLib[customQuery.name]) {
      state.customQueries = state.customQueries.map(c => c.name === customQuery.name ? customQuery : c)
    } else {
      state.customQueries.push(customQuery)
    }

    state.customQueriesLib[customQuery.name] = customQuery
  },
  [removeCustomQueryAction]: (state, { payload: { ids }}) => {
    state.customQueries = state.customQueries.filter(customQuery => !ids.includes(customQuery.name))
    state.customQueriesLib = makeLib({ data: state.customQueries, key: "name" })
  },
  [setPagingAction]: (state, { payload: { paging } }) => {
    state.paging = paging
  },
  [setCustomQueriesAction]: (state, { payload: { customQueries } }) => {
    state.customQueries = customQueries
    state.customQueriesLib = makeLib({ data: customQueries, key: "name" })
  },
  [updateCustomQueryAction]: (state, { payload: { customQuery } }) => {
    state.customQueries = state.customQueries.map(c => c.name === customQuery.name ? customQuery : c)
    state.customQueriesLib[customQuery.name] = customQuery
  },
  [CLEAN_SPACE]: () => initialState
})

export const getCustomQuery = id => {
  return async dispatch => {
    try {
      const customQuery = await getCustomQueryRequest(id)
      dispatch(addCustomQueryAction({ customQuery }))

      return customQuery
    } catch (error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Custom Query could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR,
      })
    }
  }
}

export const getCustomQueries = ({ next=false }={}) => {
  return async (dispatch, getState) => {
    try {
      const {customQueries} = getState()?.customQueries || {}
      const {next_cursor} = getState()?.customQueries?.paging || {}
 

      const shouldGetNext = next && next_cursor
      if(next && !shouldGetNext) return

      const params = shouldGetNext ? { next_cursor } : null
      const { data, paging } = await getCustomQueriesRequest(params)
      const customQueriesList = shouldGetNext ? [ ...customQueries, ...data ] : data
      dispatch(setCustomQueriesAction({ customQueries: customQueriesList }))
      dispatch(setPagingAction({ paging }))
      return customQueriesList
    }
    catch (error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Custom Queries could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR,
      })
    }
  }
}

export const createCustomQuery = (body) => {
  return async dispatch => {
    try {
      const customQuery = await createCustomQueryRequest(body)
      dispatch(addCustomQueryAction({ customQuery }))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Custom Query was created successfully",
        type: MESSAGE_TYPE_SUCCESS,
      })
      return customQuery
    }
    catch (error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Custom Query could not be created",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR,
      })
    }
  }
}

export const deleteCustomQueries = ids => {
  return async dispatch => {
    try {
      const searchParams = new URLSearchParams()
      ids.forEach(id => searchParams.append("variable[]", id))

      await deleteCustomQueriesRequest(`?${searchParams.toString()}`)
      
      dispatch(removeCustomQueryAction({ ids }))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Delete Complete",
        subtext: `${ids.length === 1 ? "Custom Query was" : `${ids.length} Custom Queries were`} deleted successfully`,
        type: MESSAGE_TYPE_SUCCESS
      })
      return true
    }
    catch (error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Delete Failed",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR,
      })
      return false
    }
  }
}

export const updateCustomQuery = (customQuery) => {
  return async dispatch => {
    try {
      const newCustomQuery = await updateCustomQueryRequest(customQuery)
      dispatch(updateCustomQueryAction({ customQuery: newCustomQuery }))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Custom Query was updated successfully",
        type: MESSAGE_TYPE_SUCCESS,
      })
      return newCustomQuery
    }
    catch (error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Custom Query could not be updated",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR,
      })
    }
  }
}

const specialCases = {
  uid: {
    id: "_id",
    label: "uid"
  },
  thing_uid: {
    id: "thing__id",
    label: "thing_uid"
  },
}

const makeOptions = (options) => options.map(o => (specialCases[o] || {id: o, label: o}))
const thingAttributes = ["model.version", "@type", "@context", "links.href", "links.rel", "title", "uid", "category"]
const thingAttributesForNonThings = thingAttributes.map(a => "thing_" + a)

export const resourceTypeOptions = [
  {
    id: "things",
    label: "Things",
    filters: makeOptions(thingAttributes),
    results: makeOptions(["thing", "uid", "title", "status", "average status", "min status", "max status"])
  },
  {
    id: "propertiesHistory",
    label: "Properties-history",
    filters: makeOptions([...thingAttributesForNonThings, "value", "timestamp" ]),
    results: makeOptions(["property", "value", "average value", "min value", "max value"])
  },
  {
    id: "actionsHistory",
    label: "Actions-history",
    filters: makeOptions([...thingAttributesForNonThings, "timeRequested", "timeCompleted" ]),
    results: makeOptions(["action", "input", "status"])
  },
  {
    id: "eventsHistory",
    label: "Events-history",
    filters: makeOptions([...thingAttributesForNonThings, "timestamp" ]),
    results: makeOptions(["event", "name", "data"])
  }
]

export const getResultsOptions = (resourceType) => {
  return resourceTypeOptions.find(r => r.id === resourceType)?.results || []
}

export const getAttributeOptions = (resourceType) => {
  return resourceTypeOptions.find(r => r.id === resourceType)?.filters || []
}

export const dontRemoveEmptyString = value => value

const removeSpecialCharacter = (attribute) => attribute.startsWith("@") ? attribute.substring(1) : attribute
const escapeSpecialCharacter = (attribute) => attribute.startsWith("@") ? "`"+attribute+"`" : attribute

const createBindVarName = (f, filters) => {
  const filtersWithSameAttribute = filters.filter(fi => f.attribute === fi.attribute)
  const index =
  filtersWithSameAttribute.length > 1
    ? filtersWithSameAttribute.findIndex(fi => fi === f) + 1
    : ""

  return `${removeSpecialCharacter(f.attribute).replace(".", "_")}${index}`
}

export const makeQuery = (
  resourceType = "",
  filters = [],
  results = "",
  propertyName = ""
) => {
  const bindVars = {}
  const resource= resourceType[0]
  const forStrings = [`FOR ${resource} IN ${resourceType}`]
  const filterStrings = []
    
  const normalizedFilters = filters.map(f => ({
    ...f,
    attribute: f.attribute.replace("thing_", ""),
    isThingAttribute: f.attribute.startsWith("thing_")
  }))

  if (normalizedFilters.some(f => f.isThingAttribute)) {
    forStrings.push("FOR t IN things")
    filterStrings.push(`FILTER ${resource}.thing_id == t._id`)
  }
  

  normalizedFilters.forEach(f => {
    const bindVarName = createBindVarName(f, normalizedFilters)

    bindVars[bindVarName] = f.attribute === "_id" ? `things/${f.value}` : f.value

    if(f.attribute.startsWith("links.")) {
      const linksAttribute = f.attribute.replace("links.", "links[*].")
      const linksOperator = f.operator === "==" ? "IN" : "NOT IN"

      filterStrings.push(`FILTER @${bindVarName} ${linksOperator} ${linksAttribute}`)
    } else if (f.attribute === "category") {
      const categoryOperator = f.operator === "==" ? "IN" : "NOT IN"
      forStrings.push("FOR c IN categories")
      filterStrings.push(`FILTER c._id ${categoryOperator} t.categories`)
      filterStrings.push(`FILTER @${bindVarName} == c.name`)
    } else if (f.isThingAttribute) {
      filterStrings.push(`FILTER t.${escapeSpecialCharacter(f.attribute)} ${f.operator} @${bindVarName}`)
    } else {
      filterStrings.push(`FILTER ${resource}.${escapeSpecialCharacter(f.attribute)} ${f.operator} @${bindVarName}`)
    }
  })
  
  let resultsString =
  ["thing", "property", "action", "event"]
    .includes(results)
    ? `RETURN ${resource}`
    : `RETURN ${resource}.${results}`
  
  if (
    resourceType === "things" &&
    ["average status", "min status", "max status"].includes(results)
  ) {
    bindVars.propertyName = propertyName
    filterStrings.push(...["FILTER p.thing_id == t._id","FILTER IS_NUMBER(p.value)","FILTER p.name == @propertyName"])
    forStrings.push("FOR p IN propertiesHistory")
    resultsString = "COLLECT AGGREGATE"

    if (results === "average status") {
      resultsString += " average = AVERAGE(p.value)\nRETURN average"
    }

    if (results === "min status") {
      resultsString += " min = MIN(p.value)\nRETURN min"
    }
    
    if (results === "max status") {
      resultsString += " max = MAX(p.value)\nRETURN max"
    }
  }

  if (
    resourceType === "propertiesHistory" &&
    ["average value", "min value", "max value"].includes(results)
  ) {
    filterStrings.push(`FILTER IS_NUMBER(${resource}.value)`)
    resultsString = "COLLECT AGGREGATE"

    if (results === "average value") {
      resultsString += ` average = AVERAGE(${resource}.value)\nRETURN average`
    }

    if (results === "min value") {
      resultsString += ` min = MIN(${resource}.value)\nRETURN min`
    }
    
    if (results === "max value") {
      resultsString += ` max = MAX(${resource}.value)\nRETURN max`
    }
  }
  
  return {
    query: [
      uniq(forStrings).join("\n"),
      "\n",
      uniq(filterStrings).join("\n"),
      "\n",
      resultsString
    ]
      .filter(Boolean)
      .join(""),
    count: true,
    bindVars
  }
}
